const router = function (permissions) {
  return ({
    path: 'conf/dialogos',
    component: () => import('@/views/Dialogos/DialogosList.vue'),
    meta: { permissions, title: 'Diálogs feitos com a Inteligência Artificial' },
  })
}

export default {
  router,
}
