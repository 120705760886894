<template>
  <crud-card title="Dados Pessoais">
    <crud-pessoa-fisica-base
      :exclude="exclude"
      :optional="optional"
    />
    <slot />
  </crud-card>
</template>
<script>
  export default {
    props: {
      optional: {
        type: Array,
        default: () => [],
      },
      exclude: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>
