import { v4 } from 'uuid'

export class PedidoLog {
  constructor ({
    id,
    pedido_id,
    user_id,
    user_name,
    status,
    data,
    log,
  } = {
    id: v4(),
    pedido_id: '',
    user_id: 0,
    user_name: '',
    status: '',
    data: '',
    log: '',
  }) {
    this.id = id
    this.pedido_id = pedido_id
    this.user_id = user_id
    this.user_name = user_name
    this.status = status
    this.data = data
    this.log = log
  }
}
