import profile from './profile'
import keys from './keys'
import fazendas from './fazendas'
import produtos from './produtos'
import categorias from './categorias'
import unidadesmedida from './unidadesmedida'
import produtores from './produtores'
import tecnicos from './tecnicos'
import ofertas from './ofertas'
import catalogos from './catalogos'
import volumes from './volumes'
import qualidades from './qualidades'
import fornecedores from './fornecedores'
import pedidos from './pedidos'
import cotacoes from './cotacoes'
import relatorios from './relatorios'
import cadastros from './cadastros'
import notificacoesTecnico from './notificacoesTecnico'
import notificacoesProdutor from './notificacoesProdutor'
import integracoes from './integracoes'
import voucher from './voucher'

const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

/* ACESSO GESTOR */
export default {
  name: 'supervisor',
  path: '/supervisor',
  redirect: '/supervisor/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardSupervisor'),
      meta: { permissions, title: 'Início' },
    },
    tecnicos.router(permissions),
    produtores.router(permissions),
    fazendas.router(permissions),
    produtos.router(permissions),
    categorias.router(permissions),
    unidadesmedida.router(permissions),
    ofertas.router(permissions),
    profile.router(permissions),
    catalogos.router(permissions),
    volumes.router(permissions),
    qualidades.router(permissions),
    fornecedores.router(permissions),
    pedidos.router(permissions),
    cotacoes.router(permissions),
    relatorios.router(permissions),
    cadastros.router(permissions),
    keys.router(permissions),
    notificacoesTecnico.router(permissions),
    notificacoesProdutor.router(permissions),
    integracoes.router(permissions),
    voucher.router(permissions),
  ],
}
