const router = function (permissions) {
  return ({
    path: 'loja',
    component: () => import('@/views/Loja/LojaModule.vue'),
    meta: { permissions, title: 'Loja' },
    children: [
      {
        path: '/',
        meta: { permissions, title: 'Loja' },
        component: () => import('@/views/Loja/Loja.vue'),
      },
      {
        path: 'produtos',
        component: () => import('@/views/Loja/ProdutosList.vue'),
        props: (route) => ({ ordenacao: route.query.ordenacao, campanha: route.query.campanha }),
        meta: { permissions, title: 'Produtos' },
      },
      {
        path: 'produto',
        component: () => import('@/views/Loja/ProdutoIndividual.vue'),
        meta: { permissions, title: 'Produto' },
      },
      {
        path: 'carrinho',
        component: () => import('@/views/Loja/CarrinhoList.vue'),
        meta: { permissions, title: 'Carrinho' },
      },
      {
        path: 'pedido',
        component: () => import('@/views/Loja/Pedido.vue'),
        meta: { permissions, title: 'Finalização do Pedido' },
      },
    ],
  })
}

export default {
  router,
}
