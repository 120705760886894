import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_RECOMENDADOS = 'LOAD_RECOMENDADOS'
export const RESET_STATE = 'RESET_STATE'
export const PLACE_ORDER = 'PLACE_ORDER'

const getDefaultState = () => {
  return {
    produto: null,
    loading: true,
    produtosRecomendados: [],
    ref: 'loja',
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, rootState }, { id }) {
    commit(SET_STATE, { loading: true, produto: null, produtosRecomendados: [] })
    const produto = rootState.loja.catalogoAlgo.find(prod => prod.id === id)
    produto.rotulo = produto.rotulo && typeof produto.rotulo === 'object' ? produto.rotulo
    : {
 indicacao: produto.rotulo ? JSON.parse(produto.rotulo).indicacao : '',
modo_uso: produto.rotulo ? JSON.parse(produto.rotulo).modo_uso : '',
      restricoes: produto.rotulo ? JSON.parse(produto.rotulo).restricoes : '',
consumo: produto.rotulo ? JSON.parse(produto.rotulo).consumo : '',
      validade: produto.rotulo ? JSON.parse(produto.rotulo).validade : '',
apresentacao: produto.rotulo ? JSON.parse(produto.rotulo).apresentacao : '',
}
    commit(SET_STATE, { produto })
    await dispatch(LOAD_RECOMENDADOS, { id, ofertaId: produto.id_campanha })
    commit(SET_STATE, { loading: false })
  },
  async [LOAD_RECOMENDADOS] ({ commit, state, rootState }, { id, ofertaId }) {
    const produtosRecomendados = []
    await api.cadastrarEntidade({ unidades: rootState.loja.unidades, id, ofertaId }, 'loja/recomendados').then(response => {
      const responseArray = []
      Object.keys(response).forEach(key => responseArray.push(response[key]))
      responseArray.forEach(produto => {
        const index = rootState.loja.catalogoAlgo.findIndex(el => el.id === produto.id)
        produtosRecomendados.push(rootState.loja.catalogoAlgo[index])
      })
    })
    commit(SET_STATE, { produtosRecomendados })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
