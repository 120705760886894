import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_FORM = 'BOOTSTRAP_FORM'
export const BOOTSTRAP_FORM_DETAIL = 'BOOTSTRAP_FORM_DETAIL'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    data: null,
    detail: null,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  UPDATE_FORM_FIELD (state, payload) {
    const { ...fields } = payload
    Object.assign(state.data, { ...fields })
  },
  UPDATE_FORM_DETAIL_FIELD (state, payload) {
    const { ...fields } = payload
    Object.assign(state.detail, { ...fields })
  },
  UPDATE_FORM_FIELD_ARRAY (state, payload) {
    const { field, index, value } = payload
    state.data[field].splice(
      index,
      1,
      typeof value === 'function' ? value() : value,
    )
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  [BOOTSTRAP_FORM] ({ state, commit }, { model }) {
    commit('SET_STATE', { data: model })
  },
  [BOOTSTRAP_FORM_DETAIL] ({ state, commit }, { model }) {
    commit('SET_STATE', { detail: model })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
