import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Oferta } from '@/models/Oferta'

export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const LOAD_PRODUTOS = 'LOAD_PRODUTOS'
export const LOAD_OFERTAS = 'LOAD_OFERTAS'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const RESET_STATE = 'RESET_STATE'
export const OPEN_CAMPAIGN_DIALOG = 'OPEN_CAMPAIGN_DIALOG'
export const CLOSE_CAMPAIGN_DIALOG = 'CLOSE_CAMPAIGN_DIALOG'

const getDefaultState = () => ({
  getField,
  unidades: [],
  catalogo: [],
  catalogoAlgo: [],
  produtosAlgo: [],
  ofertasCampanhas: [],
  itensOfertas: [],
  itensCatalogoOfertas: [],
  itensCatalogoCampanhas: [],
  campaignDialog: false,
  campaignDialogLoading: true,
  campaign: {},
  loading: true,
  campanhasAtivas: [],
})

const getDataValue = (date) => {
  const newDate = new Date()
  if (date) {
    const dateArray = date.substring(0, 10).split('-')
    newDate.setFullYear(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]))
  }
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

function fabricaOfertaCampanha (id, status, nome, data_inicio, data_fim, entrega, descricao, unidades, regras, imagem) {
  const ofertaCampanha = {}
  ofertaCampanha.id = id
  ofertaCampanha.status = status
  ofertaCampanha.nome = nome
  ofertaCampanha.data_inicio = data_inicio
  ofertaCampanha.data_fim = data_fim
  ofertaCampanha.entrega = entrega
  ofertaCampanha.descricao = descricao
  ofertaCampanha.unidades = unidades
  ofertaCampanha.regras = regras
  return ofertaCampanha
}

function fabricaMergeProdutosCampanhaCatalogo (id_campanha, produto_id, unidades, condicoes, categoria,
  codigo, descricao, id, imagens, nome, pedido_min, preco, rotulo, unidade, id_regra, volume, nome_regra,
  data_inicio, data_fim, status, entrega, marca, nome_campanha) {
  const mergeProdutosCampanhaCatalogo = {}
  mergeProdutosCampanhaCatalogo.id_campanha = id_campanha
  mergeProdutosCampanhaCatalogo.produto_id = produto_id
  mergeProdutosCampanhaCatalogo.unidades = unidades
  mergeProdutosCampanhaCatalogo.condicoes = condicoes
  mergeProdutosCampanhaCatalogo.categoria = categoria
  mergeProdutosCampanhaCatalogo.codigo = codigo
  mergeProdutosCampanhaCatalogo.descricao = descricao
  mergeProdutosCampanhaCatalogo.id = id
  mergeProdutosCampanhaCatalogo.imagens = imagens
  mergeProdutosCampanhaCatalogo.nome = nome
  mergeProdutosCampanhaCatalogo.pedido_min = pedido_min
  mergeProdutosCampanhaCatalogo.preco = preco
  mergeProdutosCampanhaCatalogo.rotulo = rotulo
  mergeProdutosCampanhaCatalogo.unidade = unidade
  mergeProdutosCampanhaCatalogo.id_regra = id_regra
  mergeProdutosCampanhaCatalogo.volume = volume
  mergeProdutosCampanhaCatalogo.nome_regra = nome_regra
  mergeProdutosCampanhaCatalogo.nome_campanha = nome_campanha
  mergeProdutosCampanhaCatalogo.data_inicio = data_inicio
  mergeProdutosCampanhaCatalogo.data_fim = data_fim
  mergeProdutosCampanhaCatalogo.status = status
  mergeProdutosCampanhaCatalogo.entrega = entrega
  mergeProdutosCampanhaCatalogo.marca = marca
  return mergeProdutosCampanhaCatalogo
}

function fabricaProdutosOfertaRegra (data_inicio, data_fim, status, entrega, produto_id, unidades, condicoes, id_regra, nome_regra, id, nome_campanha) {
  const produtosOfertaRegra = {}
  produtosOfertaRegra.data_inicio = data_inicio
  produtosOfertaRegra.data_fim = data_fim
  produtosOfertaRegra.status = status
  produtosOfertaRegra.entrega = entrega
  produtosOfertaRegra.produto_id = produto_id
  produtosOfertaRegra.unidades = unidades
  produtosOfertaRegra.condicoes = condicoes
  produtosOfertaRegra.id_regra = id_regra
  produtosOfertaRegra.nome_regra = nome_regra
  produtosOfertaRegra.nome_campanha = nome_campanha
  produtosOfertaRegra.id = id
  return produtosOfertaRegra
}

function fabricaProdutos (id, produto_id, estoque, pedido_min, preco, disponibilidade, unidade_negocio, codigo,
  descricao, nome, rotulo, categoria, unidade, imagens, desconto, id_campanha, nomeCampanha, volume, marca,
  nome_campanha, entrega, data_inicio, data_fim, parcelamento, desconto_tecnico, vlr_parcelas) {
  const produtos = {}
  produtos.id = id
  produtos.produto_id = produto_id
  produtos.estoque = estoque
  produtos.pedido_min = pedido_min
  produtos.preco = preco
  produtos.precoWithDiscount = desconto && nome_campanha === '' ? (parseFloat(preco) - (parseFloat(preco) * (parseFloat(desconto) / 100))).toFixed(2) : preco
  produtos.precoWithDescend = desconto && nome_campanha === '' ? (parseFloat(preco) - (parseFloat(preco) * (parseFloat(desconto) / 100))).toFixed(2) : preco
  produtos.disponibilidade = disponibilidade
  produtos.unidade_negocio = unidade_negocio
  produtos.codigo = codigo
  produtos.descricao = descricao
  produtos.nome = nome
  produtos.rotulo = rotulo
  produtos.categoria = categoria
  produtos.unidade = unidade
  produtos.imagens = imagens
  produtos.desconto = desconto
  produtos.id_campanha = id_campanha
  produtos.nomeCampanha = nomeCampanha
  produtos.nome_campanha = nome_campanha
  produtos.volume = volume
  produtos.marca = marca
  produtos.entrega = entrega
  produtos.data_inicio = data_inicio
  produtos.data_fim = data_fim
  produtos.parcelamento = parcelamento
  produtos.desconto_tecnico = desconto_tecnico
  produtos.vlr_parcelas = vlr_parcelas
  return produtos
}

const state = getDefaultState()

const getters = {
  getField,
  catalogoFiltered: (state, getters, rootState) => {
    return state.catalogoAlgo.filter(prod => (rootState.fazendas.selecaoFazenda) && rootState.fazendas.selecaoFazenda.unidade_negocio_id === prod.unidade_negocio.id)
  },
  categorias: (state, getters) => {
    const categoriascount = []
    const categorias = getters.catalogoFiltered.map(produto => {
      categoriascount.push(produto.categoria.nome)
      return Object.assign(produto.categoria, { qtd: 0 })
    })

    const categoriasF = categorias.filter((v, i, a) => a.findIndex(t => (t.abrv === v.abrv && t.nome === v.nome)) === i).filter(categoria => categoria.nome.length > 0)
    categoriasF.forEach(element => {
      element.qtd = categoriascount.filter(el => el === element.nome).length
    })
    return categoriasF
  },
  marcas: (state, getters) => {
    const marcascount = []
    const marcas = getters.catalogoFiltered.map(produto => {
      marcascount.push(produto.marca)
      return { name: produto.marca, descricao: '', qtd: 0 }
    })
    const marcasF = marcas.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i).filter(marca => marca.name.length > 0)
    marcasF.forEach(element => {
      element.descricao = element.name
      element.qtd = marcascount.filter(el => el === element.name).length
    })
    return marcasF
  },
  promos: (state, getters) => {
    const promoscount = []
    const promos = getters.catalogoFiltered.map(produto => {
      if (produto.id_campanha === '-1') {
        promoscount.push('-1')
        return { id: '-1', name: 'Em Oferta', qtd: 0 }
      } else {
        if (produto.nome_campanha) {
          promoscount.push(produto.id_campanha)
          return { id: produto.id_campanha, name: produto.nome_campanha, qtd: 0 }
        } else {
          return { id: '', name: '' }
        }
      }
    })
    const promosF = promos.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i).filter(promo => promo.name.length > 0)
    promosF.forEach(element => {
      element.qtd = promoscount.filter(el => el === element.id).length
    })
    return promosF
  },
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
}

const actions = {
  async [BOOTSTRAP] ({ state, commit, dispatch }) {
    commit(RESET_STATE)
    await dispatch(LOAD_UNIDADES)
    await dispatch(LOAD_PRODUTOS)
    await dispatch(LOAD_OFERTAS)
    commit(SET_STATE, { loading: false })
  },
  async [OPEN_CAMPAIGN_DIALOG] ({ state, commit, dispatch }, id) {
    commit(SET_STATE, { campaignDialog: true })
    commit(SET_STATE, { campaignDialogLoading: true })
    await api.getEntidade('oferta', id).then(response => {
      commit(SET_STATE, { campaign: new Oferta(response.data[0]) })
      commit(SET_STATE, { campaignDialogLoading: false })
    })
  },
  async [CLOSE_CAMPAIGN_DIALOG] ({ commit }) {
    commit(SET_STATE, { campaignDialog: false })
  },
  async [LOAD_UNIDADES] ({ commit, rootState }) {
    if (rootState.user.role === 'tecnico') {
      const id = rootState.user.pessoa_id
      await api.getEntidade('tecnico', id).then(response => {
        const unidades = response.data[0].unidades.map(u => u.id)
        commit(SET_STATE, { unidades })
      })
    } else {
      await api.listEntidade('produtor/getUnidades').then(response => {
        const unidades = response.map(u => u.id)
        commit(SET_STATE, { unidades })
      })
    }
  },
  async [LOAD_PRODUTOS] ({ commit, state }) {
    await api.cadastrarEntidade({ unidades: state.unidades }, 'loja/unidades').then(response => {
      commit(SET_STATE, { catalogo: response })
    })
  },
  async [LOAD_OFERTAS] ({ commit, state, rootState }) {
    await api.listEntidade('oferta/loja').then(response => {
      const data_atual = getDataValue().valueOf()
      const ofertas_ativas = response
      commit(SET_STATE, { campanhasAtivas: ofertas_ativas })
      var parametroUnidadeNegocio = state.unidades

      const ofertas = ofertas_ativas

      state.ofertasCampanhas = []
      const produtosOfertaRegra = []

      // separa ofertas e campanhas
      let maiorDesconto = 0
      for (let i = 0; i < ofertas.length; i++) {
        if (ofertas[i].tipo === 'oferta') {
          for (let j = 0; j < ofertas[i].regras.length; j++) {
            if (parseFloat(ofertas[i].regras[j].condicoes[0].desconto) > parseFloat(maiorDesconto)) {
              maiorDesconto = parseFloat(ofertas[i].regras[j].condicoes[0].desconto)
            }
          }
        }
      }
      state.ofertasCampanhas.push(fabricaOfertaCampanha('-1', '1', 'Ofertas', '', '', '', 'Itens em Oferta', '', maiorDesconto))

      // percorre ofertas
      for (let i = 0; i < ofertas.length; i++) {
        const status = ofertas[i].status
        const entrega = ofertas[i].entrega
        // verifica se é oferta ou campanha
        if (ofertas[i].tipo === 'oferta') {
          const data_inicio = ofertas[i].data_inicio
          const data_fim = ofertas[i].data_fim

          // percorre as regras e pega as condicoes

          for (let j = 0; j < ofertas[i].regras.length; j++) {
            const condicoes = JSON.stringify(ofertas[i].regras[j].condicoes)
            const id_regra = ofertas[i].regras[j].id
            // percorre os produtos
            for (let k = 0; k < ofertas[i].regras[j].produtos.length; k++) {
              const produto_id = ofertas[i].regras[j].produtos[k].id
              const unidades = JSON.stringify(ofertas[i].regras[j].produtos[k].unidades)
              // seta o vetor com os valores dos produtos nas ofertas
              produtosOfertaRegra.push(fabricaProdutosOfertaRegra(data_inicio, data_fim, status, entrega, produto_id, unidades, condicoes, id_regra, '', '-1', ''))
            }
          }
        } else {
          const data_inicio = getDataValue((new Date(ofertas[i].data_inicio)).toISOString())
          const data_fim = getDataValue((new Date(ofertas[i].data_fim)).toISOString())

          const data_inicioConcat = (data_inicio.getDate().toString().padStart(2, '0')) + '/' + (((data_inicio.getMonth() + 1).toString()).padStart(2, '0')) + '/' + data_inicio.getFullYear()
          const data_fimConcat = (data_fim.getDate().toString().padStart(2, '0')) + '/' + (((data_fim.getMonth() + 1).toString()).padStart(2, '0')) + '/' + data_fim.getFullYear()
          ofertas[i].status = parseInt(ofertas[i].status)

          if ((data_inicio <= data_atual) && (data_fim >= data_atual) && (ofertas[i].status === 1)) {
            const regras = ofertas[i].regras
            state.ofertasCampanhas.push(fabricaOfertaCampanha(ofertas[i].id, ofertas[i].status, ofertas[i].nome,
              data_inicioConcat, data_fimConcat, ofertas[i].entrega, ofertas[i].descricao, ofertas[i].unidades, regras, ofertas[i].imagem))

            const id_campanha = ofertas[i].id
            const nome_campanha = ofertas[i].nome

            // percorre as regras e pega as condicoes

            for (let j = 0; j < ofertas[i].regras.length; j++) {
              const condicoes = JSON.stringify(ofertas[i].regras[j].condicoes)
              const id_regra = ofertas[i].regras[j].id
              const nome_regra = ofertas[i].regras[j].nome
              // percorre os produtos
              for (let k = 0; k < ofertas[i].regras[j].produtos.length; k++) {
                const produto_id = ofertas[i].regras[j].produtos[k].id
                const unidades = JSON.stringify(ofertas[i].regras[j].produtos[k].unidades)
                // seta o vetor com os valores dos produtos nas campanhas
                produtosOfertaRegra.push(fabricaProdutosOfertaRegra(ofertas[i].data_inicio, ofertas[i].data_fim, status, entrega, produto_id, unidades, condicoes, id_regra, nome_regra, id_campanha, nome_campanha))
              }
            }
          }
        }
      }
      // fim percorre ofertas

      // --------------------INICIO OFERTAS-------------------------
      // percorre os itens de produtosOfertaRegra e filtra os produtos nas campanhas.

      state.itensOfertas = []

      for (let i = 0; i < produtosOfertaRegra.length; i++) {
        const produtos_id = produtosOfertaRegra[i].produto_id
        const data_inicio = getDataValue(new Date(produtosOfertaRegra[i].data_inicio).toISOString())
        const data_fim = getDataValue(new Date(produtosOfertaRegra[i].data_fim).toISOString())
        produtosOfertaRegra[i].status = parseInt(produtosOfertaRegra[i].status)

        const produtoCatalogo = state.catalogo.find(e => e.produto_id === produtos_id)
        const id_regra = produtosOfertaRegra[i].id_regra
        const nome_regra = produtosOfertaRegra[i].nome_regra

        if ((typeof produtoCatalogo !== 'undefined') && (produtoCatalogo.unidade_negocio.id === parametroUnidadeNegocio || parametroUnidadeNegocio.includes(produtoCatalogo.unidade_negocio.id)) &&
          (data_inicio <= data_atual) && (data_fim >= data_atual) && (produtosOfertaRegra[i].status === 1)) {
          state.itensOfertas.push(fabricaMergeProdutosCampanhaCatalogo('-1', produtosOfertaRegra[i].produto_id, JSON.parse(produtosOfertaRegra[i].unidades),
            JSON.parse(produtosOfertaRegra[i].condicoes), produtoCatalogo.categoria, produtoCatalogo.codigo, produtoCatalogo.descricao,
            produtoCatalogo.id, produtoCatalogo.imagens, produtoCatalogo.nome, produtoCatalogo.pedido_min,
            produtoCatalogo.preco, JSON.parse(produtoCatalogo.rotulo), produtoCatalogo.unidade, id_regra, produtoCatalogo.volume.toString(), nome_regra,
            data_inicio, data_fim, produtosOfertaRegra[i].status, produtosOfertaRegra[i].entrega, produtoCatalogo.marca, ''))
        }
      }

      state.itensCatalogoOfertas = state.itensOfertas

      // --------------------FIM OFERTAS-------------------------

      // --------------------INICIO CAMPANHA-------------------------

      state.itensOfertas = []

      for (let i = 0; i < produtosOfertaRegra.length; i++) {
        const produtos_idCampanha = produtosOfertaRegra[i].produto_id
        const id_campanha = produtosOfertaRegra[i].id
        const produtoCatalogoCampanha = state.catalogo.find(e => e.produto_id === produtos_idCampanha)
        const nome_regra = produtosOfertaRegra[i].nome_regra
        const data_inicio = getDataValue(new Date(produtosOfertaRegra[i].data_inicio).toISOString())
        const data_fim = getDataValue(new Date(produtosOfertaRegra[i].data_fim).toISOString())
        if ((typeof produtoCatalogoCampanha !== 'undefined') && (produtoCatalogoCampanha.unidade_negocio.id === parametroUnidadeNegocio || parametroUnidadeNegocio.includes(produtoCatalogoCampanha.unidade_negocio.id))) {
          state.itensOfertas.push(fabricaMergeProdutosCampanhaCatalogo(id_campanha, produtosOfertaRegra[i].produto_id,
            JSON.parse(produtosOfertaRegra[i].unidades), JSON.parse(produtosOfertaRegra[i].condicoes), produtoCatalogoCampanha.categoria,
            produtoCatalogoCampanha.codigo, produtoCatalogoCampanha.descricao, produtoCatalogoCampanha.id, produtoCatalogoCampanha.imagens,
            produtoCatalogoCampanha.nome, produtoCatalogoCampanha.pedido_min, produtoCatalogoCampanha.preco, JSON.parse(produtoCatalogoCampanha.rotulo),
            produtoCatalogoCampanha.unidade, produtosOfertaRegra[i].id_regra, produtoCatalogoCampanha.volume.toString(),
            nome_regra, data_inicio, data_fim, produtosOfertaRegra[i].status, produtosOfertaRegra[i].entrega, produtoCatalogoCampanha.marca, produtosOfertaRegra[i].nome_campanha))
        }
      }

      state.itensCatalogoCampanhas = state.itensOfertas

      const catalogo = state.catalogo
      // Pega as categorias
      state.produtosAlgo = []
      state.catalogoAlgo = []

      for (let i = 0; i < catalogo.length; i++) {
        const imagens = catalogo[i].imagens
        const categoria = catalogo[i].categoria
        const unidade = catalogo[i].unidade
        const rotulo = catalogo[i].rotulo

        const pedido_min = parseInt(catalogo[i].pedido_min) > 0 ? parseInt(catalogo[i].pedido_min) : 1

        const ofertaOuCampanha = state.itensOfertas.find(el => el.produto_id === catalogo[i].produto_id)

        if (ofertaOuCampanha) {
          state.produtosAlgo.push(fabricaProdutos(catalogo[i].id, catalogo[i].produto_id, catalogo[i].estoque, pedido_min, catalogo[i].preco, catalogo[i].disponibilidade,
            catalogo[i].unidade_negocio, catalogo[i].codigo, catalogo[i].descricao, catalogo[i].nome, rotulo, categoria, unidade, imagens,
            ofertaOuCampanha.condicoes[0].desconto, ofertaOuCampanha.id_campanha, ofertaOuCampanha.nome_regra, catalogo[i].volume, catalogo[i].marca, ofertaOuCampanha.nome_campanha, ofertaOuCampanha.entrega, ofertaOuCampanha.data_inicio, ofertaOuCampanha.data_fim, ofertaOuCampanha.condicoes[0].parcelamento, 0, 0))

          state.catalogoAlgo.push(fabricaProdutos(catalogo[i].id, catalogo[i].produto_id, catalogo[i].estoque, pedido_min, catalogo[i].preco, catalogo[i].disponibilidade,
            catalogo[i].unidade_negocio, catalogo[i].codigo, catalogo[i].descricao, catalogo[i].nome, rotulo, categoria, unidade, imagens,
            ofertaOuCampanha.condicoes[0].desconto, ofertaOuCampanha.id_campanha, ofertaOuCampanha.nome_regra, catalogo[i].volume, catalogo[i].marca, ofertaOuCampanha.nome_campanha, ofertaOuCampanha.entrega, ofertaOuCampanha.data_inicio, ofertaOuCampanha.data_fim, ofertaOuCampanha.condicoes[0].parcelamento, 0, 0))
        } else {
          state.produtosAlgo.push(fabricaProdutos(catalogo[i].id, catalogo[i].produto_id, catalogo[i].estoque, pedido_min, catalogo[i].preco, catalogo[i].disponibilidade,
            catalogo[i].unidade_negocio, catalogo[i].codigo, catalogo[i].descricao, catalogo[i].nome, rotulo, categoria, unidade, imagens,
            '', '', '', catalogo[i].volume, catalogo[i].marca, '', '', '', '', catalogo[i].plano_pagamento, catalogo[i].desconto, catalogo[i].parcelas))

          state.catalogoAlgo.push(fabricaProdutos(catalogo[i].id, catalogo[i].produto_id, catalogo[i].estoque, pedido_min, catalogo[i].preco, catalogo[i].disponibilidade,
            catalogo[i].unidade_negocio, catalogo[i].codigo, catalogo[i].descricao, catalogo[i].nome, rotulo, categoria, unidade, imagens,
            '', '', '', catalogo[i].volume, catalogo[i].marca, '', '', '', '', catalogo[i].plano_pagamento, catalogo[i].desconto, catalogo[i].parcelas))
        }
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
