import Vue from 'vue'
import Vuetify, { VCard, VSelect } from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import pt from 'vuetify/es5/locale/pt'
import '@/sass/overrides.sass'

Vue.use(Vuetify, {
  components: {
    VCard,
    VSelect,
  },
})

const theme = {
  primary: '#003677',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
  footer: '#005dbc',
}

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
