import { getField, updateField } from 'vuex-map-fields'

export const SET_STATE = 'SET_STATE'
export const START = 'START'
export const STOP = 'STOP'
export const REDIRECIONAR = 'REDIRECIONAR'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    counting: false,
    seconds: -1,
    interval: null,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [START] ({ dispatch, commit, state }, { user, router }) {
    if (state.counting) {
      await dispatch(STOP)
    }
    commit(SET_STATE, {
      counting: true,
      seconds: 10,
      interval: setInterval(() => {
        commit(SET_STATE, { seconds: state.seconds - 1 })
        if (state.seconds <= 0) {
          dispatch(REDIRECIONAR, { user, router })
        }
      }, 1000),
    })
  },
  async [STOP] ({ dispatch, commit, state }) {
    if (state.interval) {
      clearInterval(state.interval)
    }
    commit(SET_STATE, { counting: false, seconds: -1, interval: null })
  },
  async [REDIRECIONAR] ({ dispatch, commit, state }, { user, router }) {
    if (state.counting) {
      await dispatch(STOP)
    }
    const windowPath = window.location.href.toLowerCase()
    if (windowPath.search('sempermissao') > 0) {
      // if (window.history.length > 3) {
      //   router.go(-2)
      // } else {
        router.push({
          path: `/${user.get().role}/dashboard`,
        })
      // }
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
