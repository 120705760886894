const router = function (permissions) {
  return ({
    path: 'conf/logs',
    component: () => import('@/views/Logs/Logs.vue'),
    meta: { permissions, title: 'Registro dos logs de acesso dos usuários' },
  })
}

export default {
  router,
}
