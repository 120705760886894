const router = function (permissions) {
  return ({
      path: 'keys',
      component: () => import('@/views/User/UserKeys.vue'),
      meta: { permissions, title: 'Alterar Senha' },
    })
}

export default {
  router,
}
