const router = function (permissions) {
  return ({
    path: 'conf/integracoes',
    component: () => import('@/views/Integracoes/IntegracaoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Integracoes/Integracao.vue'),
        meta: { permissions, title: 'Integrações de Cadastro' },
      },
    ],
  })
}

export default {
  router,
}
