var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._b({class:_vm.classes,style:({
    borderRadius: '8px',
    background: _vm.background,
    color: _vm.cardDashboard ? 'white' : 'black',
  })},'v-card',_vm.$attrs,false),[_c('div',{staticClass:"d-flex flex-wrap grow"},[(_vm.avatar)?_c('v-avatar',{staticClass:"mx-auto v-card--material__avatar elevation-5",attrs:{"size":"128","color":"grey"}},[_c('v-img',{attrs:{"src":_vm.avatar}})],1):_vm._e(),(!_vm.cardDashboard)?_c('v-sheet',{staticClass:"text-start v-card--material__heading",class:{
        'pa-5': !_vm.$slots.image,
      },style:(_vm.icon ? 'height: 64px; min-width: 48px; width: 64px; border-radius: 13px;': 'border-radius: 13px;'),attrs:{"color":_vm.color,"max-height":_vm.icon ? 90 : undefined,"width":_vm.icon ? 'auto' : '100%',"elevation":"5","dark":""}},[(_vm.$slots.heading)?_vm._t("heading"):(_vm.$slots.image)?_vm._t("image"):(_vm.title && !_vm.icon)?_c('div',{staticClass:"mb-0 text-h4 text-typo font-weight-bold",domProps:{"textContent":_vm._s(_vm.title)}}):(_vm.icon)?_c('v-icon',{attrs:{"size":"24"},domProps:{"textContent":_vm._s(_vm.icon)}}):_vm._e(),(_vm.text)?_c('div',{staticClass:"headline font-weight-thin",domProps:{"textContent":_vm._s(_vm.text)}}):_vm._e()],2):_vm._e(),(_vm.cardDashboard)?_c('v-sheet',{staticClass:"icone-center my-auto ml-n7",staticStyle:{"border-radius":"13px"},attrs:{"height":"64","width":"64","elevation":"5"}},[_c('v-icon',{attrs:{"size":"24","color":_vm.color}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e(),(_vm.$slots['after-heading'])?_c('div',{staticClass:"ml-3"},[_vm._t("after-heading")],2):(_vm.icon && _vm.title)?_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"display-2 pt-2 font-weight-bold",domProps:{"textContent":_vm._s(_vm.title)}})]):_vm._e(),(_vm.$slots.grafico)?_c('v-card',{staticClass:"v-card--material__heading mb-n6",staticStyle:{"overflow-x":"auto","overflow-y":"hidden"},attrs:{"width":'100%',"elevation":"0"}},[_vm._t("grafico")],2):_vm._e()],1),_vm._t("default"),(_vm.$slots.actions)?[_c('v-divider',{staticClass:"mt-2"}),_c('v-card-actions',{staticClass:"pb-0"},[_vm._t("actions")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }