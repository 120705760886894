const router = function (permissions) {
  return ({
    path: 'produtores',
    component: () => import('@/views/Produtor/ProdutorModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Produtor/ProdutorList.vue'),
        meta: { permissions, title: 'Manutenção de Produtores' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Produtor/ProdutorProfile.vue'),
        meta: { permissions, title: 'Cadastrando Produtor' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Produtor/ProdutorProfile.vue'),
        meta: { permissions, title: 'Editando Produtor' },
      },
    ],
  })
}

export default {
  router,
}
