const router = function (permissions) {
  return ({
    path: 'cdl/qualidades',
    component: () => import('@/views/Qualidade/QualidadeModule.vue'),
    meta: { permissions, title: 'Qualidade do Leite' },
    children: [
      {
        path: '/',
        component: () => import('@/views/Qualidade/QualidadeList.vue'),
        meta: { permissions, title: 'Qualidade do Leite' },
      },
      {
        path: 'grafico',
        component: () => import('@/views/Qualidade/QualidadeGraficoProdutor.vue'),
        meta: { permissions, title: 'Consulta amostras de Qualidade' },
      },
    ],
  })
}

export default {
  router,
}
