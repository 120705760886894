import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const LOAD_DADOS = 'LOAD_DADOS'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    relatorio: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final, relatorio, tipo }) {
    commit(SET_STATE, { ready: false })
    if (tipo === 0) {
      await dispatch(LOAD_BATCH, { data_inicial, data_final, relatorio })
    } else {
      await dispatch(LOAD_DADOS, { relatorio })
    }
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { data_inicial, data_final, relatorio }) {
    await api.listEntidade('relatorio/' + relatorio + '/' + data_inicial + '/' + data_final).then(
      relatorio => {
        commit(SET_STATE, { relatorio })
      },
    )
  },
  async [LOAD_DADOS] ({ dispatch, commit }, { relatorio }) {
    await api.listEntidade('relatorio/' + relatorio).then(
      relatorio => {
        commit(SET_STATE, { relatorio })
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
