import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_BATCH = 'LOAD_BATCH'
export const SET_STATE = 'SET_STATE'
export const RESET_STATE = 'RESET_STATE'
export const NEW_VOUCHER = 'NEW_VOUCHER'
export const EDIT_VOUCHER = 'EDIT_VOUCHER'
export const DELETE_VOUCHER = 'DELETE_VOUCHER'
export const UPDATE_STATUS_VOUCHER = 'UPDATE_STATUS_VOUCHER'
export const LISTAR_CUPONS = 'LISTAR_CUPONS'
export const DESTRUIR_CUPONS = 'DESTRUIR_CUPONS'

const getDefaultState = () => {
  return {
    vouchers: [],
    loading: false,
    cupons: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },

  [NEW_VOUCHER] ({ commit, dispatch }) {
    dispatch('form/voucher/BOOTSTRAP_PROFILE', { id: null }, { root: true })
  },

  [EDIT_VOUCHER] ({ commit, dispatch }, { item }) {
    dispatch('form/voucher/BOOTSTRAP_PROFILE', { id: item.id }, { root: true })
  },

  async [DELETE_VOUCHER] ({ commit, dispatch }, { item }) {
    Swal.fire({
      title: 'Deseja realmente excluir o voucher?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Enviando dados!',
          timerProgressBar: true,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading()
          },
          didOpen: async () => {
            return await api.inativarEntidade('voucher', item.id).then(
              data => {
                commit('SET_STATE', { dialog: false })
                commit('form/RESET_STATE', null, { root: true })
                Swal.fire({
                  title: 'Voucher excluido com sucesso!',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
                dispatch(LOAD_BATCH)
              },
              error => {
                Swal.fire({
                  icon: 'error',
                  title: 'Atenção',
                  text: error.message,
                })
              },
            )
          },
        })
      } else if (result.isDenied) {
        Swal.fire('Voucher não excluído!', '', 'info')
      }
    })
  },

  async [UPDATE_STATUS_VOUCHER] ({ commit, dispatch }, { item }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        return api.salvarEntidade(item, 'voucher/status', item.id).then(
          data => {
            commit('SET_STATE', { dialog: false })
            commit('form/RESET_STATE', null, { root: true })
            Swal.fire({
              title: 'Voucher atualizado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          },
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: error.message,
            })
            item.status = !item.status
          },
        )
      },
    })
  },

  async [LISTAR_CUPONS] ({ commit }, { id }) {
    const cupons = await api.listEntidade('voucher/itens/' + id)
    commit(SET_STATE, { cupons })
  },

  async [LOAD_BATCH] ({ commit }) {
    const vouchers = await api.listEntidade('voucher')
    commit(SET_STATE, { vouchers })
  },

  [DESTRUIR_CUPONS] ({ commit }) {
    commit(SET_STATE, { cupons: [] })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
