<template>
  <v-container class="pl-4 pr-0 py-0">
    <v-card
      height="99.4"
      :style="{background: color}"
      class="d-flex align-content-center flex-wrap my-2"
      elevation="6"
    >
      <v-row
        no-gutters
        dense
        class="ml-n4"
      >
        <v-col
          cols="auto"
          class="d-flex align-content-center flex-wrap"
        >
          <v-card
            height="64.16"
            width="65.97"
            class="icone-center my-auto"
          >
            <v-icon
              style="font-size: 32px;"
              :color="colorIcon"
            >
              {{ icon }}
            </v-icon>
          </v-card>
        </v-col>
        <v-col class="ml-3 color-text">
          <div
            class="estilos-teste"
          >
            {{ value }}
          </div>
          <div style="font-size: 13px;">
            {{ title }}
          </div>
          <div
            style="font-size: 9px; cursor: pointer;"
            @click="action"
          >
            Visualizar
            <v-icon color="#FFF">
              mdi-chevron-right
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>

  export default {
    name: 'CardDashboard',
    props: {
      title: {
        type: String,
        default: '',
      },
      value: {
        type: Number,
        default: 0,
      },
      icon: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: '',
      },
      colorIcon: {
        type: String,
        default: '',
      },
      action: {
        type: Function,
        default: null,
      },
      subText: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      }
    },
  }
</script>
<style scoped>
  .icone-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .color-card {
    background: linear-gradient(270deg, rgba(155, 52, 145, 0.50) 2.79%, #9B3491 96.1%);
    border-radius: 3.615px;
  }

  .estilos-teste {
    font-size: 43.375px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.301px;
  }

  .color-text {
    color: var(--white-100, #FFF)
  }
</style>
