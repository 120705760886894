import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS'
export const LOAD_ROLES = 'LOAD_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP = 'BOOTSTRAP'
export const DIALOG = 'DIALOG'
export const READY = 'READY'
export const SUBMIT = 'SUBMIT'
export const LOADING = 'LOADING'
export const REMOVE_ROLE = 'REMOVE_ROLE'
export const RESET_ACTIVE = 'RESET_ACTIVE'

export default {
  namespaced: true,
  state: () => ({
    ready: false,
    permissoes: null,
    roles: null,
    active: [],
  }),
  mutations: {
    updateField,
    [LOADING] (state) {
      state.ready = false
    },
    [READY] (state) {
      state.ready = true
    },
    [ADD_ROLE] (state, { role: newRole }) {
      state.roles = state.roles.map(
        role => role.id === newRole.parent
          ? {
            ...role,
            children: role.children
              ? [...role.children, newRole]
              : [newRole],
          }
          : role,
      )
    },
    [SET_STATE] (state, payload) {
      Object.assign(state, payload)
    },
    [RESET_ACTIVE] (state) {
      state.active[0] = null
    },
  },
  getters: {
    getField,
    activeGroup: (state) => state.active[0],
  },
  actions: {
    async [LOAD_PERMISSIONS] ({ commit }) {
      const permissoes = (await api.listEntidade('permission')).sort((a, b) => a.order - b.order)
      commit(SET_STATE, { permissoes })
    },
    async [LOAD_ROLES] ({ commit, state, rootState }) {
      const roles = await api.listEntidade('role')
      const items = []

      roles.forEach(element => {
        const filhos = []
        if (element.children) {
          element.children.forEach(filho => {
            filhos.push({
              name: filho.description,
              id: filho.id,
              permissoes: filho.permissions,
              parent: filho.parent,
              level: filho.level,
            })
          })
        }

        items.push({
          name: element.description,
          id: element.id,
          ...(filhos.length ? { children: filhos } : {}),
          permissoes: element.permissions,
          parent: element.parent,
          level: element.level,
        })
      })

      commit(SET_STATE, { roles: items })
    },
    async [BOOTSTRAP] ({ dispatch, commit }) {
      commit(LOADING)
      await dispatch(LOAD_PERMISSIONS)
      await dispatch(LOAD_ROLES)
      commit(READY)
    },
    async [SUBMIT] ({ dispatch, commit, rootState, state: { roles } }) {
      // agora a logica de envio para o backend
      Swal.fire({
        title: 'Enviando dados!',
        timerProgressBar: true,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading()
        },
        didOpen: () => {
          // formata dados para envio
          const dataFormatada = []
          roles.forEach(element => {
            const arr = []
            element.permissoes && element.permissoes.forEach((el, index) => {
              if (el) {
                arr.push(index)
              }
            })
            dataFormatada.push({
              id: element.id,
              level: element.level,
              permissions: arr,
            })
            // se tiver filhos tem que fazer o msm processo pra eles
            if (element.children) {
              element.children.forEach(filho => {
                const arr = []
                filho.permissoes.forEach((el, index) => {
                  if (el) {
                    arr.push(index)
                  }
                })
                dataFormatada.push({
                  id: filho.id,
                  level: element.level,
                  permissions: arr,
                })
              })
            }
          })

          const formData = dataFormatada.filter(d => d.level >= (rootState.user.roles[0].level + 1))
          return api.salvarAlteracoesControleAcesso(formData).then(
            data => {
              Swal.fire({
                title: 'Controle de acesso atualizado com sucesso!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
            },
            error => {
              Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.errors ? error.errors : error.message,
              })
            },
          )
        },
      })
    },
    async [REMOVE_ROLE] ({ dispatch, commit }, id) {
      // agora a logica de envio para o backend
      Swal.fire({
        title: 'Tem certeza que deseja excluir esse Grupo de Acesso?',
        icon: 'question',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#109010',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        focusConfirm: false,
        preConfirm: () => {
          return api.inativarEntidade('role', id).then(
            result => result,
            error => {
              this.Swal.fire({
                icon: 'error',
                title: 'Atenção',
                text: error.errors ? error.errors : error.message,
              })
            },
          )
        },
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'O Grupo de acesso foi excluído com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          dispatch(BOOTSTRAP)
          commit(RESET_ACTIVE)
        }
      })
    },
  },
}
