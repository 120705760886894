import axios from 'axios'
import store from '@/store'
import Swal from 'sweetalert2'
import { logout } from '@/services/AuthService'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.platform = 'web'
axios.defaults.headers.version = localStorage.getItem('version_api') || ''

const token = localStorage.getItem('user-token')

if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

axios.interceptors.response.use(
  function (response) {
    if (response.headers && response.headers.version) {
      localStorage.setItem('version_api', response.headers.version)
      axios.defaults.headers.version = response.headers.version
    }
    if (response.data && response.data.error) {
      return Promise.reject(response)
    }
    return response
  },
  error => {
    if (error.response.status === 401) {
      if (store.state.user.roles !== null) {
        Swal.fire({
          icon: 'error',
          title: 'Atenção...',
          text: 'Sua sessão expirou ou você não tem autorização para acessar este componente. Faça o login novamente.',
          didClose: () => {
            logout()
          },
        })
      }
    }
    return Promise.reject(error.response)
  },
)

export default axios
