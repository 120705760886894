<template>
  <div>
    <v-menu
      v-if="!$vuetify.breakpoint.smAndDown && !$vuetify.breakpoint.xsAndDown"
      max-height="600"
      max-width="370"
      min-width="370"
      persistent
      scrollable
      :close-on-content-click="false"
      :close-on-click="true"
      offset-y
      bottom
      left
      style="border-radius: 20px;"
      :value="Show"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          @click="() => { Show = true
                          carrega_carrinho() }"
        >
          <v-badge
            :content="produtos.length"
            :value="produtos.length"
            color="success"
            overlap
            class="pa-1 ma-2"
            small
          >
            <v-icon
              small
              color="white"
              title="Ver meu carrinho de compras"
            >
              mdi-cart-outline
            </v-icon>
          </v-badge>
        </div>
      </template>
      <v-card
        class="my-0"
        flat
        style="border-radius: 20px;"
      >
        <v-card-title
          style="font-size: 20px; color: #143693;font-weight: bold;"
          class="pt-4"
        >
          Resumo do meu carrinho
        </v-card-title>
        <v-card-text class="pa-0 pt-0">
          <v-data-iterator
            :items="produtos"
            hide-default-footer
            no-data-text="Não há produtos adicionados ao carrinho, acesse a loja para adicioná-los."
            class="ma-0 pa-0"
          >
            <template v-slot:header />
            <template v-slot:default="props">
              <div
                v-for="(item, index) in props.items"
                :key="item.id"
                style="overflow-y: auto; max-height:300px; padding-inline: 10px;"
              >
                <card-carrinho
                  class="my-0 py-0"
                  :produto="item"
                />
                <v-divider
                  v-if="index < props.items.length - 1"
                  class="mt-2"
                />
              </div>
            </template>
          </v-data-iterator>
          <v-card-actions
            v-if="produtos.length > 0"
            style="background-color: #E8EBF4; height: 55px; padding: 30px;"
            class="mt-0 pt-2 pb-2 mx-0 d-flex justify-space-between"
          >
            <v-btn
              small
              class="text-none text-white"
              style="color: white; border-radius: 8px;"
              color="#183B94"
              @click="handleCarrinhoClick"
            >
              <v-icon
                small
                color="white"
                class="mr-1"
              >
                mdi-cart-outline
              </v-icon>
              Ver meu carrinho
            </v-btn>
            <div style="font-weight: 700; text-align: right;">
              Total:  R$ {{ currencyFormatter( precoTotal ) }}
            </div>
          </v-card-actions>
          <v-card-actions
            v-else
            style="background-color: #E8EBF4; height: 55px;"
            class="mt-0 pt-2 pb-2 mx-0"
          >
            <v-btn
              class="text-none text-white"
              text
              small
              style="margin: auto;"
              color="success accent-4"
              @click="handleLojaClick"
            >
              <v-icon
                small
                color="success accent-4"
                class="mr-1"
              >
                mdi-shopping
              </v-icon>
              Retornar a Loja
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-menu>
    <div
      v-else
      @click="handleCarrinhoClick()"
    >
      <v-badge
        :content="produtos.length"
        :value="produtos.length"
        color="success"
        overlap
        class="pa-1 ma-2"
        small
      >
        <v-icon
          small
          color="white"
          title="Acessar meu Carrinho de Compras"
        >
          mdi-cart-outline
        </v-icon>
      </v-badge>
    </div>
  </div>
</template>

<script>
  import CarrinhoStore, { RESET_STATE, SET_STATE } from '@/store/modules/carrinho'
  import { mapState, mapGetters, mapMutations } from 'vuex'
  import CardCarrinho from '@/views/Loja/_cardCarrinho.vue'
  import { formatImageLink } from '@/views/Loja/helpers'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'Notification',
    components: {
      CardCarrinho,
    },
    data () {
      return {
        currencyFormatter,
        Show: false,
      }
    },
    computed: {
      ...mapState('carrinho', ['produtos']),
      ...mapGetters('carrinho', ['precoTotal', 'precoSubTotal']),
    },
    async created () {
      if (!this.$store.state.carrinho) { this.$store.registerModule('carrinho', CarrinhoStore) }
    },
    methods: {
      ...mapMutations('carrinho', [SET_STATE, RESET_STATE]),
      formatImageLink,
      handleCarrinhoClick () {
        this.Show = false
        if (!this.$route.path.includes('carrinho')) {
          this.$router.push({
            path: `/${this.$user.get().role}/loja/carrinho`,
          })
        }
      },
      handleLojaClick () {
        this.Show = false
        if (this.$route.path !== `/${this.$user.get().role}/loja`) {
          this.$router.push({
            path: `/${this.$user.get().role}/loja`,
          })
        }
      },
      carrega_carrinho () {
        const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + this.$store.state.fazendas.selecaoFazenda.id)))
        if (produtos !== null) {
          this.SET_STATE({ produtos })
        } else {
          this.RESET_STATE()
        }
      },
    },
  }
</script>

<style scoped>
.normal {
  background: #ffffff;
  border-left: 6px solid;
  border-color: #9c9c9c;
}
.active {
  background: #f1f1ff;
  border-left: 6px solid;
  border-color:#1976d2;
}

.v-menu__content {
  border-radius: 20px;
}

</style>
