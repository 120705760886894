import Vue from 'vue'
import App from './App.vue'
import router from './Routes/router'
import store from './store'
import vuetify from './plugins/vuetify'
import AsyncComputed from 'vue-async-computed'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueTheMask from 'vue-the-mask'
import VueRouterUserRoles from 'vue-router-user-roles'
import { load } from './services/AuthService'

import './plugins/base'
import './plugins/cruds'
import './plugins/datas'
import './plugins/XLSX'
import './plugins/moment'
import './plugins/swal'
import './plugins/chartist'
import './plugins/googleMaps'
import './api/api'
import './plugins/vuetify-money.js'

Vue.use(VueRouterUserRoles, { router })
Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(AsyncComputed)
Vue.use(VueSweetalert2)
Vue.component('VueCropper', VueCropper)
Vue.config.productionTip = false

// configuração do Toast
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  timer: 1500,
  timerProgressBar: true,
  showConfirmButton: false,
})
// salva a configuração global na palavra "Toast"
window.Toast = Toast

// Criando prototype para remover o loading
Element.prototype.remove = function () {
  this.parentElement?.removeChild(this)
}

NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
  for (var i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement?.removeChild(this[i])
    }
  }
}

load().then(() => {
  window.location.origin
  document.getElementById('main-loading').remove()
  new Vue({
    store,
    vuetify,
    render: h => h(App),
    router,
  }).$mount('#app')
})
