const router = function (permissions) {
  return ({
    path: 'cadastros',
    component: () => import('@/views/Cadastro/CadastroList.vue'),
    meta: { permissions, title: 'Solicitações de Conta' },
    props: (route) => ({ status: route.query.status }),
  })
}

export default {
  router,
}
