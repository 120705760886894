const router = function (permissions) {
  return ({
    path: 'cdc/categorias',
    component: () => import('@/views/Categoria/CategoriaModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Categoria/CategoriaList.vue'),
        meta: { permissions, title: 'Manutenção de Categorias' },
      },
    ],
  })
}

export default {
  router,
}
