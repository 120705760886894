const router = function (permissions) {
  return ({
    path: 'cdc/unidadesmedida',
    component: () => import('@/views/Unidade/UnidadeModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Unidade/UnidadeList.vue'),
        meta: { permissions, title: 'Manutenção de Unidades de Medida' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Unidade/UnidadeProfile.vue'),
        meta: { permissions, title: 'Cadastrando Unidade de Medida' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Unidade/UnidadeProfile.vue'),
        meta: { permissions, title: 'Editando Unidade de Medida' },
      },
    ],
  })
}

export default {
  router,
}
