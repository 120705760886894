import { getField } from 'vuex-map-fields'
import Swal from 'sweetalert2'

export default {
  getField,
  USER: state => state.user,
  hasPermission: state => (permission) => {
    try {
      if (state && state.permissions && state.user && state.user.roles && state.user.roles.length) {
        const { id } = state.permissions.find(p => p.name === permission)
        return id && state.user.roles[0].permissions[id]
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: `Permissão inválida: "${permission}". Por favor, entre em contato com o suporte.`,
      })
    }
    return false
  },
}
