const router = function (permissions) {
  return ({
    path: 'cdl/volumes',
    component: () => import('@/views/Volume/VolumeModule.vue'),
    meta: { permissions, title: 'Volume do Leite' },
    children: [
      {
        path: '/',
        component: () => import('@/views/Volume/VolumeList.vue'),
        meta: { permissions, title: 'Volume do Leite' },
      },
      {
        path: 'grafico',
        component: () => import('@/views/Volume/VolumeGraficoProdutor.vue'),
        meta: { permissions, title: 'Consulta Volumes coletados por dia' },
      },
    ],
  })
}

export default {
  router,
}
