<template>
  <v-row>
    <v-col cols="4">
      <v-menu offset-y>
        <template
          v-slot:activator="{ on, attrs }"
        >
          <v-btn
            class="pa-4 text-none"
            rounded
            v-bind="attrs"
            small
            :color="date.length ? 'primary' : ''"
            v-on="on"
          >
            Periodo: {{ value.selected }}
            <v-icon
              right
            >
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in selectPeriodosNames"
            :key="i"
            active-class="custom-active"
            @click="changeSelectList(item)"
          >
            <v-list-item-title
              :key="i"
              :value="item"
            >
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title class="headline">
          Selecione o período
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="auto">
              <data-field
                v-model="dateFrom"
                :label="'Data Inicial'"
                :data-max="dateTo"
                :mes-ano="mensal"
              />
            </v-col>
            <v-col cols="auto">
              <data-field
                v-model="dateTo"
                :label="'Data Final'"
                :data-min="dateFrom"
                :mes-ano="mensal"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            class="text-none"
            rounded
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="text-none"
            color="primary"
            rounded
            @click="selectPeriodo"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import hash from 'object-hash'
  import moment from 'moment'
  export default {
    name: 'PeriodSelect',
    props: {
      value: {
        type: Object,
        default: () => ({
          name: null,
          label: 0,
          selected: 'Trimestre',
          from: null,
          to: null,
        }),
      },

      selectPeriodosNames: {
        type: Array,
        default: () => [
          'Semana',
          'Mês',
          'Trimestre',
          'Semestre',
          'Selecionar período',
        ],
      },

      bootstrap: {
        type: Function,
        default: () => () => {},
      },

      mensal: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        moment,
        dialog: false,
        date: [],
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        select: null,
      }
    },

    created () {
      const { nome } = this.$user.get()
      const storage = localStorage.getItem('PeriodSelect-' + hash(this.value.name) + '-' + nome, JSON.stringify(this.value))
      const headerSave = JSON.parse(storage)
      if (headerSave) {
        Object.assign(this.value, headerSave)
      }
      this.changeSelectList(this.value.selected, this.value.from, this.value.to)
    },

    methods: {
      changeSelectList (item, from = null, to = null) {
        switch (item) {
          case 'Semana':
            from = this.moment().subtract(6, 'days').format('YYYY-MM-DD')
            to = this.moment().format('YYYY-MM-DD')
            this.saveValue(item, from, to, 0)
            break
          case 'Mês':
            this.selectMes(item)
            break
          case 'Trimestre':
            this.selectTrimetre(item)
            break
          case 'Semestre':
            this.selectSemestre(item)
            break
          case 'Ano':
            from = this.moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD')
            to = this.moment().endOf('month').format('YYYY-MM-DD')
            this.saveValue(item, from, to, 5)
            break
          default:
            if (from && to) {
              this.dateFrom = from
              this.dateTo = to
              this.selectPeriodo()
            } else {
              this.dialog = true
            }
            break
        }
      },

      selectMes (item) {
        if (this.mensal) {
          const from = this.moment().startOf('month').format('YYYY-MM-DD')
          const to = this.moment().endOf('month').format('YYYY-MM-DD')
          this.saveValue(item, from, to, 2)
        } else {
          const from = this.moment().subtract(1, 'month').format('YYYY-MM-DD')
          const to = this.moment().format('YYYY-MM-DD')
          this.saveValue(item, from, to, 2)
        }
      },

      selectTrimetre (item) {
        if (this.mensal) {
          const from = this.moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
          const to = this.moment().endOf('month').format('YYYY-MM-DD')
          this.saveValue(item, from, to, 3)
        } else {
          const from = this.moment().subtract(3, 'months').format('YYYY-MM-DD')
          const to = this.moment().format('YYYY-MM-DD')
          this.saveValue(item, from, to, 3)
        }
      },

      selectSemestre (item) {
        if (this.mensal) {
          const from = this.moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD')
          const to = this.moment().endOf('month').format('YYYY-MM-DD')
          this.saveValue(item, from, to, 4)
        } else {
          const from = this.moment().subtract(6, 'months').format('YYYY-MM-DD')
          const to = this.moment().format('YYYY-MM-DD')
          this.saveValue(item, from, to, 4)
        }
      },

      selectPeriodo () {
        const dateStart = this.moment(this.dateFrom)
        const dateEnd = this.moment(this.dateTo)
        if (dateEnd.diff(dateStart, 'months', true) >= 12) {
          return window.Toast.fire({
            icon: 'error',
            title: 'Não permitido período maior que 1 ano',
          })
        }
        if (this.mensal) {
          this.dateFrom = dateStart.startOf('month').format('YYYY-MM-DD')
          this.dateTo = dateEnd.endOf('month').format('YYYY-MM-DD')
        }
        this.dialog = false
        this.saveValue(`${dateStart.format('DD/MM/YYYY')} - ${dateEnd.format('DD/MM/YYYY')}`, this.dateFrom, this.dateTo, 6)
      },

      saveValue (period, from, to, label = null) {
        const { nome } = this.$user.get()
        Object.assign(this.value, {
          from: from,
          to: to,
          label: label,
          selected: period,
        })
        localStorage.setItem('PeriodSelect-' + hash(this.value.name) + '-' + nome, JSON.stringify(this.value))
        this.bootstrap({ from, to })
      },
    },
  }
</script>
