
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import Swal from 'sweetalert2'
import { getProperty } from './objectUtils'
import { currencyFormatter } from './formatter'

//  TODO: estilos possiveis das colunas
//  para definir o estilo da coluna, basta adicionar o atributo style seguindo o padrão abaixo
//  styleReport: {atributo: valor, atributo: valor, ...}
//  atributos possiveis:
//  font: helvetica|times|courier = helvetica
//  fontStyle: normal|bold|italic|bolditalic = normal
//  overflow: linebreak|ellipsize|visible|hidden = linebreak
//  fillColor: Color? = null
//  textColor: Color? = 20
//  cellWidth: auto|wrap|number = auto
//  minCellWidth: number? = 10
//  minCellHeight: number = 0
//  halign: left|center|right = left
//  valign: top|middle|bottom = top
//  fontSize: number = 10
//  cellPadding: Padding = 10
//  lineColor: Color = 10
//  lineWidth: border = 0
//  mesAno: boolean = false

const dateNow = new Date().toLocaleDateString('pt-BR', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
})
const horaNow = new Date().toLocaleTimeString('pt-BR', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})

const dataPermitidas = [
  'YYYY-MM-DD HH:mm:ss',
  'DD/MM/YYYY HH:mm:ss',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
]

function addHeader (doc, name) {
  const docWidth = doc.internal.pageSize.width

  doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', 10, 5, 42, 15)
  doc.setFontSize(16)
  doc.text(name, docWidth / 2, 15, { align: 'center' })
  doc.setFontSize(8)
  doc.text(dateNow, docWidth - 10, (15), { align: 'right' })
  doc.text(horaNow, docWidth - 10, (20), { align: 'right' })
  doc.line(5, (24), docWidth - 5, (24))
}

function addFooter (pageNumber, doc, totalPages) {
  const docWidth = doc.internal.pageSize.width
  const docHeight = doc.internal.pageSize.height

  doc.setFontSize(7)
  doc.text(dateNow, 10 - 5, docHeight - 10)
  doc.text('https://app.clubedoprodutor.com.br', docWidth / 2, docHeight - 10, { align: 'center' })
  doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - 10, { align: 'right' })
}

function mesAnoFormatter (value) {
  return moment(value).format('MM/YYYY')
}

function creatHeader (headers) {
  const styles = {}
  const headersFiltered = headers.filter(header => header.report).map(header => {
    styles[header.value] = {}
    if (header.styleReport) {
      styles[header.value] = header.styleReport
    }
    if (header.alterValue) {
      styles[header.value].alterValue = header.alterValue
    }
    return { dataKey: header.value, header: header.text, sum: header.sum || false }
  })
  return [headersFiltered, styles]
}

function createRows (data, headers, styles) {
  const sum = {}
  const rows = data.map(item => {
    const row = {}
    headers.forEach(header => {
      if (header.sum) {
        sum[header.dataKey] = (sum[header.dataKey] || 0) + parseInt(getProperty(item, header.dataKey))
      }
      const headerValue = applyHeaderStyle(header, item, styles)
      row[header.dataKey] = headerValue
    })
    return row
  })
  return [rows, sum]
}

function applyHeaderStyle (header, item, styles) {
  const value = getProperty(item, header.dataKey)
  if (styles[header.dataKey]) {
    const style = styles[header.dataKey]
    if (style.alterValue) {
      const alterValue = style.alterValue
      return alterValue[value] || value
    }
    if (style.type === 'number') {
      return currencyFormatter(value)
    }
    if (style.type === 'mesAno') {
      return mesAnoFormatter(value)
    }
  }
  if (moment(value, dataPermitidas, true).isValid()) {
    return moment(value).format('DD/MM/YYYY')
  }
  return value
}

export const JsonToPdf = async (data, headers, name) => {
  if (data.length > 200) {
    await new Promise((resolve) => {
      Swal.fire({
        title: 'Gerando PDF...',
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
          requestAnimationFrame(resolve)
        },
      })
    })
    await new Promise(resolve => requestAnimationFrame(resolve))
  }

  const [cols, styles] = creatHeader(headers)
  const [rows, sum] = createRows(data, cols, styles)
  // eslint-disable-next-line new-cap
  var doc = new jsPDF({
    orientation: 'landscape',
  })
  addHeader(doc, name)

  let startY = 27

  var totalPages = '{total_pages_count_string}'

  doc.autoTable({
    theme: 'plain',
    columnStyles: styles,
    headStyles: {
      textColor: '#FFFFFF',
      fillColor: '#1E88E5',
    },
    footStyles: { halign: 'right' },
    columns: cols,
    body: rows,
    foot: [sum],
    startY: startY,
    pageBreak: 'auto',
    margin: { left: 5, top: 5, bottom: 15, right: 5 },
    didDrawPage: (data) => {
      addFooter(data.pageNumber, doc, totalPages)
    },
  })
  startY = doc.autoTable.previous.finalY

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPages)
  }
  Swal.close()
  doc.save(name + '.pdf')
}
