<template>
  <v-data-table
    :key="anIncrementoNumber"
    v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
    v-fixed-columns
    :headers="headerList"
    :items="valueFiltered"
    :search="debouncedSearch"
    :height="TableHeigth"
    no-results-text="Nenhum resultado para o texto pesquisado"
    :single-expand="singleExpand"
    :show-expand="showExpand"
    :expanded.sync="expanded"
    item-key="id"
    calculate-widths
    fixed-header
    :style="stylesTable"
    :class="tableClass"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :group-by="groupHeader"
    :footer-props="{
      showFirstLastPage: false,
      showCurrentPage: true,
      showCurrentPageSize: true,
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      'items-per-page-options': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? [20, 40, 60, 120, -1] : $vuetify.breakpoint.sm || $vuetify.breakpoint.md ? [7, 14, 38, 68, 100, -1] : [1]
    }"
    :item-class="itemClass"
  >
    <template v-slot:top>
      <v-row
        v-if="showSearch || $slots.datas || exportPdfXlsx || $slots.btnImporte || $slots.btnCadastro"
        class="px-5 py-3"
        style="margin: 0;"
      >
        <v-col
          v-if="showSearch"
          cols="12"
          sm="12"
          md="7"
          lg="4"
          xl="6"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            color="primary"
            label="Pesquisar"
            outlined
            rounded
            dense
            elevation="0"
            hide-details
            clearable
            clear-icon="mdi-close"
          />
        </v-col>
        <v-col
          v-if="$slots.datas"
          cols="12"
          sm="6"
          md="6"
          lg="auto"
          xl="auto"
        >
          <v-row>
            <slot name="datas" />
          </v-row>
        </v-col>
        <v-spacer />
        <v-col
          v-if="exportPdfXlsx"
          cols="12"
          sm="4"
          md="auto"
          lg="auto"
          xl="auto"
          align-self="end"
        >
          <v-row>
            <v-col>
              <v-menu
                open-on-hover
                bottom
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-none text-white"
                    color="primary"
                    v-bind="attrs"
                    rounded
                    block
                    v-on="on"
                  >
                    <v-icon
                      dark
                      left
                    >
                      mdi-file-move
                    </v-icon>
                    Exportar
                  </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                  <v-list-item
                    :key="1"
                    :class="{active: true}"
                    @click="exportExcel"
                  >
                    <v-list-item-title
                      class="mx-2 px-0"
                    >
                      <v-icon
                        color="green"
                        class="mx-2 px-0"
                      >
                        mdi-file-excel
                      </v-icon>
                      Exportar XLSX
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    :key="2"
                    :class="{active: true}"
                    @click="exportPdf"
                  >
                    <v-list-item-title class="mx-2 px-0">
                      <v-icon
                        color="red"
                        class="mx-2 px-0"
                      >
                        mdi-file-pdf
                      </v-icon>
                      Exportar PDF
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
          md="auto"
          lg="auto"
          xl="auto"
          align-self="end"
        >
          <v-row>
            <slot
              v-if="$slots.btnCadastro"
              name="btnCadastro"
            />
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-slot:group.header="{ group, groupBy, items, isOpen, toggle, remove }">
      <td
        colspan="2"
      >
        <v-btn
          x-small
          icon
          :title="(isOpen ? 'Recolhe os itens grupo' : 'Expande os itens do grupo' )"
          @click="toggle"
        >
          <v-icon
            small
            :color="(isOpen ? 'red' : 'green' )"
          >
            {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
        </v-btn>
        <span style="font-size: 13px; font-weight: bold; text-align: center;color: #003677"> {{ groupNameHeader(groupBy) }}: </span>
        <span style="font-size: 13px; text-align: center; display: inline-block; background-color: #e0e0e0; border-radius: 10px;">{{ group }} ({{ items.length }})<br></span>
        <v-btn
          icon
          x-small
          title="Desfaz o agrupamento dos registros do grid."
          @click="() => { setGroupHeader(''), remove }"
        >
          <v-icon
            small
            color="red"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </td>
    </template>
    <template
      v-for="(coluna,indexHeader,indexFilter) in headerName"
      v-slot:[`header.${indexHeader}`]="{ header }"
    >
      {{ header.text }}
      <v-btn
        v-if="headerName[indexHeader]"
        :key="`group-${indexHeader}`"
        icon
        x-small
        title="Agrupar os registros"
        @click="setGroupHeader(header.value)"
      >
        <v-icon
          small
        >
          mdi-lightbulb-group
        </v-icon>
      </v-btn>
      <v-menu
        v-if="filters[header.value]"
        :key="`filter-${indexHeader}`"
        v-model="menuFilter[indexFilter]"
        offset-y
        :close-on-content-click="false"
        style="border-radius: 20px;"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            title="Filtrar os registros"
            v-bind="attrs"
            v-on="on"
          >
            <v-badge
              v-if="filters[header.value].length"
              bottom
              color="red"
              dot
              offset-x="8"
              offset-y="8"
              overlap
            >
              <v-icon
                small
                color="red"
              >
                mdi-filter-variant-plus
              </v-icon>
            </v-badge>
            <v-icon
              v-else
              small
            >
              mdi-filter-variant
            </v-icon>
          </v-btn>
        </template>
        <div style="background-color: white; width: 350px">
          <v-list>
            <v-list-item>
              <v-autocomplete
                v-if="menuFilter[indexFilter]"
                v-model="filters[header.value]"
                multiple
                dense
                auto-select-first
                clearable
                chips
                small-chips
                deletable-chips
                color="primary"
                :items="optionsFilter(header.value)"
                append-icon="mdi-filter"
                :label="`Informe o filtro por: ${header.text}:`"
                hide-details
                outlined
                rounded
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index < 4"
                    small
                    class="caption"
                  >
                    <span>
                      {{ item }}
                    </span>
                  </v-chip>
                  <span
                    v-if="index === 4"
                    class="grey--text caption"
                  >
                    (+{{ filters[header.value].length - 4 }} outros)
                  </span>
                </template>
              </v-autocomplete>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </template>
    <template v-slot:expanded-item="{ item }">
      <slot
        name="expanded-item"
        :item="item"
        :headers="headerList"
      />
    </template>
    <template
      v-slot:[`item.actions`]="{ item }"
    >
      <div
        style="display: flex !important; flex-direction: row;"
      >
        <v-tooltip
          v-for="(action, index) in actions"
          :key="index"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :color="action.color"
              class="mr-1"
              v-bind="attrs"
              :disabled="action.disabled ? action.disabled(item) : false"
              v-on="on"
              @click="action.click(item)"
            >
              {{ action.icon }}
            </v-icon>
          </template>
          <span>{{ action.title }}</span>
        </v-tooltip>
      </div>
    </template>
    <template
      v-for="slot in slots"
      v-slot:[slot]="{ item }"
    >
      <slot
        :name="slot"
        :item="item"
      />
    </template>
    <template v-slot:no-data>
      <v-row justify="center">
        {{ noDataMsg }}
      </v-row>
    </template>
    <template
      v-if="totalColumn"
      v-slot:body.append
    >
      <tr>
        <td v-if="showExpand" />
        <td
          v-for="(header, index) in headerList"
          :key="index"
          scope="col"
          style="font-size: 14px; font-weight: bold; width: auto; min-width: auto"
          :class="header.align === 'center' ? 'text-center' : header.align === 'end' || header.align === 'right' ? 'text-right' : 'text-left'"
        >
          <span
            v-if="header.sum"
            :style="`${ sumColuns[header.value] >= 0 ? 'color: #006aff' : 'color: #fd0404' }`"
          >
            {{ header.sum?.toUpperCase() === 'INTEGER' ? currencyFormatter( sumColuns[header.value], 0 ) : header.sum?.toUpperCase() === 'FLOAT' ? sumColuns[header.value].toFixed(4) : 'R$ ' + currencyFormatter(Math.abs(sumColuns[header.value])) }}
          </span>
        </td>
      </tr>
    </template>
    <template v-slot:[`footer.page-text`]="items">
      {{ items.pageStop }} de {{ items.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
//  Propriedades do Json Header
//  {
//     text: string ,
//     value: string,
//     align?: 'start' | 'center' | 'end',
//     sortable?: boolean,
//     filterable?: boolean,
//     groupable?: boolean,
//     divider?: boolean,
//     class?: string | string[],
//     cellClass?: string | string[],
//     width?: string | number,
//     filter?: (value: any, search: string, item: any) => boolean,
//     sort?: (a: any, b: any) => number
//     floatingfilter: bolean => filtro flutuante
//     sum : string => 'Integer', 'Float', 'Money'
//  }
  import store from '@/store'
  import hash from 'object-hash'
  import Sortable from 'sortablejs'
  import Vue from 'vue'
  import { mapGetters, mapState } from 'vuex'
  import { JsonToExcel } from '../../utils/Worksheet'
  import { currencyFormatter } from '../../utils/formatter'
  import { getProperty } from '../../utils/objectUtils'
  import { JsonToPdf } from '../../utils/reportPDF'
  import _ from 'lodash'

  const style = document.createElement('style')
  style.type = 'text/css'

  const applyCallback = (el, binding, vnode) => {
    const columns = vnode.componentOptions.propsData.headers
    const showSelect = vnode.componentOptions.propsData.showSelect !== undefined || false

    const fixedIndexes = []

    columns.forEach((column, columnIdx) => {
      if (column.fixed) {
        fixedIndexes.push(columnIdx + (showSelect ? 2 : 1))
      }
    })

    if (showSelect) {
      fixedIndexes.push(1)
    }

    const uniqId = Date.now().toString(36) + Math.random().toString(36).substring(2)

    el.setAttribute('v-fixed-columns', uniqId)

    let css = `[v-fixed-columns="${uniqId}"] tbody > tr:hover > td, [v-fixed-columns="${uniqId}"] tbody > tr.v-data-table__selected > td { background: inherit !important;}`

    fixedIndexes.forEach((index, indexIdx) => {
      css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index})`
      if (indexIdx < fixedIndexes.length - 1) { css += ',' }
    })

    css += '{ position: sticky !important; position: -webkit-sticky !important; left: 0; z-index: 3; background: inherit;}'

    fixedIndexes.forEach((index, indexIdx) => {
      css += `[v-fixed-columns="${uniqId}"] > tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] > .v-data-table__wrapper > table > thead > tr > th:nth-child(${index})`

      if (indexIdx < fixedIndexes.length - 1) { css += ',' }
    })

    css += '{ z-index: 4;}'

    let left = 0

    columns.forEach((column, columnIdx) => {
      if (column.fixed) {
        const index = columnIdx + (showSelect ? 2 : 1)
        css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index}) { left: ${left + (showSelect ? 64 : 0)}px !important;}`
        left += (column.width || 0) + left
      }
    })

    style.innerHTML = css
    document.head.appendChild(style)
  }

  const unbindCallback = (el, binding) => {
    style.innerHTML = ''
    el.removeAttribute('v-fixed-columns')
    style.remove()
  }

  Vue.directive('fixed-columns', {
    bind: applyCallback,
    componentUpdated: applyCallback,
    unbind: unbindCallback,
  })

  function watchClass (targetNode, classToWatch) {
    let lastClassState = targetNode.classList.contains(classToWatch)
    const observer = new MutationObserver((mutationsList) => {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i]
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const currentClassState = mutation.target.classList.contains(classToWatch)
          if (lastClassState !== currentClassState) {
            lastClassState = currentClassState
            if (!currentClassState) {
              mutation.target.classList.add('sortHandle')
            }
          }
        }
      }
    })
    observer.observe(targetNode, { attributes: true })
  }

  export default {
    name: 'List',
    emits: ['clicked'],
    directives: {
      'sortable-table': {
        inserted: (el, binding) => {
          el.querySelectorAll('th').forEach((draggableEl) => {
            watchClass(draggableEl, 'sortHandle')
            draggableEl.classList.add('sortHandle')
          })
          Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {})
        },
      },
    },
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      itemKey: {
        type: String,
        default: 'id',
      },
      headers: {
        type: Array,
        default: () => [],
      },
      actions: {
        type: Array,
        default: () => [],
      },
      noDataMsg: {
        type: String,
        default: 'Nenhum item encontrado.',
      },
      slots: {
        type: Array,
        default: () => [],
      },
      singleExpand: {
        type: Boolean,
        default: true,
      },
      showExpand: {
        type: Boolean,
        default: false,
      },
      totalColumn: {
        type: Boolean,
        default: false,
      },
      stylesTable: {
        type: String,
        default: 'font-size: 10px',
      },
      tableClass: {
        type: String,
        default: 'elevation-1 mt-2 text-no-wrap',
      },
      sortBy: {
        type: Array,
        default: () => [],
      },
      sortDesc: {
        type: Array,
        default: () => [],
      },
      permission: {
        type: String,
        default: '',
      },
      exportPdfXlsx: {
        type: Boolean,
        default: false,
      },
      showSearch: {
        type: Boolean,
        default: true,
      },
      itemHeight: {
        type: String,
        default: '',
      },
      itemClass: {
        type: Function,
        default: () => '',
      },
    },
    data () {
      return {
        currencyFormatter,
        debouncedSearch: null,
        search: null,
        expanded: [],
        excel: null,
        filterOpts: [],
        filters: '',
        menuFilter: [],
        sumColuns: {},
        anIncrementoNumber: 1,
        headerList: [],
        groupHeader: [],
        headerName: [],
        timeout: null, // Armazena o timeout do debounce
      }
    },
    computed: {
      ...mapGetters(['hasPermission']),
      ...mapState(['crud_filter']),
      valueFiltered () {
        const data = this.value.filter((d) => {
          return Object.keys(this.filters).every((f) => {
            return (
              this.filters[f].length < 1 ||
              this.filters[f].includes(getProperty(d, f))
            )
          })
        })
        this.headerList.map((header) => {
          if (header.sum) {
            const soma = data.reduce((acc, d) => {
              if (header.sum.toUpperCase() === 'INTEGER') {
                acc.total += parseInt(getProperty(d, header.value))
              } else {
                acc.total += parseFloat(getProperty(d, header.value))
              }
              return acc
            }, {
              total: 0,
            })

            this.sumColuns[header.value] = (header.sum.toUpperCase() === 'INTEGER' || header.sum.toUpperCase() === 'MONEY' ? soma.total : soma.total.toFixed(4))
          }
        })
        this.$root.$emit('updateFilter', data)
        return data
      },
      TableHeigth () {
        return this.itemHeight ? this.itemHeight : this.$vuetify.breakpoint.xl ? 'calc(81vh - 155px)' : 'calc(80vh - 185px)'
      },
    },
    watch: {
      search (newVal) {
        // Toda vez que o usuário digitar algo, chamamos a função debounce
        this.debounceSearch(newVal)
      },
      expanded: function () {
        this.$emit('clicked', this.expanded)
      },

      filters: {
        handler: function () {
          const object = new Object()
          const chave = hash(this.headerList)
          object[chave] = this.filters
          this.$store.commit('SET_CRUD_FILTER', {
            ...this.$store.state.crud_filter,
            ...object,
          })
        },
        deep: true,
      },
    },
    created () {
      const { nome } = this.$user.get()
      const headerSave = JSON.parse(localStorage.getItem(('CrudList-' + hash(this.headers) + '-' + nome)))

      if (this.permission.trim() !== '' && !store.getters.hasPermission(this.permission.trim())) {
        this.$router.push({
          path: '/controleacesso/sempermissao',
        })
      }

      if (headerSave?.length > 0) {
        this.headerList = headerSave.length !== this.headers.length ? [...this.headers] : [...headerSave]
      } else {
        this.headerList = [...this.headers]
      }

      const chave = hash(this.headerList)
      this.filterOpts = []
      let objetoFilter = '{'
      let objetoHeader = '{'

      this.headerList.map(header => {
        if (header.floatingfilter) {
          this.filterOpts.push({
            value: '',
            key: header.value,
            checked: false,
            text: header.text,
            unidade: [],
          })
          objetoFilter += `"${header.value}": [],`
        }
        if (header.groupable === undefined) {
          objetoHeader += `"${header.value}": false,`
        } else {
          objetoHeader += `"${header.value}": ${header.groupable},`
        }
      })

      if (objetoFilter.length > 1) {
        this.filters = JSON.parse(objetoFilter.substring(0, objetoFilter.length - 1) + '}')
      }

      this.headerName = JSON.parse(objetoHeader.substring(0, objetoHeader.length - 1) + '}')
      this.menuFilter = Object.keys(this.headerName).map(element => element === true)
      this.menuFilter.forEach((element) => { element = false })

      if (
        this.$store.state.crud_filter &&
        this.$store.state.crud_filter[chave]
      ) {
        const crud_filter = this.$store.state.crud_filter[chave]

        if (Object.keys(this.filters).length <= Object.keys(crud_filter).length) {
          this.filters = crud_filter
        }
      }
    },
    methods: {
      async exportPdf () {
        JsonToPdf(this.valueFiltered, this.headerList, this.$route.meta.title)
      },
      exportExcel () {
        this.excel = new JsonToExcel(this.valueFiltered, this.headerList, this.$route.meta.title)
      },
      optionsFilter (key) {
        const options = []
        this.value.map(item => {
          const hv = getProperty(item, key)
          if (hv !== '' && !options.filter(option => option === hv).length) options.push(hv)
        })
        options.sort()
        return options
      },
      sortTheHeadersAndUpdateTheKey (evt) {
        const { nome } = this.$user.get()
        const headersTmp = this.headerList
        const oldIndex = evt.oldIndex
        const newIndex = evt.newIndex
        if (newIndex >= headersTmp.length) {
          let k = newIndex - headersTmp.length + 1
          while (k--) {
            headersTmp.push(undefined)
          }
        }
        headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0])
        this.anIncrementoNumber += 1
        localStorage.setItem('CrudList-' + hash(this.headers) + '-' + nome, JSON.stringify(headersTmp))
      },
      groupNameHeader (keyValue) {
        let groupName = ''
        this.headers.forEach(ob => {
          if (ob.value === keyValue[0]) {
            groupName = ob.text
          }
        })
        return groupName
      },
      setGroupHeader (keyValue) {
        if (keyValue === '') {
          this.groupHeader = []
        } else {
          this.groupHeader = [keyValue]
        }
      },
      debounceSearch: _.debounce(function (val) {
        // Atualiza o valor debounced após o timeout do debounce
        this.debouncedSearch = val
      }, 300),
    },

  }
</script>

<style lang="scss" scoped>

.v-data-table > .v-data-table__wrapper > table>tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}

.v-menu__content {
  border-radius: 16px !important;
}
.normal {
  background: #ffffff;
  border-color: #9c9c9c;
}
.active {
  background: #f1f1ff;
}
</style>
