const router = function (permissions) {
  return {
    path: 'unidadesnegocio',
    component: () => import('@/views/UnidadeNegocio/UnidadeNegocioModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () =>
          import('@/views/UnidadeNegocio/UnidadeNegocioList.vue'),
        meta: { permissions, title: 'Manutenção de Unidades de Negócio' },
      },
      {
        path: 'cadastrar',
        component: () =>
          import('@/views/UnidadeNegocio/UnidadeNegocioProfile.vue'),
        meta: { permissions, title: 'Cadastrando Unidade de Negócio' },
      },
      {
        path: 'alterar',
        component: () =>
          import('@/views/UnidadeNegocio/UnidadeNegocioProfile.vue'),
        meta: { permissions, title: 'Editando Unidade de Negócio' },
      },

      {
        path: 'cadastrardesconto',
        component: () =>
          import('@/views/UnidadeNegocio/UnidadeNegocioConfiguracion.vue'),
        meta: { permissions, title: 'Cadastrando Unidade de desconto' },
      },

      {
        path: 'atualizardesconto',
        component: () =>
          import('@/views/UnidadeNegocio/UnidadeNegocioConfiguracion.vue'),
        meta: { permissions, title: 'Cadastrando Unidade de desconto' },
      },
    ],
  }
}

export default {
  router,
}
