<template>
  <v-dialog
    v-model="datetimeDialog"
    :persistent="persistent"
    width="290px"
    :fullscreen="fullscreen"
    @click:outside="closeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="published"
        readonly
        prepend-inner-icon="mdi-calendar-clock"
        :label="label"
        :filled="filled"
        height="43"
        color="primary"
        outlined
        hide-details="false"
        dense
        rounded
        :clearable="clearable"
        v-bind="{ ...attrs, ...$attrs }"
        :rules="rules"
        elevation="2"
        v-on="on"
      />
    </template>
    <v-card class="t-datetime-picker white">
      <v-toolbar
        height="36"
        color="primary"
        dark
      >
        <v-tabs
          v-model="tab"
          grow
          height="36"
        >
          <v-tabs-slider />
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker
            v-model="date"
            class="rounded-0"
            full-width
            :min="dataMin"
            :max="dataMax"
            @input="tab = 'time'"
          />
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            :key="tab"
            v-model="time"
            :min="minTime"
            :max="maxTime"
            format="24hr"
            class="rounded-0"
            full-width
          >
            <v-btn
              text
              color="primary"
              @click="closeDialog"
            >
              OK
            </v-btn>
          </v-time-picker>
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="closeDialog"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'DataTimePicker',

    model: {
      prop: 'value',
      event: 'input',
    },

    props: {
      value: {
        type: String,
        default: null,
      },
      fullscreen: {
        type: Boolean,
        default: false,
      },
      persistent: {
        type: Boolean,
        default: true,
      },
      dateFormat: {
        type: String,
        default: 'DD/MM/yyyy',
      },
      timeFormat: {
        type: String,
        default: 'HH:mm',
      },
      label: {
        type: String,
        default: 'Data e hora',
      },
      filled: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: Array,
        default: () => [],
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      dataMin: {
        type: String,
        default: '0000-00-00',
      },
      dataMax: {
        type: String,
        default: '9999-99-99',
      },
    },

    data: () => ({
      moment,
      date: null,
      time: null,
      tab: false,
      published: null,
      datetimeDialog: false,
    }),

    computed: {
      minTime () {
        if (this.dataMin && this.dataMin !== '0000-00-00') {
          if (this.moment(this.date).format('yyyy-MM-DD') === this.moment(this.dataMin).format('yyyy-MM-DD')) {
            return this.moment(this.dataMin).format('HH:mm')
          }
        }
        return '00:00'
      },
      maxTime () {
        if (this.dataMax && this.dataMax !== '9999-99-99') {
          if (this.moment(this.date).format('yyyy-MM-DD') === this.moment(this.dataMax).format('yyyy-MM-DD')) {
            return this.moment(this.dataMax).format('HH:mm')
          }
        }
        return '23:59'
      },
    },

    watch: {
      value: {
        immediate: true,
        handler (val) {
          if (val) {
            this.published = this.moment(val).format(
              this.dateFormat + ' ' + this.timeFormat,
            )
            this.date = this.moment(val).format('yyyy-MM-DD')
            this.time = this.moment(val).format(this.timeFormat)
          }

          if (!val) {
            this.date = null
            this.time = null
            this.published = null
          }
        },
      },
    },

    methods: {
      closeDialog () {
        this.datetimeDialog = false

        if (!this.date) {
          this.date = this.moment().format('yyyy-MM-DD')
        }

        if (!this.time) {
          this.time = this.moment().format(this.timeFormat)
        }

        if (this.date && this.time) {
          this.published = this.moment(this.date + ' ' + this.time).format(
            this.dateFormat + ' ' + this.timeFormat,
          )
        }

        this.$emit('input', `${this.date} ${this.time}`)

        // prevents animate to date tab when closing
        setTimeout(() => (this.tab = 'date'), 300)
      },
    },
  }
</script>

<style scoped>
.v-tabs-slider-wrapper {
  top: 0;
}
.v-picker__title {
  height: 90px;
}
.v-time-picker-title__time * {
  font-size: 60px;
  height: 60px;
}
.v-picker__body {
  height: 290px;
}
.v-tabs-items {
  height: 380px;
}
.v-tab--active .v-icon {
  color: white;
}
</style>
