import profile from './profile'
import keys from './keys'
import fazendas from './fazendas'
import produtores from './produtores'
import pedidos from './pedidos'
import loja from './loja'
import cotacoes from './cotacoes'
import notificacoesTecnico from './notificacoesTecnico'
import volumes from './volumes'
import qualidades from './qualidades'

const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'supervisor',
    access: false,
    redirect: 'supervisor',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

/* ACESSO TECNICO */
export default {
  name: 'tecnico',
  path: '/tecnico',
  redirect: '/tecnico/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardTecnico'),
      meta: { permissions, title: 'Início' },
    },
    fazendas.router(permissions),
    produtores.router(permissions),
    profile.router(permissions),
    pedidos.router(permissions),
    loja.router(permissions),
    keys.router(permissions),
    cotacoes.router(permissions),
    notificacoesTecnico.router(permissions),
    volumes.router(permissions),
    qualidades.router(permissions),
  ],
}
