import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const INATIVAR_UNIDADE = 'INATIVAR_UNIDADE'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    unidades: null,
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_UNIDADES] ({ commit }) {
    commit(SET_STATE, { loading: true })
    await api.listEntidade('unidade').then(
      unidades => {
        commit(SET_STATE, { unidades, loading: false })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch }) {
    await dispatch(LOAD_UNIDADES)
  },
  async [INATIVAR_UNIDADE] ({ dispatch }, unidade) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a Unidade?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('unidade', unidade.id).then(
          result => result,
          ({
            error = null,
            message = 'Não foi possivel excluir a Unidade!',
          }) => {
            Swal.fire({
              icon: 'error',
              title: error === null ? 'Ocorreu algum erro!' : 'Erro!',
              text: message,
            })
          },
        )
      },
    }).then(
      result => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'A Unidade foi excluída com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2500,
          })
          dispatch(BOOTSTRAP)
        }
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
