import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'

export const BOOTSTRAP = 'BOOTSTRAP'
export const BOOTSTRAP_FAZENDA = 'BOOTSTRAP_FAZENDA'
export const BOOTSTRAP_DASHBOARD = 'BOOTSTRAP_DASHBOARD'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const LOAD_BATCH_FAZENDA = 'LOAD_BATCH_FAZENDA'
export const LOAD_BATCH_DASHBOARD = 'LOAD_BATCH_DASHBOARD'
export const LOAD_BATCH_CONDENACAO = 'LOAD_BATCH_CONDENACAO'
export const DELETE_VOLUME = 'DELETE_VOLUME'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    volumes: [],
    volume_grafico_mes: [],
    volume_grafico_ano: [],
    volume_total: [],
    dashboard: [],
    condenacao: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH, { data_inicial, data_final })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    try {
      const volumes = await api.listEntidade(`volume/${data_inicial}/${data_final}`)
      commit(SET_STATE, { volumes })
    } catch (error) {
      console.error('Error loading volumes:', error)
    }
  },
  async [BOOTSTRAP_FAZENDA] ({ dispatch, commit }, { data_inicial, data_final, id } = { data_inicial: 'from', data_final: 'to', id: '' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH_FAZENDA, { data_inicial, data_final, id })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH_FAZENDA] ({ commit }, { data_inicial, data_final, id } = { data_inicial: 'from', data_final: 'to', id: '' }) {
    commit(SET_STATE, { volume_grafico_mes: [], volume_grafico_ano: [], volume_total: {} })

    try {
      const createGraficoData = () => ({
        data: {
          labels: [],
          series: [
            { data: [], name: 'Volume', className: 'ct-series-a' },
            { data: [], name: 'Volume Não Conforme', className: 'ct-series-b' },
          ],
        },
      })

      const volume_grafico_mes = createGraficoData()
      const volume_grafico_ano = createGraficoData()
      const volume_total = {
        totalVolumeMes: 0,
        mediaGeralDiaMes: 0,
        totalVolumeAnual: 0,
        mediaMensalAno: 0,
      }

      const [respostaVolume, respostaCondenacao] = await Promise.all([
        api.listEntidade(`volume/${data_inicial}/${data_final}`),
        api.listEntidade(`volume-condenado/${data_inicial}/${data_final}`),
      ])

      const filterAndSortData = async (data, id, dateKey) =>
        data.filter(item => item.fazenda_id === id)
          .sort((a, b) => new Date(a[dateKey]) - new Date(b[dateKey]))

      const formatMonth = dateStr => {
        const [year, month] = dateStr.split(' ')[0].split('-')
        return `${month}/${year}`
      }

      const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()

      let totalVolumeMes = 0
      let totalVolumeAnual = 0
      const monthlyVolumes = {}
      const monthlyCondenations = {}

      const currentMonth = moment().format('MM')
      const currentYear = moment().format('YYYY')
      const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth)

      const [volumes, condenacoes] = await Promise.all([
        filterAndSortData(respostaVolume, id, 'data_coleta'),
        filterAndSortData(respostaCondenacao, id, 'data_condenacao'),
        (async () => {
          for (let day = 1; day <= 31; day++) {
            const dayFormatted = day < 10 ? `0${day}` : day.toString()
            volume_grafico_mes.data.labels.push(`${dayFormatted}`)
            volume_grafico_mes.data.series[0].data.push({ meta: `${dayFormatted}/${currentMonth}`, value: 0 })
            volume_grafico_mes.data.series[1].data.push({ meta: `${dayFormatted}/${currentMonth}`, value: 0 })
          }
        })(),
      ])

      await Promise.all([
        (async () => {
          volumes.forEach(item => {
            const day = moment(item.data_coleta, 'YYYY-MM-DD').date()
            if (day <= daysInCurrentMonth) {
              volume_grafico_mes.data.series[0].data[day - 1].value += parseInt(item.volume, 10)
            }
            const monthYear = formatMonth(item.data_coleta)
            const volume = parseInt(item.volume, 10)
            totalVolumeMes += volume
            totalVolumeAnual += volume

            if (monthlyVolumes[monthYear]) {
              monthlyVolumes[monthYear].total += volume
              monthlyVolumes[monthYear].days.add(day)
            } else {
              monthlyVolumes[monthYear] = { total: volume, days: new Set([day]) }
            }

            if (day >= 1 && day <= daysInCurrentMonth) {
              volume_grafico_mes.data.series[0].data[day - 1].value += volume
            }
          })
        })(),
        (async () => {
          condenacoes.forEach(item => {
            const day = moment(item.data_condenacao, 'YYYY-MM-DD').date()
            const monthYear = formatMonth(item.data_condenacao)
            const volume = parseInt(item.volume, 10)

            if (monthlyCondenations[monthYear]) {
              monthlyCondenations[monthYear] += volume
            } else {
              monthlyCondenations[monthYear] = volume
            }

            if (day <= daysInCurrentMonth) {
              volume_grafico_mes.data.series[1].data[day - 1].value += parseInt(item.volume, 10)
            }
          })
        })(),
        (async () => {
          Object.keys(monthlyVolumes).forEach(monthYear => {
            const days = monthlyVolumes[monthYear].days.size
            const total = monthlyVolumes[monthYear].total
            volume_grafico_ano.data.labels.push(monthYear)
            volume_grafico_ano.data.series[0].data.push({ meta: `Mês ${monthYear}`, value: total })
          })
        })(),
      ])

      volume_grafico_ano.data.labels.sort((a, b) => {
        const [monthA, yearA] = a.split('/')
        const [monthB, yearB] = b.split('/')
        return new Date(`${yearA}-${monthA}-01`) - new Date(`${yearB}-${monthB}-01`)
      })

      volume_total.totalVolumeMes = totalVolumeMes
      volume_total.mediaGeralDiaMes = Math.floor(totalVolumeMes / daysInCurrentMonth, 0)
      volume_total.totalVolumeAnual = totalVolumeAnual
      volume_total.mediaMensalAno = totalVolumeAnual / 12

      commit(SET_STATE, { volume_grafico_mes, volume_grafico_ano, volume_total })
    } catch (error) {
      console.error(error)
    }
  },
  async [BOOTSTRAP_DASHBOARD] ({ dispatch, commit }, { from, to } = { from: null, to: null }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH_DASHBOARD, { from, to })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH_DASHBOARD] ({ commit }, { from, to }) {
    await api.listEntidade(`volume/dashboard/${from}/${to}`).then(
      dashboard => {
        commit(SET_STATE, { dashboard })
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
