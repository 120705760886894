var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"py-0 pl-4 pr-0"},[_c('base-material-card',_vm._g(_vm._b({staticClass:"v-card--material-stats ajuste-margem",attrs:{"icon":_vm.icon,"card-dashboard":true,"background":_vm.colorBackground},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"ml-auto text-right"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{attrs:{"align-self":"end","cols":"12"}},[_c('span',{style:({
                fontSize: '40px',
                color:'#f8f8ff',
                fontStyle: 'normal',
                letterSpacing: '-1.301px',
                fontWeight: 700
              })},[_vm._v(" "+_vm._s(_vm.value)+" "),_c('small',[_vm._v(_vm._s(_vm.smallValue))])])])],1),_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"body-3 font-weight-bold",attrs:{"align-self":"end","cols":"12"},domProps:{"textContent":_vm._s(_vm.title)}})],1)],1)]},proxy:true}])},'base-material-card',_vm.$attrs,false),_vm.$listeners),[(_vm.subText || _vm.action)?_c('v-col',{staticClass:"px-0 py-2",attrs:{"cols":"12"}},[_c('v-divider')],1):_vm._e(),(_vm.subIcon || _vm.subText)?_c('v-row',[_c('v-col',{staticClass:"pb-2",attrs:{"cols":"11"}},[_c('v-icon',{staticClass:"ml-1 mr-1",attrs:{"color":_vm.subIconColor,"size":"16"}},[_vm._v(" "+_vm._s(_vm.subIcon)+" ")]),_c('span',{staticClass:"caption font-weight-medium",class:_vm.subTextColor,domProps:{"textContent":_vm._s(_vm.subText)}})],1),(_vm.action)?_c('v-col',{staticClass:"px-0 pb-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"#FFFFFF","size":"16","title":"Visualizar os itens"},on:{"click":_vm.action}},[_vm._v(" mdi-file-search-outline ")])],1):_vm._e()],1):_vm._e(),(_vm.help && ! _vm.action)?_c('div',{staticStyle:{"position":"absolute","right":"5px","bottom":"5px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"info","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,460215151)},[_c('span',[_vm._v(_vm._s(_vm.help))])])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }