const router = function (permissions) {
  return ({
    path: 'cdv/cotacoes',
    component: () => import('@/views/Cotacao/CotacaoList.vue'),
    meta: { permissions, title: 'Manutenção das Cotações' },
    props: (route) => ({ status: route.query.status }),
  })
}

export default {
  router,
}
