const permissions = [
  {
    role: 'guest',
    access: true,
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'supervisor',
    access: false,
    redirect: 'supervisor',
  },
  {
    role: 'tecnico',
    access: false,
    redirect: 'tecnico',
  },
  {
    role: 'produtor',
    access: false,
    redirect: 'produtor',
  },
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
]

export default [
  {
    /* ACESSO ADMINISTRADOR */
    name: 'verifyMail',
    path: '/verifyMail/:token',
    component: () => import('@/views/MailConfirmation/MailConfirmation'),
    meta: { permissions },
  },
  {
    /* ACESSO ADMINISTRADOR */
    name: 'resetPassword',
    path: '/resetPassword/:token',
    component: () => import('@/views/ResetPassword/ResetPassword'),
    meta: { permissions },
  },
]
