<template>
  <v-row>
    <v-col cols="6">
      <v-autocomplete
        v-model="selecaoProdutor"
        :items="sortedProdutores"
        :loading="((!produtores) || produtores.length === 0)"
        :disabled="((!produtores) || produtores.length === 0)"
        item-text="nome"
        item-value="id"
        return-object
        label="Selecione o Produtor"
        clearable
        dense
        solo
        flat
        autocomplete="off"
        prepend-inner-icon="mdi-account-cowboy-hat"
        class="rounded-lg mx-n2 pt-6 ma-2 caption font-weight-bold"
        style="border-radius: 12px !important;"
        @change="selectProdutor"
        @click:clear="selectProdutor"
      >
        <template
          slot="item"
          slot-scope="data"
        >
          <v-row
            dense
            justify="space-between"
            class="rounded-lg mx-n2 hei"
            style="background-color: white;"
          >
            <v-list-item-content>
              <v-list-item-title
                class="caption font-weight-bold"
              >
                {{ data.item.nome }}
              </v-list-item-title>
            </v-list-item-content>
          </v-row>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        v-model="selecaoFazenda"
        :items="fazendasProdutor"
        :disabled="(!oldProd || ((!fazendas) || fazendas.length === 0))"
        :loading="(!oldProd || ((!fazendas) || fazendas.length === 0))"
        item-text="razao_social"
        item-value="id"
        return-object
        label="Selecione a Fazenda"
        clearable
        dense
        solo
        flat
        autocomplete="off"
        prepend-inner-icon="mdi-cow"
        class="rounded-lg mx-n2 pt-6 ma-2 caption font-weight-bold"
        @change="selectFazenda"
        @click:clear="selectFazenda"
      >
        <template
          slot="item"
          slot-scope="data"
        >
          <v-row
            dense
            class="rounded-lg mx-n2 hei ustify"
            style="background-color: white;"
          >
            <v-list-item-content>
              <v-list-item-title
                class="caption font-weight-bold"
              >
                {{ data.item.nome_fantasia ? data.item.nome_fantasia : data.item.razao_social }}
              </v-list-item-title>
            </v-list-item-content>
          </v-row>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
  import { RESET_STATE, SET_STATE } from '@/store/modules/carrinho'
  import ProdutorStore, { LOAD_BATCH_TECNICO, SET_PRODUTOR_SELECIONADO } from '@/store/modules/produtores'
  import FazendaStore, { ATT_FAZENDAS, LOAD_FAZENDAS, SET_FAZENDA_SELECIONADA } from '@/store/modules/fazendas'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'SelecaoProdutor',
    data () {
      return { oldProd: null, oldFaz: null }
    },
    computed: {
      ...mapState('produtores', ['produtores']),
      ...mapState('fazendas', ['fazendasProdutor', 'fazendas']),
      ...mapFields('produtores', ['selecaoProdutor']),
      ...mapFields('fazendas', ['selecaoFazenda']),
      sortedProdutores () {
        const produtoresToSort = this.produtores
        return this.produtores !== null ? produtoresToSort.sort((a, b) => a.nome.localeCompare(b.nome)) : this.produtores
      },
    },
    async created () {
      if (!this.$store.state.produtores) { this.$store.registerModule('produtores', ProdutorStore) }
      if (!this.$store.state.fazendas) { this.$store.registerModule('fazendas', FazendaStore) }
      await this.LOAD_FAZENDAS()
      this.LOAD_BATCH_TECNICO(this.$user.get().pessoa_id)
      this.seta_Produtor()
    },
    methods: {
      ...mapActions('produtores', [LOAD_BATCH_TECNICO, SET_PRODUTOR_SELECIONADO]),
      ...mapActions('fazendas', [LOAD_FAZENDAS, SET_FAZENDA_SELECIONADA, ATT_FAZENDAS]),
      ...mapMutations('carrinho', [RESET_STATE, SET_STATE]),
      selectProdutor (item) {
        if (this.oldProd !== null && this.$store.state.carrinho.produtos.length > 0) {
          this.RESET_STATE()
          if (item !== undefined) {
            this.oldProd = item
            localStorage.setItem('Produtor-Selecionado', JSON.stringify(item))
            this.SET_PRODUTOR_SELECIONADO(item)
            this.ATT_FAZENDAS(item.id)
            if (this.fazendasProdutor.length === 1) {
              this.selectFazenda(this.fazendasProdutor[0])
            } else {
              localStorage.removeItem('Fazenda-Selecionada')
            }
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
            this.$store.commit('produtores/SET_STATE', { selecaoProdutor: null })
            this.oldProd = null
            this.oldFaz = null
            localStorage.removeItem('Produtor-Selecionado')
            localStorage.removeItem('Fazenda-Selecionada')
          }
          if ((this.$route.path.includes('loja/pedido') || !this.$route.path.includes('loja')) && (!this.$route.path.includes('volume') && !this.$route.path.includes('qualidades'))) {
            this.$router.push({ path: `/${this.$user.get().role}/loja` })
          }
          return true
        } else {
          this.RESET_STATE()
          if (item !== undefined) {
            this.oldProd = item
            localStorage.setItem('Produtor-Selecionado', JSON.stringify(item))
            this.SET_PRODUTOR_SELECIONADO(item)
            this.ATT_FAZENDAS(item.id)
            if (this.fazendasProdutor.length === 1) {
              this.selectFazenda(this.fazendasProdutor[0])
            }
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
            this.$store.commit('produtores/SET_STATE', { selecaoProdutor: null })
            this.oldProd = null
            this.oldFaz = null
            localStorage.removeItem('Produtor-Selecionado')
            localStorage.removeItem('Fazenda-Selecionada')
          }
          if ((this.$route.path.includes('loja/pedido') || !this.$route.path.includes('loja')) && (!this.$route.path.includes('volume') && !this.$route.path.includes('qualidades'))) {
            this.$router.push({ path: `/${this.$user.get().role}/loja` })
          }
        }
      },
      seta_Produtor () {
        const produtorLocal = localStorage.getItem('Produtor-Selecionado')
        if ((produtorLocal) && produtorLocal !== 'undefined') {
          this.selectProdutor(JSON.parse(produtorLocal))
          this.seta_Fazenda()
        }
      },
      selectFazenda (item) {
        if (this.fazendasSelecionada !== null && this.$store.state.carrinho.produtos.length > 0) {
          this.RESET_STATE()
          if (item !== undefined) {
            localStorage.setItem('Fazenda-Selecionada', JSON.stringify(item))
            this.fazendaSelecionada = item
            this.SET_FAZENDA_SELECIONADA(item)
            const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + item.id)))
            if (produtos !== null) {
              this.SET_STATE({ produtos })
            }
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
            localStorage.removeItem('Fazenda-Selecionada')
            this.oldFaz = null
          }
          if ((this.$route.path.includes('loja/pedido') || !this.$route.path.includes('loja')) && (!this.$route.path.includes('volume') && !this.$route.path.includes('qualidades'))) {
            this.$router.push({ path: `/${this.$user.get().role}/loja` })
          }
          return true
        } else {
          this.RESET_STATE()
          if (item !== undefined) {
            this.oldFaz = item
            this.SET_FAZENDA_SELECIONADA(item)
            localStorage.setItem('Fazenda-Selecionada', JSON.stringify(item))
            const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + item.id)))
            if (produtos !== null) {
              this.SET_STATE({ produtos })
            }
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
            this.oldFaz = null
            localStorage.removeItem('Fazenda-Selecionada')
          }
          if ((this.$route.path.includes('loja/pedido') || !this.$route.path.includes('loja')) && (!this.$route.path.includes('volume') && !this.$route.path.includes('qualidades'))) {
            this.$router.push({ path: `/${this.$user.get().role}/loja` })
          }
          return true
        }
      },
      seta_Fazenda () {
        const fazendaLocal = localStorage.getItem('Fazenda-Selecionada')
        if (fazendaLocal && fazendaLocal !== undefined) {
          this.selectFazenda(JSON.parse(fazendaLocal))
        }
      },
    },
  }
</script>
