import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const BOOTSTRAP_FAZENDA = 'BOOTSTRAP_FAZENDA'
export const BOOTSTRAP_DASHBOARD = 'BOOTSTRAP_DASHBOARD'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const LOAD_BATCH_FAZENDA = 'LOAD_BATCH_FAZENDA'
export const LOAD_BATCH_DASHBOARD = 'LOAD_BATCH_DASHBOARD'
export const DELETE_ANALISE = 'DELETE_ANALISE'
export const RESET_STATE = 'RESET_STATE'
export const NEW_CALC = 'NEW_CALC'
export const CLOSE = 'CLOSE'

const getDefaultState = () => {
  return {
    dialog: false,
    ready: false,
    qualidades: [],
    qualidade_dia: [],
    media_qualidades: [],
    dashboard: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [CLOSE] (state) {
    state.dialog = false
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH, { data_inicial, data_final })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    try {
      const qualidades = await api.listEntidade(`qualidade/${data_inicial}/${data_final}`)

      const processedQualidades = qualidades.map(el => {
        if (el.sigla_analise === 'CPP' || el.sigla_analise === 'CCS' || el.sigla_analise === 'CBT') {
          return { ...el, valor_analise: parseInt(el.valor_analise) / 1000 }
        } else {
          return { ...el, valor_analise: parseFloat(el.valor_analise).toFixed(2) }
        }
      })

      commit(SET_STATE, { qualidades: processedQualidades })
    } catch (error) {
      console.error('Error loading qualidades:', error)
    }
  },
  async [BOOTSTRAP_FAZENDA] ({ dispatch, commit }, { data_inicial, data_final, id } = { data_inicial: 'from', data_final: 'to', id: '' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH_FAZENDA, { data_inicial, data_final, id })
    commit(SET_STATE, { ready: true })
  },
  async [NEW_CALC] ({ commit }) {
    commit(SET_STATE, { dialog: true })
  },
  async [LOAD_BATCH_FAZENDA] ({ dispatch, commit, rootState }, { data_inicial, data_final, id } = { data_inicial: 'from', data_final: 'to', id: '' }) {
    let dataInicioTrimestre = new Date(data_inicial.slice(0, 4), data_inicial.slice(5, 7) - 1, 1)
    dataInicioTrimestre.setMonth(dataInicioTrimestre.getMonth() - 2)
    dataInicioTrimestre = dataInicioTrimestre.toISOString().slice(0, 10)

    try {
      const [medias_qualidades, qualidades] = await Promise.all([
        api.listEntidade('mediasqualidade/' + dataInicioTrimestre + '/' + data_final),
        api.listEntidade('qualidade/' + data_inicial + '/' + data_final),
      ])

      const media_qualidades = medias_qualidades
        .filter(vol => vol.fazenda_id === id)
        .map(el => {
          if (['CBT', 'CPP', 'CCS'].includes(el.sigla_analise)) {
            el.valor_mensal = parseInt(el.valor_mensal) / 1000
            el.valor_trimestre = parseInt(el.valor_trimestre) / 1000
          }
          return el
        })

      const dados = qualidades.filter(vol => vol.fazenda_id === id && vol.status === 1)
      dados.sort((a, b) => (a.data_analise < b.data_analise ? -1 : a.data_analise > b.data_analise ? 1 : 0))

      const qualidade_dia = dados.reduce((acc, el) => {
        const valor_analise = ['CBT', 'CPP', 'CCS'].includes(el.sigla_analise) ? parseInt(el.valor_analise) / 1000 : el.valor_analise
        const qualidade_obj = {
          sigla: el.sigla_analise,
          dia: el.data_analise.slice(8, 10),
          valor: valor_analise,
          status: el.status,
          coleta: el.codigo_coleta,
        }
        if (rootState.user.roles[0].slug === 'produtor' && el.status === 1) {
          acc.push(qualidade_obj)
        } else if (rootState.user.roles[0].slug !== 'produtor') {
          acc.push(qualidade_obj)
        }
        return acc
      }, [])

      commit(SET_STATE, { media_qualidades, qualidade_dia, qualidades })
    } catch (error) {
      console.error('Failed to load data:', error)
    }
  },
  async [BOOTSTRAP_DASHBOARD] ({ dispatch, commit }, { from, to } = { from: null, to: null }) {
    commit(SET_STATE, { ready: false, dashboard: [] })
    await dispatch(LOAD_BATCH_DASHBOARD, { from, to })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH_DASHBOARD] ({ commit, rootState }, { from, to }) {
    const path = from && to ? 'mediasqualidade/dashboard/' + from + '/' + to : 'mediasqualidade/dashboard'

    await api.listEntidade(path).then(
      dashboard => {
        commit(SET_STATE, { dashboard })
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
