import { relatorios } from '../utils/constants'
const router = function (permissions) {
  return ({
    path: 'relatorio',
    component: () => import('@/views/Relatorio/RelatorioModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        redirect: '/',
      },
      {
        path: relatorios.acompanhamentoSemanalVendas,
        meta: { permissions, title: 'Acompanhamento Semanal de Vendas', relatorio: relatorios.acompanhamentoSemanalVendas },
        component: () => import('@/views/Relatorio/RelAcompSemanalVendas.vue'),
      },
      {
        path: relatorios.vendasUnidadesNegocio,
        meta: { permissions, title: 'Vendas por Unidades de Negócio', relatorio: relatorios.vendasUnidadesNegocio },
        component: () => import('@/views/Relatorio/RelVendasUnidadesNegocio.vue'),
      },
      {
        path: relatorios.vendasTecnicos,
        meta: { permissions, title: 'Vendas por Técnicos', relatorio: relatorios.vendasTecnicos },
        component: () => import('@/views/Relatorio/RelVendasTecnicos.vue'),
      },
      {
        path: relatorios.vendasProdutores,
        meta: { permissions, title: 'Vendas por Produtores', relatorio: relatorios.vendasProdutores },
        component: () => import('@/views/Relatorio/RelVendasProdutores.vue'),
      },
      {
        path: relatorios.desempenhoPromocoes,
        meta: { permissions, title: 'Desempenho de Promoções', relatorio: relatorios.desempenhoPromocoes },
        component: () => import('@/views/Relatorio/RelDesempenhoPromocoes.vue'),
      },
      {
        path: relatorios.produtosMaisVendidos,
        meta: { permissions, title: 'Produtos mais Vendidos', relatorio: relatorios.produtosMaisVendidos },
        component: () => import('@/views/Relatorio/RelProdutosMaisVendidos.vue'),
      },
      {
        path: relatorios.produtoresSemCompra,
        meta: { permissions, title: 'Produtores sem compra no período', relatorio: relatorios.produtoresSemCompra },
        component: () => import('@/views/Relatorio/RelProdutoresSemCompra.vue'),
      },
      {
        path: relatorios.produtoresFazendas,
        meta: { permissions, title: 'Fazendas por produtor', relatorio: relatorios.produtoresFazendas },
        component: () => import('@/views/Relatorio/RelProdutoresFazendas.vue'),
      },
    ],
  })
}

export default {
  router,
}
