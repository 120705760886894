export const getProperty = (item, string) => {
  if (item === undefined || item === null) return ''

  const parts = string.split('.')

  let current = item

  for (const part of parts) {
    if (current === undefined || current === null) return ''

    // Check if part contains array indices, e.g., roles[0].description
    const arrayMatch = part.match(/^([a-zA-Z_$][0-9a-zA-Z_$]*)(\[(\d+)\])?$/)

    if (arrayMatch) {
      const propName = arrayMatch[1]
      const arrayIndex = arrayMatch[3] !== undefined ? parseInt(arrayMatch[3], 10) : undefined

      if (arrayIndex !== undefined) {
        if (Array.isArray(current[propName])) {
          current = current[propName][arrayIndex]
        } else {
          return '' // Not an array, return empty string
        }
      } else {
        current = current[propName]
      }
    } else {
      current = current[part]
    }
  }

  return current !== undefined ? current : ''
}

export const getSubProperty = (item, arrayString) => {
  let result = item
  arrayString.forEach((string) => {
    if (!result || !result[string]) {
      result = ''
      return
    }
    result = result[string]
  })
  return result
}

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    const successCallback = (position) => {
      const location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      resolve(location)
    }

    const errorCallback = (error) => {
      const { code } = error
      switch (code) {
        case error.TIMEOUT:
          reject(new Error('A requisição para obter a localização do usuário excedeu o tempo limite.'))
          break
        case error.PERMISSION_DENIED:
          window.Toast.fire({
            icon: 'error',
            title: 'Atenção',
            text: 'Foi negado acesso às informações de localização do usuário!',
            timer: 5000,
          })
          reject(new Error('Permissão para acessar a localização foi negada.'))
          break
        case error.POSITION_UNAVAILABLE:
          window.Toast.fire('As informações de localização do usuário são inválidas!', '', 'error')
          reject(new Error('As informações de localização não estão disponíveis.'))
          break
        default:
          reject(new Error('Ocorreu um erro desconhecido ao obter a localização.'))
      }
    }

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 600000 })
    } else {
      reject(new Error('Geolocalização não é suportada por este navegador.'))
    }
  })
}

export const clone = (obj) => JSON.parse(JSON.stringify(obj))

export default {
  getLocation,
  getProperty,
  clone,
}
