import { v4 } from 'uuid'
export class Regra {
  constructor ({
    id,
    nome,
    tipo,
    produtos,
    condicoes,
    unidade,
  } = {
    id: v4(),
    nome: '',
    tipo: '',
    unidade: null,
    produtos: [],
    condicoes: [
      {
        volume: '',
        desconto: '',
        parcelamento: '',
        quantidade: '',
        calculo: '',
        vlr_parcela: '',
        regra_tecnico: '0',
        bonificacoes: [],
      },
    ],
  }) {
    this.id = id
    this.nome = nome
    this.tipo = tipo
    this.unidade = unidade
    this.produtos = produtos.map(p => ({ ...p, regra: id }))
    this.condicoes = condicoes.map(c => new Condicao(c))
  }

  addCondicao () {
    this.condicoes.push(new Condicao())
  }

  removeCondicao (index) {
    return this.condicoes.splice(index, 1)
  }

  clearCondicoes () {
    this.condicoes = [new Condicao()]
  }
}

class Condicao {
  constructor ({
    volume = '',
    desconto = '',
    parcelamento = '',
    quantidade = '',
    calculo = '',
    vlr_parcela = '',
    regra_tecnico = '0',
    bonificacoes = [],
  } = {
    volume: '',
    desconto: '',
    parcelamento: '',
    quantidade: '',
    calculo: '',
    vlr_parcela: '',
    regra_tecnico: '0',
    bonificacoes: [],
  }) {
    this.volume = volume
    this.desconto = desconto
    this.parcelamento = parcelamento
    this.quantidade = quantidade
    this.calculo = calculo
    this.vlr_parcela = vlr_parcela
    this.regra_tecnico = regra_tecnico
    this.bonificacoes = bonificacoes
  }
}
export function createRegra (data) {
  return Object.freeze(new Regra(data))
}
