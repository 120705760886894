import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const NEW_UNIDADE = 'NEW_UNIDADE'
export const EDIT_UNIDADE = 'EDIT_UNIDADE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const DELETE_UNIDADE = 'DELETE_UNIDADE'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    descontosCategoria: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { id }) {
    commit(SET_STATE, { ready: true })
    await dispatch(LOAD_BATCH, { id })
    commit(SET_STATE, { ready: false })
  },
  async [LOAD_BATCH] ({ commit }, { id }) {
    const unidades = { unidades: [id] }
    await api.cadastrarEntidade(unidades, 'descontos/unidades')
      .then((descontosCategoria) => {
        commit(SET_STATE, { descontosCategoria })
      })
  },
  [NEW_UNIDADE] ({ dispatch }, unidade_negocio_id) {
    dispatch('form/descontoCategoria/BOOTSTRAP_PROFILE', { id: null, unidade_negocio_id }, { root: true })
  },
  [EDIT_UNIDADE] ({ dispatch }, descontosCategoria) {
    dispatch('form/descontoCategoria/BOOTSTRAP_PROFILE', { id: descontosCategoria.id, unidade_negocio_id: descontosCategoria.unidade_negocio_id }, { root: true })
  },
  async [DELETE_UNIDADE] ({ dispatch, commit, state }, descontosCategoria) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a Configuração?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('descontos', descontosCategoria.id).then(
          (result) => result,
          ({
            error = null,
            message = 'Não foi possivel excluir a Configuração!',
          }) => {
            Swal.fire({
              icon: 'error',
              title: error === null ? 'Ocorreu algum erro!' : 'Erro!',
              text: message,
            })
          },
        )
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.hideLoading()
        window.Toast.fire('A Configuração foi excluída com sucesso!', '', 'success')
        dispatch(BOOTSTRAP, { id: descontosCategoria.unidade_negocio.id })
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
