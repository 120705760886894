import api from '@/api/api'
import Swal from 'sweetalert2'
import { currencyFormatter } from '../../utils/formatter'
import { DisponibilidadeCatalogo } from '../../utils/constants'
import Vue from 'vue'

export const LOAD_UNIDADES_NEGOCIO_GESTOR = 'LOAD_UNIDADES_NEGOCIO_GESTOR'
export const REMOVE_ITEM_FROM_CATALOGO = 'REMOVE_ITEM_FROM_CATALOGO'
export const LOAD_BATCH = 'LOAD_BATCH'
export const SET_UNIDADES_NEGOCIO = 'SET_UNIDADES_NEGOCIO'
export const SET_PRODUTO_CATALOGO = 'SET_PRODUTO_CATALOGO'
export const ADD_PRODUTO_CATALOGO = 'ADD_PRODUTO_CATALOGO'
export const SET_CATALOGO = 'SET_CATALOGO'
export const BOOTSTRAP = 'BOOTSTRAP'
export const DIALOG = 'DIALOG'
export const READY = 'READY'
export const SET_STATE = 'SET_STATE'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    catalogo: null,
    unidades_negocio: null,
    ready: false,
  }
}

const state = getDefaultState()

const getters = {
  produtos: state => state.produtos,
  catalogo: state => state.catalogo,
  unidades_negocio: state => state.unidades_negocio,
}

const mutations = {
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [SET_CATALOGO] (state, { catalogo }) {
    state.catalogo = catalogo
  },
  [SET_PRODUTO_CATALOGO] (state, { item }) {
    const index = state.catalogo.findIndex(cat_item => cat_item.id === item.id)
    if (~index) {
      Object.assign(state.catalogo[index], item)
    }
  },
  [ADD_PRODUTO_CATALOGO] (state, { item }) {
    state.catalogo.push(item)
  },
  [SET_UNIDADES_NEGOCIO] (state, { unidades_negocio }) {
    state.unidades_negocio = unidades_negocio
  },
  [READY] (state) {
    state.ready = true
  },
  [REMOVE_ITEM_FROM_CATALOGO] (state, { id }) {
    const index = state.catalogo.findIndex(item => item.id === id)
    state.catalogo.splice(index, 1)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_BATCH] ({ commit, state }) {
    const catalogoData = (await api.listEntidade(`${Vue.prototype.$user.get().role}/catalogo`)).map(
      ({ ...rest }) => {
        const acc = {
          ...rest,
          disponibilidade: rest.disponibilidade,
          preco: currencyFormatter(rest.preco),
        }
        return acc
      }, [],
    )
    commit(SET_CATALOGO, { catalogo: catalogoData })
  },
  async [LOAD_UNIDADES_NEGOCIO_GESTOR] ({ commit }) {
    commit(SET_UNIDADES_NEGOCIO, { unidades_negocio: await api.listEntidade(`${Vue.prototype.$user.get().role}/unidadesNegocio`) })
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { ready: false })
    await Promise.all([dispatch(LOAD_UNIDADES_NEGOCIO_GESTOR), dispatch(LOAD_BATCH)])
    commit(READY)
  },
  async [REMOVE_ITEM_FROM_CATALOGO] ({ dispatch, commit, state }, item) {
    if (item.disponibilidade === DisponibilidadeCatalogo.EM_OFERTA) {
      window.Toast.fire('Não é possível excluir um produto em oferta do catálogo!', '', 'error')
      return false
    }
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o produto do catálogo dessa unidade de negócio?',
        icon: 'question',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonColor: '#109010',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        focusConfirm: false,
        preConfirm: () => {
          return api.inativarEntidade('catalogo', item.id).then(
            result => result,
            () => {
              window.Toast.fire('Não foi possivel excluir o Produto do Catálogo!', '', 'error')
              resolve(false)
            },
          )
        },
      }).then(result => {
        if (result.isConfirmed) {
          window.Toast.fire('O Produto foi excluído com sucesso do Catálogo!', '', 'success')
          commit(REMOVE_ITEM_FROM_CATALOGO, { id: item.id })
          dispatch(LOAD_BATCH)

          resolve(true)
        } else {
          resolve(false)
        }
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
