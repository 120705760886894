const router = function (permissions) {
    return ({
      path: 'cdv/pedidos',
      component: () => import('@/views/Pedido/PedidoModule.vue'),
      meta: { permissions, title: 'Manutenção de Pedidos' },
      children: [
        {
          path: '/',
          meta: { permissions, title: 'Manutenção de Pedidos' },
          component: () => import('@/views/Pedido/PedidoListProdutor.vue'),
        },
        {
          path: 'pedidos',
          component: () => import('@/views/Pedido/PedidoDetalhes.vue'),
          meta: { permissions, title: 'Pedido individual' },
        },
      ],
    })
  }

  export default {
    router,
  }
