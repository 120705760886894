<template>
  <div style="padding-right: 50px; width: 100%;">
    <div v-if="periodo === 'Mensal'">
      <div
        v-if="analise === 'CBT'"
      >
        <material-chart-card
          v-if="columnsCPP"
          :options="optEvolucaoCBT"
          :data="columnsCPP"
          class="mt-5"
          type="Bar"
          title="Resultado CBT"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'CCS'"
      >
        <material-chart-card
          v-if="columnsCCS"
          :options="optEvolucaoCCS"
          :data="columnsCCS"
          class="mt-5"
          title="Resultado CCS"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'GORD'"
      >
        <material-chart-card
          v-if="columnsGORD"
          :options="optEvolucaoGORD"
          :data="columnsGORD"
          class="mt-5"
          title="Resultado GORD"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'PROT'"
      >
        <material-chart-card
          v-if="columnsPROT"
          :options="optEvolucaoPROT"
          :data="columnsPROT"
          class="mt-5"
          type="Bar"
          title="Resultado PROT"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'EST'"
      >
        <material-chart-card
          v-if="columnsEST"
          :options="optEvolucaoEST"
          :data="columnsEST"
          class="mt-5"
          title="Resultado EST"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'ESD'"
      >
        <material-chart-card
          v-if="columnsESD"
          :options="optEvolucaoESD"
          :data="columnsESD"
          class="mt-5"
          type="Bar"
          title="Resultado ESD"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'LACT'"
      >
        <material-chart-card
          v-if="columnsLACT"
          :options="optEvolucaoLACT"
          :data="columnsLACT"
          class="mt-5"
          title="Resultado LACT"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'CMP'"
      >
        <material-chart-card
          v-if="columnsCMP"
          :options="optEvolucaoCMP"
          :data="columnsCMP"
          title="Resultado CMP"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div
        v-else-if="analise === 'NUL'"
      >
        <material-chart-card
          v-if="columnsNUL"
          :options="optEvolucaoNUL"
          :data="columnsNUL"
          class="mt-5"
          type="Bar"
          title="Resultado NUL"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
    </div>
    <div
      v-else-if="periodo === 'Anual'"
    >
      <div v-if="analise === 'CBT'">
        <material-chart-card
          v-if="resultadoGraficoAnualCPP.obj"
          :options="optEvolucaoCBT"
          :data="resultadoGraficoAnualCPP.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'CCS'">
        <material-chart-card
          v-if="resultadoGraficoAnualCCS.obj"
          :options="optEvolucaoCCS"
          :data="resultadoGraficoAnualCCS.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'GORD'">
        <material-chart-card
          v-if="resultadoGraficoAnualGORD.obj"
          :options="optEvolucaoGORD"
          :data="resultadoGraficoAnualGORD.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'PROT'">
        <material-chart-card
          v-if="resultadoGraficoAnualPROT.obj"
          :options="optEvolucaoPROT"
          :data="resultadoGraficoAnualPROT.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'EST'">
        <material-chart-card
          v-if="resultadoGraficoAnualEST.obj"
          :options="optEvolucaoEST"
          :data="resultadoGraficoAnualEST.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'ESD'">
        <material-chart-card
          v-if="resultadoGraficoAnualESD.obj"
          :options="optEvolucaoESD"
          :data="resultadoGraficoAnualESD.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'NUL'">
        <material-chart-card
          v-if="resultadoGraficoAnualNUL.obj"
          :options="optEvolucaoNUL"
          :data="resultadoGraficoAnualNUL.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'CMP'">
        <material-chart-card
          v-if="resultadoGraficoAnualCMP.obj"
          :options="optEvolucaoCMP"
          :data="resultadoGraficoAnualCMP.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
      <div v-else-if="analise === 'LACT'">
        <material-chart-card
          v-if="resultadoGraficoAnualLACT.obj"
          :options="optEvolucaoLACT"
          :data="resultadoGraficoAnualLACT.obj"
          class="mt-5"
          type="Bar"
          color="#143693"
          icon="mdi-chart-timeline-variant-shimmer"
          elevation="0"
          max-width="100%"
          min-height="430px"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import Tooltips from 'chartist-plugin-tooltips-updated'
  // import Legend from 'chartist-plugin-legend'

  export default {
    name: 'QualidadeProdutor',
    components: {
      MaterialChartCard,
    },
    props: {
      analise: {
        type: String,
        default: () => '',
      },
      periodo: {
        type: String,
        default: () => '',
      },
    },
    data () {
      return {
        currencyFormatter,
        chartOptions: {
          lineSmooth: false,
        },
        optEvolucaoCBT: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 300,
            labelInterpolationFnc: function (value) {
              return value + 'K'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoCCS: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 1000,
            labelInterpolationFnc: function (value) {
              return value + 'K'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoGORD: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 4,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoPROT: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 4,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoEST: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 12,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoESD: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 12,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoLACT: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 4,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoCMP: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 12,
            labelInterpolationFnc: function (value) {
              return value + ',00%'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        optEvolucaoNUL: {
          axisY: {
            onlyInteger: true,
            offset: 50,
            low: 0,
            high: 15,
            labelInterpolationFnc: function (value) {
              return value + 'K'
            },
          },
          height: 290,
          plugins: [
            // Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return value },
            }),
            this.pontos({
              textAnchor: 'middle',
              labelInterpolationFnc: function (value) { return value },
            }),
          ],
          chartPadding: {
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }
    },
    computed: {
      ...mapState('qualidades', ['ready', 'qualidade_dia', 'media_qualidades']),
      resultadoGraficoAnualCPP: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'CBT' || item.sigla_analise === 'CPP')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(currencyFormatter(item.valor_mensal, 0))
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualCCS: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'CCS')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(currencyFormatter(item.valor_mensal, 0))
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualGORD: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'GORD')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualPROT: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'PROT')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualEST: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'EST' || item.sigla_analise === 'SOLI')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualESD: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'ESD')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualNUL: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'NU')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualCMP: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'CMP' || item.sigla_analise === 'CASE')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      resultadoGraficoAnualLACT: function () {
        const dados = this.media_qualidades.filter(item => item.sigla_analise === 'LACT')

        const labels = []
        const series = [[]]

        dados.forEach(item => {
          const data = new Date(item.data_referencia)
          const mes = data.toLocaleDateString('pt-BR', { month: 'short' }).replace('.', '') // Remove o ponto do final
          const ano = data.getFullYear()
          const mesAno = `${mes}/${ano}`
          labels.push(mesAno)
          series[0].push(item.valor_mensal)
        })

        const obj = {
          labels,
          series,
        }
        return { obj }
      },
      columnsCPP: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'CBT' || item.sigla === 'CPP').reverse()

        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          // acc.series.push({ meta: 'algo', value: item.valor })
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsCCS: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'CCS').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsGORD: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'GORD').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsPROT: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'PROT').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsEST: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'EST' || item.sigla === 'SOLI').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsESD: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'ESD').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsNUL: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'NUL' || item.sigla === 'NU').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsCMP: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'CMP' || item.sigla === 'CASE').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
      columnsLACT: function () {
        const dados = this.qualidade_dia.filter(item => item.sigla === 'LACT').reverse()
        const resultado = dados.reduce((acc, item) => {
          acc.labels.push('Col. ' + item.coleta)
          acc.series[0].push(item.valor)
          return acc
        }, { labels: [], series: [[]] })
        return resultado
      },
    },
    methods: {
      pontos (options) {
        var defaultOptions = {
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -10,
          },
          textAnchor: 'middle',
          align: 'center',
          labelInterpolationFnc: this.$chartist.noop,
        }

        var labelPositionCalculation = {
          point: function (data) {
            return {
              x: data.x,
              y: data.y,
            }
          },
          bar: {
            left: function (data) {
              return {
                x: data.x1,
                y: data.y1,
              }
            },
            top: function (data) {
              return {
                x: data.x1,
                y: data.y2,
              }
            },
            center: function (data) {
              return {
                x: data.x1 + (data.x2 - data.x1) / 2,
                y: data.y1,
              }
            },
            right: function (data) {
              return {
                x: data.x2,
                y: data.y1,
              }
            },
          },
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        function addLabel (position, data) {
          var value = data.value.x !== undefined && data.value.y
            ? (data.value.x + ', ' + data.value.y)
            : data.value.y || data.value.x

          value = value === undefined ? 0 : value

          data.group.elem('text', {
            x: position.x + options.labelOffset.x,
            y: position.y + options.labelOffset.y,
            style: 'text-anchor: ' + options.textAnchor,
          }, options.labelClass).text(options.labelInterpolationFnc(value))
        }

        return function ctPointLabels (chart) {
          chart.on('draw', function (data) {
            var positonCalculator = (labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align]) || labelPositionCalculation[data.type]
            if (positonCalculator) {
              addLabel(positonCalculator(data), data)
            }
          })
        }
      },
    },
  }
</script>
