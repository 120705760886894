import api from '@/api/api'
import { Pedido } from '@/models/Pedido'
import rules from '@/utils/formRules'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'
import { statusPedido } from '../../../utils/constants'

export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const SUBMIT = 'SUBMIT'
export const SET_STATE = 'SET_STATE'
export const CLOSE = 'CLOSE'
export const DELETE_BONIFICACAO = 'DELETE_BONIFICACAO'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    dialog: false,
    loading: true,
    rules,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, state }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, dialog: true })
    dispatch('form/BOOTSTRAP_FORM', { model: new Pedido() }, { root: true })
    if (id) {
      await api.getEntidade('pedido', id).then(response => {
        response.data.statusPrevious = response.data.status
        response.data.status = ''
        dispatch('form/BOOTSTRAP_FORM', { model: new Pedido(response.data) }, { root: true })
      })
    }
    commit(SET_STATE, { loading: false })
  },

  async [DELETE_BONIFICACAO] ({ dispatch, state }, bonificacao) {
    return await Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que deseja excluir o produto bonificado?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('pedido/bonificacao', bonificacao.id).then(
          result => result,
          () => {
            window.Toast.fire('Atenção...', 'Ocorreu algum problema na exclusão do produto bonificado!', 'error')
          },
        ).catch(() => {
          window.Toast.fire('Atenção...', 'Ocorreu algum problema na exclusão do produto bonificado!', 'error')
        })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire('Atenção...', 'O Produto bonificado foi excluído com sucesso!', 'success')
      }
    })
  },

  async [SUBMIT] ({ dispatch, commit, state, rootState }) {
    const form = { ...rootState.form.data }
    if (form.status === statusPedido.cancelamento_solicitado ||
      form.status === statusPedido.aceite_produtor ||
      form.status === statusPedido.nao_sincronizado ||
      form.status === statusPedido.entregue_produtor) {
      commit('CLOSE')
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Não é possível alterar o Pedido para esse status!',
        timer: 2000,
      })
    } else {
      Swal.fire({
        title: 'Editando o Pedido!',
        showConfirmButton: false,
        timer: 1500,
      })
      Swal.showLoading()
      api.updatePedidoStatus(form).then(response => {
        dispatch('pedidos/ATUALIZAR_PEDIDO', { id: form.id, status: form.status }, { root: true })
        commit('CLOSE')
        Swal.hideLoading()
        Swal.update({
          icon: 'success',
          title: 'O Pedido foi editado com sucesso!',
        })
      }).catch(err => {
        console.error(err)
        Swal.hideLoading()
        Swal.update({
          icon: 'error',
          title: 'Não foi possível editar o Pedido, favor tentar novamente.',
        })
      })
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
