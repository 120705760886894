import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import Swal from 'sweetalert2'
import pedido from './forms/pedido'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const CLOSE = 'CLOSE'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const LOAD_UNIDADES_NEGOCIO = 'LOAD_UNIDADES_NEGOCIO'

const getDefaultState = () => {
  return {
    loading: true,
    dialog: false,
    uNegocio: [],
    pedido_id: '',
    numero_pedido: '',
    data_pedido: '',
    data_entrega: '',
    valor_total: '',
    nome_produtor: '',
    nome_fazenda: '',
    unidade_negocio: '',
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { id }) {
    commit(SET_STATE, { ready: false, loading: true, dialog: true })
    await dispatch(LOAD_BATCH, { id })
    await dispatch(LOAD_UNIDADES_NEGOCIO)
    commit(SET_STATE, { ready: true, loading: false })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { id }) {
    await api.getEntidade('pedido', id).then(response => {
      commit(SET_STATE, {
        pedido_id: response.data.id,
        numero_pedido: response.data.pedido_id,
        data_pedido: response.data.data_pedido,
        data_entrega: response.data.data_prevista_entrega,
        valor_total: response.data.valor_total,
        nome_produtor: response.data.nome_produtor,
        nome_fazenda: response.data.nome_fazenda,
        unidade_negocio: response.data.unidadeNegocio.nome_fantasia,
      })
    },
    )
  },
  async [LOAD_UNIDADES_NEGOCIO] ({ dispatch, commit }) {
    await api.listEntidade('unidadeNegocio/All').then(
      uNegocio => {
        commit(SET_STATE, { uNegocio })
      },
    )
  },
  async [SUBMIT] ({ commit, dispatch, rootState }, { unidade, id }) {
    Swal.fire({
      title: 'Transferindo Pedido!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        (() => {
          return api.transferePedidoUnidadeNegocio({ unidade_negocio: unidade }, id)
        })().then(
          data => {
            const now = new Date()
            const data_i = new Date(now.getFullYear(), now.getMonth() - 1, 1)
            const data_f = new Date(now.getFullYear(), now.getMonth() + 1, 0)
            dispatch('pedidos/BOOTSTRAP', { data_inicial: data_i.toISOString().slice(0, 10), data_final: data_f.toISOString().slice(0, 10) }, { root: true })
            commit('CLOSE')
            Swal.fire({
              icon: 'success',
              title: 'Pedido transferido com sucesso!',
              showConfirmButton: false,
              timer: 1500,
            })
          },
          error => {
            Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: error.message
                ? error.message
                : 'Não foi possivel transferir o Pedido!',
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
