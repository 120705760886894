import profile from './profile'
import keys from './keys'
import fazendas from './fazendas'
import produtos from './produtos'
import categorias from './categorias'
import ofertas from './ofertas'
import catalogos from './catalogos'
import unidadesmedida from './unidadesmedida'
import produtores from './produtores'
import unidadesnegocio from './unidadesnegocio'
import tecnicos from './tecnicos'
import gestores from './gestores'
import supervisores from './supervisores'
import fornecedores from './fornecedores'
import administradores from './administradores'
import regionais from './regionais'
import integracoes from './integracoes'
import notificacoesTecnico from './notificacoesTecnico'
import notificacoesProdutor from './notificacoesProdutor'
import relatorios from './relatorios'
import cadastros from './cadastros'
import volumes from './volumes'
import qualidades from './qualidades'
import pedidos from './pedidos'
import cotacoes from './cotacoes'
import logs from './logs'
import dialogos from './dialogos'
import voucher from './voucher'

const permissions = [
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'tecnico',
    access: false,
    redirect: 'tecnico',
  },
  {
    role: 'produtor',
    access: false,
    redirect: 'produtor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

export default {
  /* ACESSO ADMINISTRADOR */
  name: 'admin',
  path: '/admin',
  redirect: '/admin/dashboard',
  component: () => import('@/views/Index'),
  meta: { permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/dashboard/DashboardAdmin'),
      meta: { permissions, title: 'Início' },
    },
    {
      path: 'conf/acessos',
      component: () => import('@/views/User/ControleAcesso.vue'),
      meta: { permissions, title: 'Controle de Acesso' },
    },
    produtos.router(permissions),
    unidadesnegocio.router(permissions),
    tecnicos.router(permissions),
    administradores.router(permissions),
    ofertas.router(permissions),
    catalogos.router(permissions),
    gestores.router(permissions),
    supervisores.router(permissions),
    fornecedores.router(permissions),
    produtores.router(permissions),
    fazendas.router(permissions),
    categorias.router(permissions),
    unidadesmedida.router(permissions),
    regionais.router(permissions),
    profile.router(permissions),
    keys.router(permissions),
    integracoes.router(permissions),
    notificacoesTecnico.router(permissions),
    notificacoesProdutor.router(permissions),
    volumes.router(permissions),
    relatorios.router(permissions),
    cadastros.router(permissions),
    qualidades.router(permissions),
    pedidos.router(permissions),
    cotacoes.router(permissions),
    logs.router(permissions),
    dialogos.router(permissions),
    voucher.router(permissions),
  ],
}
