const router = function (permissions) {
  return ({
    path: 'supervisores',
    component: () => import('@/views/Supervisor/SupervisorModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Supervisor/SupervisorList.vue'),
        meta: { permissions, title: 'Manutenção de Supervisores' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Supervisor/SupervisorProfile.vue'),
        meta: { permissions, title: 'Cadastrando Supervisor' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Supervisor/SupervisorProfile.vue'),
        meta: { permissions, title: 'Editando Supervisor' },
      },
    ],
  })
}

export default {
  router,
}
