import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const BOOTSTRAP_PRODUTOR = 'BOOTSTRAP_PRODUTOR'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const LOAD_BATCH_PRODUTOR = 'LOAD_BATCH_PRODUTOR'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    dashboard: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit }, { from, to } = { from: null, to: null }) {
    commit(SET_STATE, { ready: false, dashboard: [] })
    await dispatch(LOAD_BATCH, { from, to })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ commit }, { from, to }) {
    const path = from === 'from' ? 'dashboard' : `dashboard/${from}/${to}`

    await api.listEntidade(path).then(
      dashboard => {
        commit(SET_STATE, { dashboard })
      },
    )
  },
  async [BOOTSTRAP_PRODUTOR] ({ dispatch, commit }, { id }) {
    commit(SET_STATE, { ready: false, dashboard: [] })
    if (id) {
      await dispatch(LOAD_BATCH_PRODUTOR, { id })
    }
    commit(SET_STATE, { ready: true })
  },

  async [LOAD_BATCH_PRODUTOR] ({ commit }, { id }) {
    commit(SET_STATE, { dashboard: [] })
    if (id) {
      await api.cadastrarEntidade(id, 'dashboard/' + id + '/from/to').then(
        dashboard => {
          commit(SET_STATE, { dashboard })
        })
      }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
