import Vue from 'vue'
import Router from 'vue-router'
import admin from './admin'
import gestor from './gestor'
import supervisor from './supervisor'
import tecnico from './tecnico'
import produtor from './produtor'
import guest from './guest'
import controleacesso from './controleacesso'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    ...guest,
    ...controleacesso,
    admin,
    gestor,
    supervisor,
    tecnico,
    produtor,
    /* LOGIN */
    {
      name: 'login',
      path: '/login/:cadastro?',
      component: () => import('@/views/login/Login'),
      props: true,
      meta: {
        // requiresAuth: true,
        permissions: [
          {
            role: 'gestor',
            access: false,
            redirect: 'gestor',
          },
          {
            role: 'supervisor',
            access: false,
            redirect: 'supervisor',
          },
          {
            role: 'tecnico',
            access: false,
            redirect: 'tecnico',
          },
          {
            role: 'produtor',
            access: false,
            redirect: 'produtor',
          },
          {
            role: 'admin',
            access: false,
            redirect: 'admin',
          },
        ],
      },
    },
    /* LANDPAGE */
    {
      name: 'land',
      path: '/',
      meta: {
        permissions: [
          {
            role: 'guest',
            access: false,
            redirect: 'login',
          },
          {
            role: 'gestor',
            access: false,
            redirect: 'gestor',
          },
          {
            role: 'supervisor',
            access: false,
            redirect: 'supervisor',
          },
          {
            role: 'tecnico',
            access: false,
            redirect: 'tecnico',
          },
          {
            role: 'produtor',
            access: false,
            redirect: 'produtor',
          },
          {
            role: 'admin',
            access: false,
            redirect: 'admin',
          },
        ],
      },
    },
    /* REDIRECIONAR ROTAS NÃO EXISTENTES PARA PÁGINA INICIAL */
    {
      path: '*',
      redirect: '/',
    },
    {
      name: 'short',
      path: '/s/:shortURL',
      component: () => import('@/views/ShortURL/index'),
    },
  ],
})
