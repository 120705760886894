export const currencyFormatter = (number, maximo) => {
  if (maximo === undefined) {
    maximo = 2
  }
  return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: maximo, maximumFractionDigits: maximo }).format(String(number))
}

export const dateFormatter = (string) => {
  return string.substring(8, 10) + '/' + string.substring(5, 7) + '/' + string.substring(0, 4)
}

export const datetimeFormatter = (string) => {
  return string.substring(8, 10) + '/' + string.substring(5, 7) + '/' + string.substring(0, 4) + ' ' + string.substring(11, 13) + ':' + string.substring(14, 16) + ':' + string.substring(17, 19)
}

export const normalizeStr = (string) => {
  return String(string).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}

export const timestamp = (date) => {
  return (
    date.getUTCFullYear() +
    '-' +
    ('00' + (date.getUTCMonth() + 1)).slice(-2) +
    '-' +
    ('00' + date.getUTCDate()).slice(-2) +
    ' ' +
    ('00' + date.getUTCHours()).slice(-2) +
    ':' +
    ('00' + date.getUTCMinutes()).slice(-2) +
    ':' +
    ('00' + date.getUTCSeconds()).slice(-2)
  )
}
