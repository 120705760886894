import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_BATCH = 'LOAD_BATCH'
export const SET_STATE = 'SET_STATE'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    condenacao: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    commit(SET_STATE, { ready: false })
    await dispatch(LOAD_BATCH, { data_inicial, data_final })
    commit(SET_STATE, { ready: true })
  },
  async [LOAD_BATCH] ({ commit }, { data_inicial, data_final } = { data_inicial: 'from', data_final: 'to' }) {
    await api.listEntidade('volume-condenado/' + data_inicial + '/' + data_final).then(
      condenacao => {
        commit(SET_STATE, { condenacao })
      },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
