import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const LOAD_BATCH = 'LOAD_BATCH'
export const CLOSE = 'CLOSE'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ready: false,
    loading: true,
    logs: [],
    dialog: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CLOSE] (state) {
    state.dialog = false
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }, { id }) {
    commit(SET_STATE, { ready: false, loading: true, dialog: true })
    await dispatch(LOAD_BATCH, { id })
    commit(SET_STATE, { ready: true, loading: false })
  },
  async [LOAD_BATCH] ({ dispatch, commit }, { id }) {
    await api.getEntidade('cotacao', id).then(response => {
        commit(SET_STATE, {
          logs: response.data.logs,
          numero_cotacao: response.data.cotacao_id,
          nome_produtor: response.data.nome_produtor,
          nome_fazenda: response.data.nome_fazenda,
        },
      )
    },
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
