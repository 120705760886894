import XLSX from 'xlsx'
import { v4 } from 'uuid'
import { getProperty, getSubProperty } from './objectUtils'
import moment from 'moment'

export class Worksheet {
  constructor (data, template) {
    let workbook = new Uint8Array(data)
    workbook = XLSX.read(workbook, { type: 'array' })

    const worksheet = workbook.Sheets[workbook.SheetNames[0]]

    this.raw_data = XLSX.utils.sheet_to_json(worksheet, { raw: false }).map(
      row => Object.fromEntries(
        Object.entries(row).map(([key, value]) => ([key, value])),
      ),
    )

    if (template) {
      this.data = this.raw_data.map(row => Object.fromEntries(
        template.listFields.map(field => [field.value, { value: row[field.map], err: false }]),
      ))

      this.data.forEach(row => Object.assign(row, { id: v4() }))
    }
    this.header = this._parseHeader(worksheet)
  }

  _parseHeader (data) {
    const nColumns = XLSX.utils.decode_range(data['!ref']).e.c + 1
    const header = []
    for (let i = 0; i < nColumns; i++) {
      try {
        header.push(data[`${XLSX.utils.encode_col(i)}1`].v)
      } catch (_err) {
      }
    }
    return header
  }

  get columns () {
    return this.header
  }
}

export class JsonToExcel {
/*
  TODO: styleXLSX{
    type: 'string'|'number'|'date'|'telefone',
  }

*/
  constructor (data, headers, name, filename = 'Relatório.xlsx', sheetname = 'Relatório') {
    try {
      function mesAnoFormatter (value) {
        return moment(value).format('MM/YYYY')
      }
      var table = []
      var cols = []
      var title = []
      if (name) {
        title.push(name)
        table.push(title)
      }
      headers.forEach(header => {
        if (header.report) {
          cols.push(header.text)
        }
      })
      table.push(cols)

      data.forEach(item => {
        const row = []
        headers.forEach(header => {
          if (header.report) {
            let hv = getProperty(item, header.value)
            if (header.styleXLSX && hv !== null && hv !== undefined) {
              if (header.styleXLSX.type === 'number') {
                if (hv.toString().includes(',')) {
                  hv = (hv.toString().replace('.', '')).replace(',', '.')
                }
                hv = parseFloat(hv)
              }
              if (header.styleXLSX.type === 'mesAno') {
                hv = { v: mesAnoFormatter(hv), t: 's' }
              }
              if (header.styleXLSX.type === 'date') {
                hv = moment(hv).format('YYYY-MM-DD')
                hv = { v: hv, t: 'd' }
              }
              if (header.styleXLSX.type === 'telefone') {
                let valTmp = ''
                for (let i = 0; i < hv.length; i++) {
                  if (hv[i]) {
                    valTmp += hv[i] + ' '
                  }
                }
                hv = valTmp
              }
              if (header.styleXLSX.type === 'alterValue') {
                hv = header.alterValue[hv]
              }
              if (header.styleXLSX.type === 'subProperty') {
                hv = getSubProperty(item, header.subProperty)
              }
            }
            row.push(hv)
          }
        })
        table.push(row)
      })

      var ws = XLSX.utils.aoa_to_sheet(table)
      const colWidths = table[1].map((_, colIndex) => table.reduce((maxWidth, currentRow) => {
        const cellValue = currentRow[colIndex]
        const cellWidth = (typeof cellValue === 'string' ? cellValue.length : 0)
        return Math.max(maxWidth, cellWidth)
      }, 0))

      ws['!cols'] = colWidths.map(width => ({ wch: width + 2 }))
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, sheetname)

      XLSX.writeFile(wb, filename)
    } catch (e) {
      console.error(e)
    }
  }
}
