import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const RESET_STATE = 'RESET_STATE'
export const INATIVAR_REGIONAL = 'INATIVAR_REGIONAL'

const getDefaultState = () => {
  return {
    regionais: null,
    loading: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('regional').then(
      regionais => {
        commit(SET_STATE, { regionais })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [INATIVAR_REGIONAL] ({ dispatch, commit, state }, regional) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir essa Regional?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('regional', regional.id).then(
          result => result,
          (error) => {
            Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: error.message
                ? error.message
                : 'Não foi possivel excluir a Regional!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'A Regional foi excluída com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
