const router = function (permissions) {
  return ({
    path: 'cdc/catalogos',
    component: () => import('@/views/Catalogo/Catalogo.vue'),
    meta: { permissions, title: 'Manutenção de Catálogos' },
  })
}

export default {
  router,
}
