<template>
  <v-snackbar
    v-model="snackbar"
    v-bind="$attrs"
    top
    right
    shaped
    elevation="24"
    color="error"
    transition="slide-x-reverse-transition"
  >
    <v-row
      justify="space-between"
      align="center"
    >
      <div class="d-flex align-center">
        <v-icon class="mx-2">
          mdi-alert-circle-outline
        </v-icon>
        <span style="font-size: 14px; font-weight: 500">{{ message }}</span>
      </div>
      <v-btn
        small
        icon
        dark
        text
        @click="$emit('snackbar',false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
  </v-snackbar>
</template>
<script>
  export default {
    model: { prop: 'value', event: 'snackbar' },
    props: {
      message: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      snackbar: {
        set (value) {
          this.$emit('snackbar', value)
        },
        get () {
          return this.value
        },
      },
    },
  }
</script>
