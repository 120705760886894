const router = function (permissions) {
  return ({
    path: 'ccc/notificacoesTecnico',
    component: () => import('@/views/Notificacao/NotificacaoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Notificacao/NotificacaoList.vue'),
        meta: { permissions, title: 'Mensagens enviadas ao Técnico' },
        props: (route) => ({ tipo: route.query.tipo, id: route.query.id }),
      },
    ],
  })
}

export default {
  router,
}
