import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import Swal from 'sweetalert2'
import { getLocation } from '../../utils/objectUtils'
import { timestamp } from '../../utils/formatter'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const INATIVAR_FORNECEDOR = 'INATIVAR_FORNECEDOR'
export const RESET_STATE = 'RESET_STATE'
export const NOTIFICA = 'NOTIFICA'
export const NOTIFICA_TODOS = 'NOTIFICA_TODOS'
export const LOAD_UNIDADES_NEGOCIO = 'LOAD_UNIDADES_NEGOCIO'
export const OPEN = 'OPEN'
export const CLOSE = 'CLOSE'
export const LOAD_PRODUTORES = 'LOAD_PRODUTORES'
export const LOAD_TECNICOS = 'LOAD_TECNICOS'
export const SUBMIT = 'SUBMIT'
export const REENVIAR_NOTIFICACAO = 'REENVIAR_NOTIFICACAO'
export const MENSAGENS_NAO_LIDAS = 'MENSAGENS_NAO_LIDAS'
export const LEITURA_NOTIFICACAO = 'LEITURA_NOTIFICACAO'
export const LIMPAR_NOTIFICACOES = 'LIMPAR_NOTIFICACOES'

const getDefaultState = () => {
  return {
    nao_lidas: null,
    entidade_produtor: [],
    entidade_tecnico: [],
    notificaProdutor: null,
    notificaTecnico: null,
    loading: false,
    dialog: false,
    unidades: [],
    detalhes: null,
    data_noti: {
      assunto: '',
      mensagem: '',
      confirma: false,
      id: [],
      tipo: null,
      nome: null,
    },
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [CLOSE] (state) {
    state.dialog = false
  },
}

const actions = {
  async [LEITURA_NOTIFICACAO] ({ commit, rootState }, { notificacao }) {
    if (notificacao.situacao !== 1 && (rootState.user.roles[0].slug === 'produtor' || rootState.user.roles[0].slug === 'tecnico')) {
      try {
        commit(SET_STATE, { detalhes: null })
        const location = await getLocation()
        await api.cadastrarEntidade({
          notificacao_id: notificacao.data.notificacao_id,
          latitude: location.latitude,
          longitude: location.longitude,
          data: timestamp(new Date()),
        }, 'notificacao/set_notification_info')
        commit(SET_STATE, { detalhes: notificacao })
        notificacao.situacao = 1
      } catch (err) {
        console.error(err)
      }
    } else {
      commit(SET_STATE, { detalhes: notificacao })
    }
  },
  async [MENSAGENS_NAO_LIDAS] ({ commit }, { id }) {
    const mensagens = await api.listEntidade(`notificacao/${id}`)
    commit(SET_STATE, { nao_lidas: mensagens.filter((mensagem) => mensagem.situacao === 0).length })
  },
  async [LOAD_BATCH] ({ commit, state }, { data_inicial, data_final, tipo_pessoa, id } = { data_inicial: 'from', data_final: 'to', tipo_pessoa: 'P', id: null }) {
    if (id) {
      const notificacoes = await api.listEntidade(`notificacao/${data_inicial}/${data_final}/${tipo_pessoa}/${id}`)
      if (tipo_pessoa === 'P') {
        await commit(SET_STATE, { notificaProdutor: notificacoes })
      } else {
        await commit(SET_STATE, { notificaTecnico: notificacoes })
      }
    } else {
      const notificacoes = await api.listEntidade(`notificacao/${data_inicial}/${data_final}/${tipo_pessoa}`)
      if (tipo_pessoa === 'P') {
        await commit(SET_STATE, { notificaProdutor: notificacoes })
      } else {
        await commit(SET_STATE, { notificaTecnico: notificacoes })
      }
    }
  },
  [LOAD_PRODUTORES] ({ commit }) {
    api.listEntidade('produtor').then(
      entidade_produtor => {
        commit(SET_STATE, { entidade_produtor })
      })
  },
  [LOAD_TECNICOS] ({ commit }) {
    api.listEntidade('tecnico').then(
      entidade_tecnico => {
        commit(SET_STATE, { entidade_tecnico })
      })
  },
  async [LOAD_UNIDADES_NEGOCIO] ({ commit, rootState }) {
    commit(SET_STATE, { unidades: await api.listEntidade(`${rootState.user.roles[0].slug}/unidadesNegocio`) })
  },
  async [NOTIFICA_TODOS] ({ dispatch, state }) {
    await dispatch(LOAD_UNIDADES_NEGOCIO)
    const unidades = state.unidades.map(unidade => unidade.id)
    const dados = { id: unidades, tipo: 8, nome: 'todos os Técnicos', confirma: true }
    dispatch('notificacao/BOOTSTRAP', dados, { root: true })
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }, { data_inicial, data_final, tipo_pessoa, id } = { data_inicial: 'from', data_final: 'to', tipo_pessoa: 'P', id: null }) {
    dispatch(LIMPAR_NOTIFICACOES)
    commit(SET_STATE, { loading: true })
    commit(SET_STATE, { detalhes: null })
    if (!id) {
      if (tipo_pessoa === 'P') {
        await dispatch(LOAD_PRODUTORES)
      } else {
        await dispatch(LOAD_TECNICOS)
      }
    }
    await dispatch(LOAD_BATCH, { data_inicial, data_final, tipo_pessoa, id })
    commit(SET_STATE, { loading: false })
  },
  async [OPEN] ({ dispatch, commit }) {
    commit(SET_STATE, { dialog: true })
  },
  async [NOTIFICA] ({ dispatch, commit }) {
    await dispatch(LOAD_UNIDADES_NEGOCIO)
    const unidades = state.unidades.map(unidade => unidade.id)
    const dados = { from: unidades, tipo: state.data_noti.tipo, title: state.data_noti.assunto, mensagem: state.data_noti.mensagem }
    Swal.fire({
      title: 'Enviando mensagem!',
    })
    Swal.showLoading()
    api.cadastrarEntidade(dados, 'notificacao/save_notificacao').then(() => {
      commit('CLOSE')
      Swal.hideLoading()
      Swal.update({
        icon: 'success',
        title: 'Mensagem enviada com sucesso!',
      })
    }).catch(err => {
      console.error(err)
      Swal.hideLoading()
      Swal.update({
        icon: 'error',
        title: 'Não foi possível enviar a Mensagem, favor tentar novamente!',
      })
    })
    dispatch(LIMPAR_NOTIFICACOES)
  },
  async [SUBMIT] ({ commit, state, dispatch }) {
    let retornErro
    const errors = []
    Swal.fire({
      title: 'Enviando Mensagem!',
    })
    Swal.showLoading()

    for (const pessoa of state.data_noti.id) {
      const dados = { from: pessoa.id, tipo: state.data_noti.tipo, title: state.data_noti.assunto, mensagem: state.data_noti.mensagem }
      try {
        retornErro = await api.cadastrarEntidade(dados, 'notificacao/save_notificacao')
      } catch (err) {
        retornErro = await api.cadastrarEntidade(dados, 'notificacao/save_notificacao')
        console.error(retornErro)
        errors.push(pessoa)
      }
    }
    if (errors.length > 0) {
      await dispatch(REENVIAR_NOTIFICACAO, { ids: errors })
    } else {
      commit('CLOSE')
      Swal.hideLoading()
      Swal.update({
        icon: 'success',
        title: 'Mensagem enviada com sucesso!',
      })
    }
    dispatch(LIMPAR_NOTIFICACOES)
  },
  async [REENVIAR_NOTIFICACAO] ({ commit, state, dispatch }, { ids } = { ids: null }) {
    const errors = []
    for (const pessoa of ids) {
      const dados = { from: pessoa.id, tipo: state.data_noti.tipo, title: state.data_noti.assunto, mensagem: state.data_noti.mensagem }
      try {
        await api.cadastrarEntidade(dados, 'notificacao/save_notificacao')
      } catch (err) {
        console.error(err)
        errors.push(pessoa)
      }
    }
    if (errors.length === ids.length) {
      Swal.hideLoading()
      Swal.update({
        icon: 'error',
        title: 'Não foi possível enviar a mensagem para nenhum dos destinatários!',
      })
    } else if (errors.length > 0) {
      const erro = errors.map(desc => desc.nome).join(', ')
      Swal.hideLoading()
      Swal.update({
        icon: 'error',
        title: 'Não foi possível enviar a mensagens para: ' + erro,
      })
    } else {
      commit('CLOSE')
      Swal.hideLoading()
      Swal.update({
        icon: 'success',
        title: 'Mensagem enviada com sucesso!',
      })
    }
  },
  async [LIMPAR_NOTIFICACOES] ({ commit }) {
    commit(SET_STATE, {
      data_noti: {
        assunto: '',
        mensagem: '',
        confirma: false,
        id: [],
        tipo: null,
        nome: null,
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
