export const RegisterStatus = {
  AGUARDANDO_CADASTRO: 0,
  AGUARDANDO_ANALISE: 1,
  APROVADO: 2,
  REPROVADO: 3,
}

export const RegisterStatusColor = {
  [RegisterStatus.AGUARDANDO_CADASTRO]: 'gray',
  [RegisterStatus.AGUARDANDO_ANALISE]: 'blue',
  [RegisterStatus.APROVADO]: 'green',
  [RegisterStatus.REPROVADO]: 'red',
}

export const RegisterStatusText = {
  [RegisterStatus.AGUARDANDO_CADASTRO]: 'Aguardando Análise',
  [RegisterStatus.AGUARDANDO_ANALISE]: 'Aguardando Aprovação',
  [RegisterStatus.APROVADO]: 'Aprovado',
  [RegisterStatus.REPROVADO]: 'Bloqueado',
}

export const RegisterStatusTextReverse = {
  [RegisterStatusText[RegisterStatus.AGUARDANDO_CADASTRO]]: RegisterStatus.AGUARDANDO_CADASTRO,
  [RegisterStatusText[RegisterStatus.AGUARDANDO_ANALISE]]: RegisterStatus.AGUARDANDO_ANALISE,
  [RegisterStatusText[RegisterStatus.APROVADO]]: RegisterStatus.APROVADO,
  [RegisterStatusText[RegisterStatus.REPROVADO]]: RegisterStatus.REPROVADO,
}
