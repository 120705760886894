import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_SUPERVISORES = 'LOAD_SUPERVISORES'
export const DELETE_SUPERVISOR = 'DELETE_SUPERVISOR'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    supervisores: [],
    ready: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_SUPERVISORES] ({ commit, state }) {
    commit(SET_STATE, { ready: false })
    await api.listEntidade('supervisor').then(
      supervisores => {
        commit(SET_STATE, { supervisores, ready: true })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    await dispatch(LOAD_SUPERVISORES)
  },
  async [DELETE_SUPERVISOR] ({ dispatch, commit, state }, supervisor) {
    const index = state.supervisores.findIndex(c => c.id === supervisor.id)

    Swal.fire({
      title: 'Tem certeza que deseja excluir o Supervisor?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('supervisor', supervisor.id).then(
          result => result,
          error => {
            Swal.fire({
              icon: 'error',
              title: 'Atenção!',
              text: error.message
                ? error.message
                : 'Não foi possivel excluir o Supervisor!',
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'O Supervisor foi excluído com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        state.supervisores.splice(index, 1)
        api.listEntidade('supervisor').then(result => {
          state.supervisores = result
        })
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
