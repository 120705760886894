import { v4 } from 'uuid'
import { Regra } from './Regra'
export class Oferta {
  constructor ({
    id,
    status,
    nome,
    descricao,
    unidades,
    tipo,
    data_inicio,
    data_fim,
    entrega,
    regras,
    imagem,
  } = {
    id: v4(),
    status: 0,
    nome: '',
    descricao: '',
    unidades: [],
    tipo: 'campanha',
    data_inicio: '',
    data_fim: '',
    entrega: null,
    regras: [new Regra()],
    imagem: null,
  }) {
    this.id = id
    this.status = status
    this.nome = nome
    this.descricao = descricao
    this.unidades = unidades
    this.tipo = tipo
    this.data_inicio = data_inicio
    this.data_fim = data_fim
    this.entrega = entrega
    this.regras = regras.map(({ produtos, ...rest }) => new Regra(({
      produtos: produtos.map(produto => produto),
      ...rest,
    })))
    this.imagem = imagem
  }

  addRegra () {
    this.regras.push(new Regra())
  }

  clearRegras () {
    this.regras = [new Regra()]
  }

  removeRegra (regra) {
    let index = -1
    for (let i = 0; i < this.regras.length; i++) {
      if (this.regras[i].id === regra.id) {
        index = i
        break
      }
    }
    return this.regras.splice(index, 1)
  }
}

export function createOferta (data) {
  return Object.freeze(new Oferta(data))
}
