import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'

export const LOAD_BATCH = 'LOAD_BATCH'
export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP = 'BOOTSTRAP'
export const OPEN = 'OPEN'
export const CLOSE = 'CLOSE'
export const RESET_STATE = 'RESET_STATE'
export const DOWLOAD = 'DOWLOAD'

const getDefaultState = () => {
  return {
    loading: true,
    logs: [],
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [OPEN] (state) {
    state.dialog = true
  },
  [CLOSE] (state) {
    state.dialog = false
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  [BOOTSTRAP] ({ dispatch, commit }, { id }) {
    dispatch(LOAD_BATCH, { id })
  },
  [LOAD_BATCH] ({ commit }, { id }) {
    commit(SET_STATE, { loading: true })
    api.listEntidade(`integracaologs/${id}`).then(
      logs => {
        commit(SET_STATE, { logs, loading: false })
      })
  },
  [DOWLOAD] ({ commit }, { id, name }) {
    api.downloadFile(id).then(
      response => {
        const binaryString = window.atob(response.data.split(',')[1]) // Decodifica a string base64
        const len = binaryString.length
        const bytes = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }

        const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
