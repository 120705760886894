import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const DELETE_TECNICO = 'DELETE_TECNICO'
export const LOAD_UNIDADES_NEGOCIO_GESTOR = 'LOAD_UNIDADES_NEGOCIO_GESTOR'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    unidades: null,
    tecnicos: null,
    loading: true,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_UNIDADES_NEGOCIO_GESTOR] ({ commit }) {
    commit(SET_STATE, { unidades: await api.listEntidade('gestor/unidadesNegocio') })
  },
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('tecnico').then(tecnicos => {
      commit(SET_STATE, { tecnicos })
    })
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { loading: false })
  },
  async [DELETE_TECNICO] ({ dispatch, commit, state }, tecnico) {
    Swal.fire({
      title: 'Exclusão',
      text: 'Tem certeza que deseja excluir o tecnico?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('tecnico', tecnico.id).then(
          result => result,
          () => {
            window.Toast.fire('Não foi possivel excluir o Técnico!', '', 'error')
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        window.Toast.fire('O Técnico foi excluído com sucesso!', '', 'success')
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
