import api from '@/api/api'
import { getField, updateField } from 'vuex-map-fields'
import Swal from 'sweetalert2'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const NEW_OFERTA = 'NEW_OFERTA'
export const EDIT_OFERTA = 'EDIT_OFERTA'
export const DELETE_OFERTA = 'DELETE_OFERTA'
export const TOGGLE_OFERTA_STATUS = 'TOGGLE_OFERTA_STATUS'
export const LOAD_OFERTAS = 'LOAD_OFERTAS'

export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    ofertas: null,
    loading: true,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [CHANGE_STATUS] (state, { oferta }) {
    const index = state.ofertas.findIndex(v => v.id === oferta.id)
    state.ofertas[index].status = 0
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit('SET_STATE', { loading: true })
    await dispatch(LOAD_OFERTAS)
    commit('SET_STATE', { loading: false })
  },
  async [LOAD_OFERTAS] ({ commit, state }) {
    await api.listEntidade('oferta').then(
      retorno => {
        const ofertas = retorno.map(({ ...rest }) => {
          const unidades_negocio = rest.unidades.map(e => e.nome_fantasia).join('\n ')
          return { ...rest, unidades_negocio }
        })
        commit(SET_STATE, { ofertas })
      },
    )
  },
  [NEW_OFERTA] ({ dispatch }) {
    dispatch('form/oferta/BOOTSTRAP_PROFILE', { id: null }, { root: true })
  },
  [EDIT_OFERTA] ({ dispatch }, oferta) {
    dispatch('form/oferta/BOOTSTRAP_PROFILE', { id: oferta.id }, { root: true })
  },
  async [DELETE_OFERTA] ({ dispatch, commit, state }, oferta) {
    const index = state.ofertas.findIndex((v) => v.id === oferta.id)
    Swal.fire({
      title: 'Excluir',
      text: `Tem certeza que excluir a ${oferta.tipo} "${oferta.nome}"?`,
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('oferta', oferta.id).then(
          result => result,
          () => {
            window.Toast.fire('Ocorreu algum erro na exclusão da Promoção!', '', 'error')
          },
        )
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.Toast.fire(`A ${oferta.tipo} foi excluída com sucesso!`, '', 'success')
        state.ofertas.splice(index, 1)
      }
    })
  },
  async [TOGGLE_OFERTA_STATUS] ({ dispatch, state }, oferta) {
    const getDataValue = (date) => {
      const newDate = new Date()
      if (date) {
        const dateArray = date.substring(0, 10).split('-')
        newDate.setFullYear(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]))
      }
      newDate.setHours(0, 0, 0, 0)
      return newDate.valueOf()
    }
    const envio_request = {}
    const atualizaStatus = () => {
      Swal.fire({
        title: 'Atualizando o status da Promoção!',
      })
      Swal.showLoading()
      api
        .salvarEntidade(envio_request, 'oferta/status', oferta.id)
        .then((response) => {
          Swal.hideLoading()
          Swal.update({
            icon: 'success',
            title: `A Promoção foi ${oferta.status ? 'resumida' : 'interrompida'
              } com sucesso!`,
          })
        })
        .catch((err) => {
          if (err.code && err.code === 400) {
            Swal.hideLoading()
            oferta.status = !oferta.status
            return Swal.update({
              icon: 'error',
              title: err.error,
            })
          }
          console.error(err)
          Swal.hideLoading()
          Swal.update({
            icon: 'error',
            title: `Não foi possível ${oferta.status ? 'resumir' : 'interromper'
              } a Promoção!`,
          })
        })
    }
    if (oferta.status === true) {
      const dataAtual = getDataValue()
      const ofertas_ativas = state.ofertas.filter(of => of.id !== oferta.id && (dataAtual <= getDataValue(of.data_fim) && of.status === (1 || true)))
      const produtos_em_ofertas = ofertas_ativas.map(oferta => {
        return { unidades: oferta.unidades.map(unidade => unidade.id), produtos: oferta.regras.map(regra => regra.produtos.map(produto => (typeof produto === 'object') ? produto.id : produto)).flat() }
      })

      const produtosValidos = (produto) => {
        const unidades = oferta.unidades.map(und => und.id)
        const retorno = produtos_em_ofertas.filter((elemento) => {
          const retornoUnidade = elemento.unidades.filter(und => unidades.includes(und))
          const retornoProduto = elemento.produtos.includes(produto)
          return (retornoUnidade.length > 0 && retornoProduto)
        })
        return (retorno.length <= 0)
      }

      if (oferta.regras.some(regra => regra.produtos.some(produto => (!produtosValidos((typeof produto === 'object') ? produto.id : produto))))) {
        oferta.status = !oferta.status
        await Swal.fire({
          title: 'Atenção',
          html: 'Essa promoção possui produtos que estão em outras <b>Promoções Ativas</b>!<br>Portanto acesse a listagem de produtos e faça as correções necessárias para poder ativá-la.',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonColor: '#109010',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Acessar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true,
          focusConfirm: false,
          preConfirm: () => {
            dispatch('form/oferta/BOOTSTRAP_PROFILE', { id: oferta.id }, { root: true })
          },
        })
      } else {
        atualizaStatus()
      }
    } else {
      atualizaStatus()
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
