const router = function (permissions) {
  return ({
    path: 'cdc/produtos',
    component: () => import('@/views/Produto/ProdutoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        meta: { permissions, title: 'Manutenção de Produtos' },
        component: () => import('@/views/Produto/ProdutoList.vue'),
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Produto/ProdutoProfile.vue'),
        meta: { permissions, title: 'Cadastrando Produto' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Produto/ProdutoProfile.vue'),
        meta: { permissions, title: 'Editando Produto' },
      },
    ],
  })
}

export default {
  router,
}
