const router = function (permissions) {
    return ({
      path: 'cdv/cotacoes',
      component: () => import('@/views/Cotacao/CotacaoModule.vue'),
      meta: { permissions, title: 'Manutenção das Cotações' },
      props: (route) => ({ status: route.query.status }),
      children: [
        {
          path: '/',
          meta: { permissions, title: 'Manutenção de Cotações' },
          component: () => import('@/views/Cotacao/CotacaoListProdutor.vue'),
        },
        {
          path: 'cotacoes',
          component: () => import('@/views/Cotacao/CotacaoDetalhes.vue'),
          meta: { permissions, title: 'Cotação' },
        },
      ],
    })
  }
  export default {
    router,
  }
