<template>
  <div>
    <template>
      <div
        style="cursor: pointer"
        @click="chamaRotas"
      >
        <v-badge
          :content="nao_lidas"
          :value="nao_lidas === 0 ? false : notOpened"
          color="success"
          overlap
          class="pa-1 ma-2"
          small
        >
          <v-icon
            small
            color="white"
            title="Acessar as mensagens do usuário!"
          >
            mdi-bell
          </v-icon>
        </v-badge>
      </div>
    </template>
  </div>
</template>

  <script>
  import { MENSAGENS_NAO_LIDAS } from '@/store/modules/notificacoes'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'NotificationPessoa',
    data () {
      return {
        show: false,
        notOpened: true,
      }
    },
    computed: {
      ...mapState('notificacoes', ['nao_lidas']),
    },
    created () {
      this.MENSAGENS_NAO_LIDAS({ tipo_pessoa: this.tipo, id: this.$store.state.user.id })
    },
    methods: {
      ...mapActions('notificacoes', [MENSAGENS_NAO_LIDAS]),
      chamaRotas () {
        if (this.$store.state.user.roles[0].slug === 'produtor') {
          this.$router.push({ path: '/produtor/dashboard' }).catch(() => {})
          this.$router.push({ path: 'ccc/notificacoesProdutor/?tipo=P' }).catch(() => {})
        } else {
          this.$router.push({ path: '/tecnico/dashboard' }).catch(() => {})
          this.$router.push({ path: 'ccc/notificacoesTecnico/?tipo=T' }).catch(() => {})
        }
      },
    },
  }
  </script>

  <style scoped>
  .normal {
    background: #ffffff;
    border-color: #9c9c9c;
  }
  .active {
    background: #f1f1ff;
  }

  .v-menu__content {
    border-radius: 20px;
    border: 7px solid;
    border-color:#1976d2;
  }
  </style>
