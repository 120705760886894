import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import carrinho from './modules/carrinho'
import catalogos from './modules/catalogos'
import categorias from './modules/categorias'
import cotacaologs from './modules/cotacaologs'
import cotacoes from './modules/cotacoes'
import dashboard from './modules/dashboard'
import descontosCategoria from './modules/descontosCategoria'
import fazendas from './modules/fazendas'
import form from './modules/form'
import fornecedores from './modules/fornecedores'
import gestores from './modules/gestores'
import supervisores from './modules/supervisores'
import loja from './modules/loja'
import ofertas from './modules/ofertas'
import pedidologs from './modules/pedidologs'
import pedidotransfere from './modules/pedidotransfere'
import pedidos from './modules/pedidos'
import produtoIndividual from './modules/produtoIndividual'
import produtores from './modules/produtores'
import produtos from './modules/produtos'
import tecnicos from './modules/tecnicos'
import unidades from './modules/unidades'
import unidadesNegocio from './modules/unidadesNegocio'
import administradores from './modules/administradores'
import cadastros from './modules/cadastros'
import qualidades from './modules/qualidades'
import regionais from './modules/regionais'
import relatorio from './modules/relatorio'
import sempermissao from './modules/semPermissao'
import user from './modules/user'
import userProfile from './modules/userProfile'
import volumes from './modules/volumes'
import mutations from './mutations'
import state from './states'
import notificacoes from './modules/notificacoes'
import integracoes from './modules/integracoes'
import condenacao from './modules/condenacao'
import logs from './modules/logs'
import vouchers from './modules/vouchers'

Vue.use(Vuex)

export default new Vuex.Store({
  // Making sure that we're doing
  // everything correctly by enabling
  // strict mode in the dev environment.
  namespaced: true,
  // O strict serve para mapear toda modificação feita
  // no estado para fins de debug
  // Ele foi desabilitado devido à necessidade de modificar
  // uma propriedade nested nas regras das ofertas.
  // strict: process.env.NODE_ENV !== 'production',
  state,
  actions,
  mutations,
  getters,
  modules: {
    ofertas,
    catalogos,
    descontosCategoria,
    gestores,
    supervisores,
    unidadesNegocio,
    form,
    categorias,
    fazendas,
    produtos,
    tecnicos,
    fornecedores,
    unidades,
    produtores,
    pedidos,
    pedidologs,
    pedidotransfere,
    cotacoes,
    cotacaologs,
    loja,
    carrinho,
    produtoIndividual,
    dashboard,
    administradores,
    cadastros,
    qualidades,
    regionais,
    relatorio,
    sempermissao,
    user,
    userProfile,
    volumes,
    notificacoes,
    integracoes,
    condenacao,
    logs,
    vouchers,
  },
})
