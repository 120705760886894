<template>
  <div>
    <v-autocomplete
      v-model="selecaoFazenda"
      :items="fazendasAtivas"
      :disabled="((!fazendas) || fazendas.length === 0)"
      :loading="((!fazendas) || fazendas.length === 0)"
      item-text="razao_social"
      item-value="id"
      return-object
      label="Selecione a Fazenda"
      clearable
      dense
      solo
      flat
      autocomplete="off"
      prepend-inner-icon="mdi-cow"
      class="rounded-lg mx-n2 pt-6 ma-2 caption font-weight-bold"
      @change="selecao"
      @click:clear="selecao"
    >
      <template
        slot="item"
        slot-scope="data"
      >
        <v-row
          dense
          justify="space-between"
          class="rounded-lg mx-n2 hei"
          style="background-color: white;"
        >
          <v-list-item-content>
            <v-list-item-title
              class="caption font-weight-bold"
            >
              {{ data.item.nome_fantasia ? data.item.nome_fantasia : data.item.razao_social }}
            </v-list-item-title>
          </v-list-item-content>
        </v-row>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
  import FazendaStore, { LOAD_FAZENDAS, SET_FAZENDA_SELECIONADA } from '@/store/modules/fazendas'
  import { RESET_STATE, SET_STATE } from '@/store/modules/carrinho'
  import { mapState, mapActions, mapMutations } from 'vuex'
  import { mapFields } from 'vuex-map-fields'

  export default {
    name: 'SelecaoFazenda',
    data () {
      return { oldFaz: null }
    },
    computed: {
      ...mapState('fazendas', ['fazendas']),
      ...mapFields('fazendas', ['selecaoFazenda']),
      fazendasAtivas () {
        return this.fazendas !== null ? this.fazendas.filter(el => el.produtores.find(prod => prod.id === this.$store.state.user.pessoa_id) && el.produtores.find(prod => prod.id === this.$store.state.user.pessoa_id).cadastro.ativo === 1) : this.fazendas
      },
    },
    async created () {
      if (!this.$store.state.fazendas) { this.$store.registerModule('fazendas', FazendaStore) }
      await this.LOAD_FAZENDAS()
      this.seta_Fazenda_Inicial(this.$store.state.fazendas.fazendas.length, this.$store.state.fazendas.fazendas)
    },
    methods: {
      ...mapActions('fazendas', [LOAD_FAZENDAS, SET_FAZENDA_SELECIONADA]),
      ...mapMutations('carrinho', [RESET_STATE, SET_STATE]),
      selecao (item) {
        if (this.fazendasSelecionada !== null && this.$store.state.carrinho.produtos.length > 0) {
          this.RESET_STATE()
          if (item !== undefined) {
            this.fazendaSelecionada = item
            this.SET_FAZENDA_SELECIONADA(item)
            localStorage.setItem('Fazenda-Selecionada', JSON.stringify(item))
            const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + item.id)))
            if (produtos !== null) {
              this.SET_STATE({ produtos })
            }
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
            localStorage.removeItem('Fazenda-Selecionada')
            this.oldFaz = null
          }
          if (this.$route.path.includes('loja')) {
            this.$router.push({ path: `/${this.$user.get().role}/loja` })
          }
          return true
        } else {
          if (item !== undefined) {
            this.oldFaz = item
            this.SET_FAZENDA_SELECIONADA(item)
            localStorage.setItem('Fazenda-Selecionada', JSON.stringify(item))
            const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + item.id)))
            if (produtos !== null) {
              this.SET_STATE({ produtos })
            }
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
            this.oldFaz = null
            localStorage.removeItem('Fazenda-Selecionada')
          }
          return true
        }
      },
      seta_Fazenda_Inicial (tamanho, fazendas) {
        if (tamanho === 1) {
          this.selecao(fazendas[0])
        } else {
          const fazendaLocal = localStorage.getItem('Fazenda-Selecionada')
          if ((fazendaLocal) && fazendaLocal !== undefined) {
            this.selecao(JSON.parse(fazendaLocal))
          } else {
            this.$store.commit('fazendas/SET_STATE', { selecaoFazenda: null })
          }
        }
      },
    },
  }
</script>
