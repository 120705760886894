import {
  validarCNPJ,
  validarCPF,
  validarData,
  validarDataMes,
  isNotFuture as iNF,
  data_Minima,
  data_Maxima,
  validarVolume,
  validarDesconto,
} from './validacoes'
import {
  numeric as isNumeric,
  decimal as isDecimal,
} from 'vuelidate/lib/validators'

const _ = v => (v === undefined || v === null) ? null : false
const required = v => !!v || '* Obrigatório'
const minLength = (min, msg) => v =>
  !v || v.length >= min || msg || '* Inválido'
const maxLength = (max, msg) => v =>
  !v || v.length <= max || msg || '* Inválido'
const email = v =>
  !v ||
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/.test(v) ||
  'E-mail inválido'
const cpf = v => validarCPF(v) || '* Inválido'
const cnpj = v => !v || validarCNPJ(v) || '* Inválido'
const validDate = v => !v || validarData(v) || '*Data Inválida'
const validDateMes = v => !v || validarDataMes(v) || '*Mês e Ano Inválido'
const isNotFuture = v => !v || !iNF(v) || '* A data não deve ser no futuro'
const match = (field, msg) => v =>
  !field || v === field || msg || 'Os campos são diferentes'
const numeric = v => !v || isNumeric(v) || 'Valor deve ser um numérico'
const decimal = v => isDecimal(v) || 'Valor deve ser um número decimal'
const minDate = (min) => v =>
  !v || data_Minima(v, min) || `* A data deve ser maior ou igual a ${min}`
const maxDate = (max) => v =>
  !v || data_Maxima(v, max) || `* A data deve ser menor ou igual a ${max}`
const geq = (min, msg) => v =>
  !v || v >= min || msg || `Valor deve ser no mínimo ${min}`
const gep = (max, msg) => v =>
  !v || v <= max || msg || `Valor deve ser no máximo ${max}`
const gt = (min, msg) => v => _(v) || v > min || msg || `Valor deve ser maior que ${min}`
const requiredIf = (field, msg) => v => !field || !!v || msg || '* Obrigatório'
const distinct = (data, msg) => v =>
  !v ||
  data.filter(d => v === d).length < 2 ||
  msg ||
  'O valor deve ser distinto'
const money = v => !v || (parseFloat(v.replaceAll('.', '').replace(',', '.')))
const moneyGt = (min) => v => _(v) || (parseFloat(v.replaceAll('.', '').replace(',', '.')) > parseFloat(min)) || `Valor deve ser maior que ${min}`
const cpf_cnpj = v => validarCPF(v) || validarCNPJ(v) || '* Invalido'
const volumes = (vol, regra) => validarVolume(vol, regra) || '* Valor já informado!'
const descontos = (desc, regra) => validarDesconto(desc, regra) || '* Valor já informado!'

export default {
  required,
  minLength,
  maxLength,
  email,
  cpf,
  cnpj,
  validDate,
  validDateMes,
  isNotFuture,
  match,
  numeric,
  decimal,
  geq,
  gt,
  requiredIf,
  distinct,
  money,
  cpf_cnpj,
  gep,
  minDate,
  maxDate,
  volumes,
  descontos,
  moneyGt,
}
