const router = function (permissions) {
  return ({
    path: 'tecnicos',
    component: () => import('@/views/Tecnico/TecnicoModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Tecnico/TecnicoList.vue'),
        meta: { permissions, title: 'Manutenção de Técnicos' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Tecnico/TecnicoProfile.vue'),
        meta: { permissions, title: 'Cadastrando Técnico' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Tecnico/TecnicoProfile.vue'),
        meta: { permissions, title: 'Editando Técnico' },
      },
    ],
  })
}

export default {
  router,
}
