<template>
  <v-container class="py-0 pl-4 pr-0">
    <base-material-card
      :icon="icon"
      class="v-card--material-stats ajuste-margem"
      v-bind="$attrs"
      :card-dashboard="true"
      :background="colorBackground"
      v-on="$listeners"
    >
      <template v-slot:after-heading>
        <div class="ml-auto text-right">
          <v-row
            no-gutters
            dense
          >
            <v-col
              align-self="end"
              cols="12"
            >
              <span
                :style="{
                  fontSize: '40px',
                  color:'#f8f8ff',
                  fontStyle: 'normal',
                  letterSpacing: '-1.301px',
                  fontWeight: 700
                }"
              >
                {{ value }} <small>{{ smallValue }}</small>
              </span>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            dense
          >
            <v-col
              class="body-3 font-weight-bold"
              align-self="end"
              cols="12"
              v-text="title"
            />
          </v-row>
        </div>
      </template>
      <v-col
        v-if="subText || action"
        cols="12"
        class="px-0 py-2"
      >
        <v-divider />
      </v-col>
      <v-row
        v-if="subIcon || subText"
      >
        <v-col
          cols="11"
          class="pb-2"
        >
          <v-icon
            :color="subIconColor"
            size="16"
            class="ml-1  mr-1"
          >
            {{ subIcon }}
          </v-icon>
          <span
            :class="subTextColor"
            class="caption font-weight-medium"
            v-text="subText"
          />
        </v-col>
        <v-col
          v-if="action"
          class="px-0 pb-0"
          cols="1"
        >
          <v-icon
            color="#FFFFFF"
            size="16"
            title="Visualizar os itens"
            @click="action"
          >
            mdi-file-search-outline
          </v-icon>
        </v-col>
      </v-row>
      <div
        v-if="help && ! action"
        style="position: absolute; right: 5px; bottom: 5px;"
      >
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="info"
              v-bind="attrs"
              small
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ help }}</span>
        </v-tooltip>
      </div>
    </base-material-card>
  </v-container>
</template>

<script>
  import Card from './Card'

  export default {
    name: 'MaterialStatsCard',

    inheritAttrs: false,

    props: {
      ...Card.props,
      icon: {
        type: String,
        required: true,
      },
      subIcon: {
        type: String,
        default: undefined,
      },
      subIconColor: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      smallValue: {
        type: String,
        default: undefined,
      },
      action: {
        type: Function,
        default: undefined,
      },
      help: {
        type: String,
        default: undefined,
      },
      colorBackground: {
        type: String,
        default: '#FFF',
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
.ajuste-margem
  margin-top: 10px
  margin-bottom: 10px
</style>
