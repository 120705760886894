<template>
  <v-card
    :style="
      $vuetify.breakpoint.xs
        ? 'padding: 12px; margin: 4px'
        : 'padding: 14px; margin: 0 12px 0 12px'
    "
  >
    <base-loading v-if="loading" />
    <div v-if="!loading">
      <slot name="prepend" />
      <slot name="default" />
      <slot name="append" />
    </div>
  </v-card>
</template>
<script>
  export default {
    name: 'Cadastro',
    props: {
      loading: {
        type: Boolean,
        default: true,
      },
    },
  }
</script>
