<script>
  export default {
    name: 'FormBase',
    props: {
      compName: {
        type: String,
        required: true,
      },
      compProps: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    render (createElement) {
      return createElement(this.compName, { props: this.compProps })
    },
  }
</script>
