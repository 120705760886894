const router = function (permissions) {
  return ({
    path: 'gestores',
    component: () => import('@/views/Gestor/GestorModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Gestor/GestorList.vue'),
        meta: { permissions, title: 'Manutenção de Gestores' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Gestor/GestorProfile.vue'),
        meta: { permissions, title: 'Cadastrando Gestor' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Gestor/GestorProfile.vue'),
        meta: { permissions, title: 'Editando Gestor' },
      },
    ],
  })
}

export default {
  router,
}
