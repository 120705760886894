import { createUnidadeNegocio } from '@/models/UnidadeNegocio'

const pessoaTransformer = (data) => {
  return {
    email: data.email,
  }
}

const pessoaFisicaTransformer = (data) => {
  return {
    cpf: data.cpf,
    data_nascimento: data.data_nascimento,
    nome: data.nome,
    rg: data.rg,
    sexo: data.sexo,
  }
}
const pessoaJuridicaTransformer = (data) => {
  return {
    cnpj: data.cnpj,
    razao_social: data.razao_social,
    nome_fantasia: data.nome_fantasia,
    inscricao_estadual: data.inscricao_estadual,
  }
}

const enderecoTransformer = (data) => {
  return {
    bairro: data.bairro,
    cep: data.cep,
    complemento: data.complemento,
    logradouro: data.logradouro,
    numero: data.numero,
    cidade: parseInt(data.cidade.id),
    estado: parseInt(data.estado.id),
    pais: parseInt(data.pais.id),
  }
}
const telefonesTransformer = (data, format) => {
  return {
    telefones: data,
  }
}
const unidadeNegocioTransformerParaGestor = (data) => {
  return {
    id: data.id,
  }
}

const tecnicoTransformer = (data) => {
  return {
    unidades: data.unidades.map(
      (unidade) => unidadeNegocioTransformerParaGestor(unidade).id,
    ),
    id: data.id,
    supervisor_id: data.supervisor_id,
    ...pessoaTransformer(data),
    ...pessoaFisicaTransformer(data),
    ...telefonesTransformer(data.telefones),
    role: data.roles.id,
  }
}
const unidadeNegocioTransformer = (data) => {
  return createUnidadeNegocio({
    id: data.id,
    cod_centro: data.cod_centro,
    cod_clube: data.cod_clube,
    email_adm_clube: data.email_adm_clube,
    email: data.email,
    telefones: data.telefones,
    gestor_id: data.gestor.id,
    regional_id: data.regional.id,
    // ...pessoaTransformer(data),
    ...pessoaJuridicaTransformer(data),
    ...enderecoTransformer(data.endereco),
    ...telefonesTransformer(data.telefones),
  })
}

const supervisorTransformer = (data) => {
  return {
    unidades: data.unidades.map(
      (unidade) => unidadeNegocioTransformerParaGestor(unidade).id,
    ),
    id: data.id,
    ...pessoaTransformer(data),
    ...pessoaFisicaTransformer(data),
    ...telefonesTransformer(data.telefones),
    gestor_id: data.gestor.id,
    role: data.roles.id,
  }
}

const descontoCategoriaTransformer = (data) => {
  return {
    id: data.id,
    unidade_negocio_id: data.unidade_negocio.id,
    categoria_id: data.categoria.id,
    produto_id: data.produto ? data.produto.id : null,
    desconto: data.desconto ?? null,
    desc_supervisor: data.desc_supervisor ?? null,
    desc_gerente: data.desc_gerente ?? null,
    plano_pagamento: data.plano_pagamento ?? null,
    parcelas: data.parcelas ?? null,
  }
}

const produtorTransformer = (data) => {
  return {
    id: data.id,
    fazendas: data.fazendas,
    ...pessoaTransformer(data),
    ...(data.cpf
      ? pessoaFisicaTransformer(data)
      : pessoaJuridicaTransformer(data)),
    ...enderecoTransformer(data.endereco),
    ...telefonesTransformer(data.telefones),
  }
}

const gestorTransformer = (data) => {
  return {
    unidades: data.unidades.map(
      (unidade) => unidadeNegocioTransformerParaGestor(unidade).id,
    ),
    id: data.id,
    ...pessoaTransformer(data),
    ...pessoaFisicaTransformer(data),
    ...telefonesTransformer(data.telefones),
    role: data.roles.id,
  }
}
const produtorTransformerParaFazenda = (data) => {
  return {
    id: data.id,
  }
}

const fazendaTransformer = (data) => {
  return {
    id: data.id,
    unidade_negocio_id: data.unidade_negocio_id,
    nirf: data.nirf,
    ramo_atividade: data.ramo_atividade,
    longitude: data.longitude,
    latitude: data.latitude,
    produtor: data.produtores
      ? data.produtores.map(
        (produtor) => produtorTransformerParaFazenda(produtor).id,
      )
      : null,
    ...pessoaTransformer(data),
    ...pessoaJuridicaTransformer(data),
    ...enderecoTransformer(data.endereco),
    ...telefonesTransformer(data.telefones),
  }
}
const produtoTransformer = (data) => {
  return {
    id: data.id,
    marca: data.marca,
    fornecedor_id: data.fornecedor.id,
    categoria_id: data.categoria.id,
    unidade_id: data.unidade.id,
    codigo: data.codigo,
    preco_venda_sugerido: data.preco_venda_sugerido,
    nome: data.nome,
    imagens: data.imagens,
    descricao: data.descricao,
    indicacao: data.rotulo ? JSON.parse(data.rotulo).indicacao : '',
    modo_uso: data.rotulo ? JSON.parse(data.rotulo).modo_uso : '',
    restricoes: data.rotulo ? JSON.parse(data.rotulo).restricoes : '',
    consumo: data.rotulo ? JSON.parse(data.rotulo).consumo : '',
    validade: data.rotulo ? JSON.parse(data.rotulo).validade : '',
    apresentacao: data.rotulo ? JSON.parse(data.rotulo).apresentacao : '',
    volume: data.volume,
  }
}
const categoriaTransformer = (data) => {
  return {
    nome: data.nome,
    id: data.id,
  }
}
const unidadeTransformer = (data) => {
  return {
    nome: data.nome,
    sigla: data.sigla,
    id: data.id,
  }
}

const regionalTransformer = (data) => {
  return {
    estados: data.estados.map((estado) => parseInt(estado.id)),
    nome: data.nome,
    id: data.id,
  }
}

const fornecedorTransformer = (data) => {
  if (data.pessoaFis) {
    return {
      id: data.id,
      codigo: data.codigo,
      pessoaFis: true,
      nome_fantasia: data.nome_fantasia,
      ...pessoaTransformer(data),
      ...enderecoTransformer(data.endereco),
      ...pessoaFisicaTransformer(data),
      ...telefonesTransformer(data.telefones),
    }
  } else {
    return {
      id: data.id,
      codigo: data.codigo,
      pessoaFis: false,
      ...pessoaTransformer(data),
      ...pessoaJuridicaTransformer(data),
      ...enderecoTransformer(data.endereco),
      ...telefonesTransformer(data.telefones),
    }
  }
}

const profileTransformer = (data) => {
  return {
    avatar: data.avatar,
    ...pessoaTransformer(data),
    ...(data.cpf
      ? pessoaFisicaTransformer(data)
      : pessoaJuridicaTransformer(data)),
    ...(data.endereco
      ? enderecoTransformer(data.endereco)
      : { endereco: null }),
    telefones: data.telefones || [],
  }
}

const produtoUnidadeNegocioTranformer = (data) => {
  return {
    produto: produtoTransformer(data.produto),
    id: data.id,
    estoque: data.estoque,
    pedido_min: data.pedido_min,
    preco: data.preco,
    disponibilidade: data.disponibilidade,
    unidade_negocio: unidadeNegocioTransformer(data.unidade_negocio),
  }
}

export {
  tecnicoTransformer,
  pessoaTransformer,
  pessoaFisicaTransformer,
  enderecoTransformer,
  unidadeNegocioTransformer,
  pessoaJuridicaTransformer,
  produtorTransformer,
  gestorTransformer,
  fazendaTransformer,
  fornecedorTransformer,
  profileTransformer,
  produtoTransformer,
  categoriaTransformer,
  unidadeTransformer,
  supervisorTransformer,
  regionalTransformer,
  produtoUnidadeNegocioTranformer,
  descontoCategoriaTransformer,
}
