<template>
  <v-text-field
    v-if="inputOutlined"
    v-model="number"
    v-mask="[...mask]"
    :rules="rules"
    :label="label"
    type="tel"
    validate-on-blur
    outlined
    rounded
    dense
    :disabled="disabled"
    autocomplete="nope"
  />
  <v-text-field
    v-else
    v-model="number"
    v-mask="[...mask]"
    :rules="rules"
    :label="label"
    type="tel"
    validate-on-blur
    dense
    :disabled="disabled"
    autocomplete="nope"
    filled
    rounded
  />
</template>
<script>
  import formRules from '../../utils/formRules'
  import { mapFields } from '../../utils/formMapFields'
  export default {
    name: 'Telefone',
    props: {
      index: {
        type: Number,
        default: () => 0,
      },
      telData: {
        type: Object,
        default: () => {},
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
      inputOutlined: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        tel: null,
      }
    },
    computed: {
      ...mapFields({
        module: 'form',
        fields: ['number'],
        custom: {
          number: {
            get () {
              return this.$store.state.form.data.telefones ? this.$store.state.form.data.telefones[this.index] : ''
            },
            set (value) {
              this.$store.commit('form/UPDATE_FORM_FIELD_ARRAY', {
                index: this.index,
                field: 'telefones',
                value,
              })
            },
          },
        },
      }),
      mask () {
        if (this.telData.format === 'com') {
          return ['(##) #####-####', '(##) ####-####']
        } else if (this.telData.format === 'cel') {
          return ['(##) #####-####']
        } else {
          return ['(##) ####-####']
        }
      },
      rules () {
        let rules = this.telData.required ? [formRules.required] : []

        rules = [...rules, formRules.minLength(14, '* Número inválido')]
        return rules
      },
      label () {
        return this.telData.title + (this.telData.required ? ' *' : '')
      },
    },
    created () {
      if (this.edit) {
        this.number = this.$store.state.form.data.telefones[this.index]
      }
    },
  }
</script>
