export function mapFields (options) {
  const { fields, module = null, custom } = options
  const object = [...fields, 'edit'].reduce((object, field) => {
    return Object.assign(object, {
      [field]: {
        get () {
          return this.$store.state[module || this.storeModule].data[field]
        },
        set (value) {
          this.$store.commit(
            `${module || this.storeModule}/UPDATE_FORM_FIELD`,
            { [field]: value },
          )
        },
        ...(custom ? custom[field] : {}),
      },
    })
  }, {})
  return Object.assign(object, {
    fields: {
      get () {
        return fields
      },
      set (data) {
        fields.forEach(field => {
          this[field] = data[field] || null
        })
      },
    },
  })
}

export function mapFieldsDetails (options) {
  const { fields, module = null, custom } = options
  const object = [...fields, 'edit'].reduce((object, field) => {
    return Object.assign(object, {
      [field]: {
        get () {
          return this.$store.state[module || this.storeModule].detail[field]
        },
        set (value) {
          this.$store.commit(
            `${module || this.storeModule}/UPDATE_FORM_FIELD_DETAIL`,
            { [field]: value },
          )
        },
        ...(custom ? custom[field] : {}),
      },
    })
  }, {})
  return Object.assign(object, {
    fields: {
      get () {
        return fields
      },
      set (detail) {
        fields.forEach(field => {
          this[field] = detail[field] || null
        })
      },
    },
  })
}

export function mapIndexField (options) {
  const { module, field, index } = options
  return {
    [field]: {
      get () {
        return this.$store.state.form[module || this.storeModule].data[field][
          index()
        ]
      },
      set (value) {
        this.$store.commit(
          `${module || this.storeModule}/UPDATE_FORM_FIELD_ARRAY`,
          { [field]: value, index: index() },
        )
      },
    },
  }
}

export function createFormFields (options) {
  const {
    create,
    fields,
    store: { state },
    module,
    defaults,
  } = options

  state[module] = fields
    ? Object.assign(
        ...[{}, create || state[module]],
        Object.fromEntries(
          new Map(
            fields.map(field => [
              field,
              defaults && defaults[field] ? defaults[field] : null,
            ]),
          ),
        ),
      )
    : null

  const { edit } = state[module]
  edit || (state[module] = Object.assign({}, state[module], { edit: null }))
}

export function createFormFieldsArray (options) {
  const {
    field,
    store: { state },
    module,
    defaults,
    length,
  } = options

  state[module] = Object.assign({}, state[module], {
    [field]: defaults ? new Array(defaults) : new Array(length).fill(null),
  })
}

export function destroyFormFields (options) {
  const { store, module } = options

  store.state[module] = Object.assign({})
}
