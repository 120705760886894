import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_BATCH = 'LOAD_BATCH'
export const INATIVAR_PRODUTO = 'INATIVAR_PRODUTO'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    produtos: null,
    loading: true,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_BATCH] ({ commit, state }) {
    await api.listEntidade('produto').then(
      data => {
        const newProdutos = data.map(({ fornecedor: { nome, nome_fantasia }, ...rest }) => ({
          ...rest,
          fornecedor: {
            nome: nome || nome_fantasia,
          },
        }))
        commit(SET_STATE, { produtos: newProdutos })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    commit(SET_STATE, { ready: false, loading: true })
    await dispatch(LOAD_BATCH)
    commit(SET_STATE, { ready: true, loading: false })
  },
  async [INATIVAR_PRODUTO] ({ dispatch, commit, state }, produto) {
    Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que deseja excluir o Produto?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      preConfirm: () => {
        return api.inativarEntidade('produto', produto.id).then(
            result => result,
            () => {
              window.Toast.fire('Ocorreu algum erro na exclusão do Produto!', '', 'error')
            },
          )
      },
    }).then(result => {
      if (result.isConfirmed) {
        window.Toast.fire('O Produto foi excluído com sucesso!', '', 'success')
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
