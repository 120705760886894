<template>
  <v-row
    :class="baseClass"
    align="center"
    justify="center"
  >
    <v-img
      :src="currentImage"
      class="pt-4 mb-2"
      style="max-height: 300px;"
      contain
    />
    <span style="font-size: 18px; font-weight: bold; margin-bottom: 14px; color: #143693"> {{ message }}</span>
  </v-row>
</template>
<script>
  export default {
    props: {
      message: {
        type: String,
        default: () => 'Aguarde...   Coletando informações do servidor.',
      },
      baseClass: {
        type: String,
        default: () => 'fill-height ma-5 flex-column',
      },
    },
    data () {
      return {
        interval: {},
        value: 0,
        index: 0,
        images:
          [
            require('../../assets/Loading/ml1.png'),
            require('../../assets/Loading/ml2.png'),
            require('../../assets/Loading/ml3.png'),
            require('../../assets/Loading/ml4.png'),
            require('../../assets/Loading/ml5.png'),
          ],
      }
    },
    computed: {
      currentImage () {
        return this.images[this.index]
      },
    },
    mounted () {
      this.startImageRotation()
    },
    methods: {
      startImageRotation () {
        const updateFrame = () => {
          this.index = (this.index + 1) % this.images.length
          setTimeout(() => requestAnimationFrame(updateFrame), 400)
        }
        requestAnimationFrame(updateFrame)
      },
    },
  }
</script>
