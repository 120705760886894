const permissions = [
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
  {
    role: 'admin',
    access: true,
    redirect: 'controleacesso',
  },
  {
    role: 'gestor',
    access: true,
    redirect: 'controleacesso',
  },
]

export default [
  {
    name: 'controleacesso',
    path: '/controleacesso',
    component: () => import('@/views/Index'),
    meta: { requiresAuth: true, permissions },
    children: [
      {
        name: 'Controle de acesso',
        path: 'sempermissao',
        component: () => import('@/views/SemPermissao/SemPermissao.vue'),
        meta: { permissions },
      },
    ],
  },
]
