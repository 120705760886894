const router = function (permissions) {
  return ({
    path: 'regionais',
    component: () => import('@/views/Regional/RegionalModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Regional/RegionalList.vue'),
        meta: { permissions, title: 'Manutenção de Regionais' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Regional/RegionalProfile.vue'),
        meta: { permissions, title: 'Cadastrando Regional' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Regional/RegionalProfile.vue'),
        meta: { permissions, title: 'Editando Regional' },
      },
    ],
  })
}

export default {
  router,
}
