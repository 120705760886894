import Vue from 'vue'
import axios from './axios'

Vue.prototype.api = {
  login: (userLogin, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/auth/login/web', { userLogin, password })
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  cadastrarEntidade: (form, entidade) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/${entidade}`, form)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  salvarEntidade: (form, entidade, id) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/${entidade}/${id}`, form)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  inativarEntidade: (entidade, id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${entidade}/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  paises: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve([{ nome: 'Brasil', id: 1 }]), 0)
    })
  },
  estados: codPais => {
    return new Promise((resolve, reject) => {
      axios
        .get('/estados/' + codPais)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  cidades: (codPais, codEstado) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/cidades/' + codPais + '/' + codEstado)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  verifyMail: token => {
    return new Promise((resolve, reject) => {
      axios
        .patch('/mailStatus/' + token)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  listEntidade: (entidade, config) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/' + entidade, config)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  resetPassword: (form, token) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/passwordReset/' + token, form)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  verifyToken: token => {
    return new Promise((resolve, reject) => {
      axios
        .post('/verifyToken/' + token)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  getEntidade: (entidade, id) => {
    return axios.get('/' + entidade + '/' + id)
  },
  forgotPassword: email => {
    return new Promise((resolve, reject) => {
      axios
        .post('/passwordForgot', { email })
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  getProdutosUnidades: (unidades) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/catalogo/produtos', { unidades })
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  uploadFile: (file, id) => {
    return new Promise((resolve, reject) => {
      axios.post('integracaofile', file)
        .then(response => {
          resolve(response.data)
        }).catch(_err => {
          reject(_err.data)
        })
    })
  },
  downloadFile: (id) => {
    return new Promise((resolve, reject) => {
      axios.get(`/integracaofile/${id}`)
        .then(response => {
          resolve(response)
        }).catch(_err => {
          reject(_err.data)
        })
    })
  },
  getProfile: () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/profile')
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  updateProfile: form => {
    return new Promise((resolve, reject) => {
      axios
        .patch('/profile', form)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  updatePassword: form => {
    return new Promise((resolve, reject) => {
      axios
        .patch('/profile/password', form)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  getPessoa: data => {
    return new Promise((resolve, reject) => {
      axios
        .post('/pessoa/dados', data)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  deleteCadFaz: (id, produtorId, fazendaId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('produtorFazenda/' + id + '/' + produtorId + '/' + fazendaId)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  deleteFaz: (idFaz, idProd) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('produtor/' + idProd + '/fazenda/' + idFaz)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  salvarAlteracoesControleAcesso: (dados) => {
    return new Promise((resolve, reject) => {
      axios
        .patch('/role', dados)
        .then(response => {
          resolve(response.data)
        })
        .catch(_err => {
          reject(_err.data)
        })
    })
  },
  ativarCadFaz: (id, produtorId, fazendaId) => {
    return new Promise((resolve, reject) => {
      axios.patch('produtorFazenda/ativar/' + id + '/' + produtorId + '/' + fazendaId).then(response => {
        resolve(response.data)
      }).catch(_err => {
        reject(_err.data)
      })
    })
  },
  inativarCadFaz: (id, produtorId, fazendaId) => {
    return new Promise((resolve, reject) => {
      axios.patch('produtorFazenda/inativar/' + id + '/' + produtorId + '/' + fazendaId).then(response => {
        resolve(response.data)
      }).catch(_err => {
        reject(_err.data)
      })
    })
  },
  updatePedidoStatus: (dados) => {
    return new Promise((resolve, reject) => {
      axios.patch('pedido/status', dados).then(response => {
        resolve(response.data)
      }).catch(_err => {
        reject(_err.data)
      })
    })
  },
  transferePedidoUnidadeNegocio: (unidade_negocio, id) => {
    return new Promise((resolve, reject) => {
      axios.patch('pedido/transfere/' + id, unidade_negocio).then(response => {
        resolve(response.data)
      }).catch(_err => {
        reject(_err.data)
      })
    })
  },
  updateCotacaoStatus: (dados) => {
    return new Promise((resolve, reject) => {
      axios.patch('cotacao/status', dados).then(response => {
        resolve(response.data)
      }).catch(_err => {
        reject(_err.data)
      })
    })
  },
}

export default Vue.prototype.api
