<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
    max-height="400px"
    eager
  >
    <vue-cropper
      :key="23871289372189"
      ref="cropper2"
      hidden
      eager
      style="overflow: hidden"
      :guides="false"
      :view-mode="2"
      :aspect-ratio="0.75"
      :min-container-width="300"
      :min-container-height="300"
      drag-mode="move"
      :responsive="false"
      :auto-crop-area="1"
      :background="true"
      :rotatable="true"
      alt="Source Image"
      :check-cross-origin="false"
      :class="circle ? 'rounded-circle' : 'rounded-sm'"
      @ready="cropSave2"
    />
    <template v-slot:activator="{ on }">
      <v-hover
        :style="`max-height: ${maxHei}; max-width: ${maxWid}; height: ${height};`"
      >
        <template v-slot:default="{ hover }">
          <v-card
            :class="circle ? 'rounded-circle pa-0 ma-0' : 'pa-0 ma-0'"
            fluid
            elevation="0"
          >
            <base-file-drop
              ref="file-drop"
              :disabled="disableOnFill && loaded_image"
              @files-selected="handleFiles"
            >
              <v-img
                :class="circle ? 'rounded-circle' : 'rounded-sm'"
                :max-height="maxHei"
                :max-width="maxWid"
                :height="height"
                :contain="circle ? false : true"
                :src="showImage"
                :style="!loaded_image ? 'background-color: RGB(198, 198, 198);': ''"
              />
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  color="#d2d2d2"
                  :z-index="1"
                >
                  <div
                    v-if="!loaded_image"
                    :class="circle ? 'rounded-circle d-flex flex-column align-center pa-2 pb-5' : 'd-flex flex-column align-center pa-2 pb-5'"
                  >
                    <v-btn
                      class="pa-8"
                      icon
                      color="primary lighten-1"
                      @click="selectFiles"
                    >
                      <v-icon large>
                        mdi-camera
                      </v-icon>
                    </v-btn>
                    <span class="primary--text text--lighten-1 text-center">
                      <b>Escolha</b> uma imagem do computador
                    </span>
                    <span
                      class="primary--text text--lighten-1 text-center my-2"
                    >
                      ou
                    </span>
                    <span
                      class="primary--text text--lighten-1 text-center mt-1"
                    >
                      <b>arraste</b> um arquivo para cá
                    </span>
                  </div>
                  <div
                    v-else
                    :class="circle ? 'rounded-circle' : ''"
                  >
                    <v-btn
                      v-if="editable"
                      class="pa-8"
                      icon
                      color="primary lighten-1"
                      v-on="on"
                      @click="att"
                    >
                      <v-icon large>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="pa-8"
                      icon
                      color="primary lighten-1"
                      @click="reset"
                    >
                      <v-icon large>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-overlay>
              </v-fade-transition>
            </base-file-drop>
          </v-card>
        </template>
      </v-hover>
    </template>
    <v-card
      justify="center"
      align="center"
    >
      <v-toolbar
        color="primary"
        dark
      >
        <v-col
          cols="11.5"
        >
          <span class="headline">{{ dialogTitle }}</span>
        </v-col>
        <v-btn
          icon
          dark
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-card-text>
          <v-card
            class="pa-0 mb-2"
            fluid
            elevation="0"
          >
            <vue-cropper
              :key="id"
              ref="cropper"
              style="overflow: hidden"
              :guides="false"
              :view-mode="2"
              :min-container-width="300"
              :min-container-height="400"
              drag-mode="move"
              :responsive="true"
              :auto-crop-area="1"
              :background="true"
              :rotatable="false"
              :zoomable="false"
              alt="Source Image"
              :check-cross-origin="false"
              @ready="crop"
            />
          </v-card>
          <small>Recorte e ajuste o tamanho da imagem</small>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="text-none text-white"
            color="error"
            rounded
            @click="dialog = false"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            rounded
            @click="() => { cropSave(); dialog = false }"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
  import { v4 } from 'uuid'
  import VueCropper from 'vue-cropperjs'
  import 'cropperjs/dist/cropper.css'
  export default {
    components: {
      VueCropper,
    },
    model: {
      prop: 'image',
      event: 'setImage',
    },
    props: {
      image: {
        type: [String, Object],
        default: null,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      noData: {
        type: String,
        default: null,
      },
      disableOnFill: {
        type: Boolean,
        default: true,
      },
      dialogTitle: {
        type: String,
        default: 'Imagem',
      },
      maxHei: {
        type: String,
        default: '220px',
      },
      maxWid: {
        type: String,
        default: '165px',
      },
      height: {
        type: String,
        default: '220px',
      },
      width: {
        type: String,
        default: '220px',
      },
      circle: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        id: v4(),
        dialog: false,
        loading: true,
        overlay: false,
        cropped_image: null,
      }
    },
    computed: {
      showImage () {
        return this.image || this.noData
      },
      loaded_image () {
        return !!this.image
      },
    },
    mounted () {
      if (this.image) {
        this.cropped_image = this.image
        this.$refs.cropper2.replace(this.image)
        this.cropSave2()
      } else {
        this.cropped_image = null
        this.$refs.cropper.reset()
      }
    },
    methods: {
      att () {
        this.$refs.cropper.replace(this.image)
      },
      handleFiles ($event) {
        const reader = new FileReader($event[0])
        let image = null
        reader.onload = e => {
          image = e.target.result
          this.dialog = true
          this.$refs.cropper.replace(image)
          this.$emit('upload', image)
        }
        reader.readAsDataURL($event[0])
      },
      selectFiles () {
        this.$refs['file-drop'].activate()
      },
      crop () {
        // const cpcanvas = this.$refs.cropper.getCroppedCanvas({
        //   maxWidth: 1024,
        //   maxHeight: 1024,
        //   fillColor: '#fff',
        // })
        // this.cropped_image = cpcanvas.toDataURL('image')
        // this.$emit('setImage', this.cropped_image)
      },
      cropSave () {
        const cpcanvas = this.$refs.cropper.getCroppedCanvas({
          maxWidth: 1024,
          maxHeight: 1024,
          fillColor: '#fff',
        })
        if (cpcanvas) {
          this.cropped_image = cpcanvas.toDataURL('image')
          this.$emit('setImage', this.cropped_image)
        }
      },
      cropSave2 () {
        const cpcanvas = this.$refs.cropper2.getCroppedCanvas({
          maxWidth: 1024,
          maxHeight: 1024,
          fillColor: '#fff',
        })
        if (cpcanvas) {
          this.cropped_image = cpcanvas.toDataURL('image')
          this.$emit('setImage', this.cropped_image)
        }
      },
      reset () {
        this.$refs.cropper.reset()
        this.cropped_image = null
        this.$emit('setImage', null)
        this.$emit('delete', null)
      },
    },
  }
</script>
