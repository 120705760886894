import router from '@/Routes/router'
import store from '@/store'

export const formatDescText = (text, descLength) => {
  if (text.length > descLength) {
    text = text.slice(0, text[descLength] === ' ' ? descLength : descLength - 1) + '...'
  }
  return text
}

export const handleProdLink = (id) => {
  router.push({
    path: `/${store.state.user.roles[0].slug}/loja/produto`,
    query: { id },
  })
  store.dispatch('produtoIndividual/BOOTSTRAP', { id })
}

export const formatImageLink = (url) => {
  if (url) {
    return 'produtos/' + url
  } else {
     return 'img/prodImage.png'
  }
}

export const getData = function (date) {
  const newDate = new Date()
  if (date) {
    const dateArray = date.substring(0, 10).split('-')
    newDate.setFullYear(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]))
  }
  newDate.setHours(0, 0, 0, 0)
  return newDate
}
