import { getField, updateField } from 'vuex-map-fields'
import Swal from 'sweetalert2'
import { v4 } from 'uuid'
import api from '@/api/api'
import router from '@/Routes/router'
import moment from 'moment'
import { getLocation } from '../../utils/objectUtils'

export const BOOTSTRAP = 'BOOTSTRAP'
export const BOOTSTRAP_PEDIDO = 'BOOTSTRAP_PEDIDO'
export const SET_STATE = 'SET_STATE'
export const FINALIZAR_PEDIDO = 'FINALIZAR_PEDIDO'
export const PUSH_PRODUTO = 'PUSH_PRODUTO'
export const REMOVE_PRODUTO = 'REMOVE_PRODUTO'
export const LOAD_RECOMENDADOS = 'LOAD_RECOMENDADOS'
export const RESET_STATE = 'RESET_STATE'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const ADD_PRODUTO_FROM_CART = 'ADD_PRODUTO_FROM_CART'
export const ADD_DESCONTO_FROM_CART = 'ADD_DESCONTO_FROM_CART'
export const SUB_PRODUTO_FROM_CART = 'SUB_PRODUTO_FROM_CART'
export const APLICA_QUANTIDADE = 'APLICA_QUANTIDADE'
export const PLACE_ORDER = 'PLACE_ORDER'
export const ADD_PRODUTO = 'ADD_PRODUTO'
export const ADD_DESCONTO = 'ADD_DESCONTO'
export const SUB_PRODUTO = 'SUB_PRODUTO'
export const CALCULAR_REGRAS = 'CALCULAR_REGRAS'
export const ADD_QUANTIDADE = 'ADD_QUANTIDADE'
export const ADD_PREVISAO_COMPRA = 'ADD_PREVISAO_COMPRA'
export const ADD_PREVISAO_FROM_CART = 'ADD_PREVISAO_FROM_CART'

const rulesCampaignAdd = (rootState, itemCampanha) => {
  if ((itemCampanha.id_campanha === '') ||
    (itemCampanha.id_campanha === '-1') ||
    (itemCampanha.id_campanha === undefined)
  ) {
    return
  }
  // validação das regras das campanhas
  // produtos no carrinho da mesma campanha
  const produtosCampanha = rootState.carrinho.produtos.filter(el => el.id_campanha === itemCampanha.id_campanha)

  // a campanha do produto
  const campanha = rootState.loja.ofertasCampanhas.find(el => el.id === itemCampanha.id_campanha)
  // filtra as regras da campanha que possuem o item
  const regrasCampanha = campanha.regras.filter(el => el.produtos.length &&
    el.produtos.filter(prod => prod.id === itemCampanha.id_produto).length)

  // ordena decrescente os descontos nas condiçoes das regras
  for (let i = 0; i < regrasCampanha.length; i++) {
    regrasCampanha[i].condicoes.sort(function (a, b) {
      return parseFloat(b.desconto).toFixed(2) - parseFloat(a.desconto).toFixed(2)
    })
  }

  // ordena decrescente os descontos nas regras
  regrasCampanha.sort(function (a, b) {
    return parseFloat(b.condicoes[0].desconto).toFixed(2) - parseFloat(a.condicoes[0].desconto).toFixed(2)
  })

  // filtra as regras do tipo quantidade
  const regrasQuantidade = regrasCampanha.filter(el => el.tipo === 'quantidade')
  // filtra as regras do tipo volume
  const regrasVolume = regrasCampanha.filter(el => el.tipo === 'volume')

  // checagem das regras quantidade
  for (let i = 0; i < regrasQuantidade.length; i++) {
    const produtosCarrinho = produtosCampanha.filter(el => regrasQuantidade[i].produtos.filter(produto => produto.id === el.id_produto).length)
    let quantidade = 0
    // somo as quantidades dos itens do carrinho que estão na mesma regra
    for (let j = 0; j < produtosCarrinho.length; j++) {
      quantidade += parseFloat(produtosCarrinho[j].quantidade)

      const oldObjectIndex = state.produtos.findIndex(el => el.id === produtosCarrinho[j].id)
      state.produtos[oldObjectIndex].id_produto = produtosCarrinho[j].id_produto
      state.produtos[oldObjectIndex].id_user = produtosCarrinho[j].id_user
      state.produtos[oldObjectIndex].nome = produtosCarrinho[j].nome
      state.produtos[oldObjectIndex].pedido_min = produtosCarrinho[j].pedido_min
      state.produtos[oldObjectIndex].quantidade = produtosCarrinho[j].quantidade
      state.produtos[oldObjectIndex].unidade = produtosCarrinho[j].unidade
      state.produtos[oldObjectIndex].preco = produtosCarrinho[j].preco
      state.produtos[oldObjectIndex].subtotal = produtosCarrinho[j].subtotal
      state.produtos[oldObjectIndex].vlrdesconto = 0
      state.produtos[oldObjectIndex].vlrtotal = produtosCarrinho[j].subtotal
      state.produtos[oldObjectIndex].desconto_negociado = produtosCarrinho[j].desconto_negociado
      state.produtos[oldObjectIndex].id_regra = ''
      state.produtos[oldObjectIndex].id_campanha = produtosCarrinho[j].id_campanha
      state.produtos[oldObjectIndex].unidade_negocio = produtosCarrinho[j].unidade_negocio
      state.produtos[oldObjectIndex].data_atual = produtosCarrinho[j].data_atual
      state.produtos[oldObjectIndex].volume = produtosCarrinho[j].volume
      state.produtos[oldObjectIndex].nome_campanha = produtosCarrinho[j].nome_campanha
      state.produtos[oldObjectIndex].imagens = produtosCarrinho[j].imagens
      state.produtos[oldObjectIndex].marca = produtosCarrinho[j].marca
      state.produtos[oldObjectIndex].parcelamento = produtosCarrinho[j].parcelamento
      state.produtos[oldObjectIndex].fazenda_id = produtosCarrinho[j].fazenda_id
      state.produtos[oldObjectIndex].produtor_id = produtosCarrinho[j].produtor_id
      state.produtos[oldObjectIndex].fazenda_name = produtosCarrinho[j].fazenda_name
      state.produtos[oldObjectIndex].produtor_name = produtosCarrinho[j].produtor_name
      state.produtos[oldObjectIndex].entrega = produtosCarrinho[j].entrega
      state.produtos[oldObjectIndex].desconto_tecnico = produtosCarrinho[j].desconto_tecnico
      state.produtos[oldObjectIndex].vlr_parcelas = produtosCarrinho[j].vlr_parcelas
    }

    if (regrasQuantidade[i].condicoes[0].calculo === 'Multiplo') {
      const numeroDeKits = Math.floor(parseFloat(quantidade) / parseFloat(regrasQuantidade[i].condicoes[0].quantidade))
      let quantidadeNosKits = numeroDeKits * parseFloat(regrasQuantidade[i].condicoes[0].quantidade)

      if (numeroDeKits > 0) {
        for (let j = 0; j < produtosCarrinho.length; j++) {
          let quantidadeItem = produtosCarrinho[j].quantidade

          if (quantidadeItem <= quantidadeNosKits) {
            quantidadeNosKits -= quantidadeItem
          } else {
            quantidadeItem = quantidadeNosKits
            quantidadeNosKits = 0
          }

          if (quantidadeItem > 0) {
            const valorDesconto = (parseFloat((produtosCarrinho[j].preco) * quantidadeItem * parseFloat(regrasQuantidade[i].condicoes[0].desconto) * 0.01) + (produtosCarrinho[j].desconto_negociado > 0 ? (produtosCarrinho[j].subtotal * produtosCarrinho[j].desconto_negociado * 0.01) : 0)).toFixed(2)

            const oldObjectIndex = state.produtos.findIndex(el => el.id === produtosCarrinho[j].id)
            state.produtos[oldObjectIndex].id_produto = produtosCarrinho[j].id_produto
            state.produtos[oldObjectIndex].id_user = produtosCarrinho[j].id_user
            state.produtos[oldObjectIndex].nome = produtosCarrinho[j].nome
            state.produtos[oldObjectIndex].pedido_min = produtosCarrinho[j].pedido_min
            state.produtos[oldObjectIndex].quantidade = produtosCarrinho[j].quantidade
            state.produtos[oldObjectIndex].unidade = produtosCarrinho[j].unidade
            state.produtos[oldObjectIndex].preco = produtosCarrinho[j].preco
            state.produtos[oldObjectIndex].subtotal = produtosCarrinho[j].subtotal
            state.produtos[oldObjectIndex].vlrdesconto = valorDesconto
            state.produtos[oldObjectIndex].vlrtotal = (parseFloat(produtosCarrinho[j].subtotal) - parseFloat(valorDesconto)).toFixed(2)
            state.produtos[oldObjectIndex].desconto_negociado = produtosCarrinho[j].desconto_negociado
            state.produtos[oldObjectIndex].id_regra = regrasQuantidade[i].id
            state.produtos[oldObjectIndex].id_campanha = produtosCarrinho[j].id_campanha
            state.produtos[oldObjectIndex].unidade_negocio = produtosCarrinho[j].unidade_negocio
            state.produtos[oldObjectIndex].data_atual = produtosCarrinho[j].data_atual
            state.produtos[oldObjectIndex].volume = produtosCarrinho[j].volume
            state.produtos[oldObjectIndex].nome_campanha = produtosCarrinho[j].nome_campanha
            state.produtos[oldObjectIndex].imagens = produtosCarrinho[j].imagens
            state.produtos[oldObjectIndex].marca = produtosCarrinho[j].marca
            state.produtos[oldObjectIndex].parcelamento = regrasQuantidade[i].parcelamento
            state.produtos[oldObjectIndex].fazenda_id = produtosCarrinho[j].fazenda_id
            state.produtos[oldObjectIndex].produtor_id = produtosCarrinho[j].produtor_id
            state.produtos[oldObjectIndex].fazenda_name = produtosCarrinho[j].fazenda_name
            state.produtos[oldObjectIndex].produtor_name = produtosCarrinho[j].produtor_name
            state.produtos[oldObjectIndex].entrega = produtosCarrinho[j].entrega
            state.produtos[oldObjectIndex].desconto_tecnico = produtosCarrinho[j].desconto_tecnico
            state.produtos[oldObjectIndex].vlr_parcelas = produtosCarrinho[j].vlr_parcelas
          }
        }
        break
      }
    } else {
      if (parseFloat(quantidade) >= parseFloat(regrasQuantidade[i].condicoes[0].quantidade)) {
        for (let j = 0; j < produtosCarrinho.length; j++) {
          const valorDesconto = (parseFloat((produtosCarrinho[j].subtotal) * parseFloat(regrasQuantidade[i].condicoes[0].desconto) * 0.01) + (produtosCarrinho[i].desconto_negociado > 0 ? (produtosCarrinho[i].subtotal * produtosCarrinho[i].desconto_negociado * 0.01) : 0)).toFixed(2)

          const oldObjectIndex = state.produtos.findIndex(el => el.id === produtosCarrinho[j].id)
          state.produtos[oldObjectIndex].id_produto = produtosCarrinho[j].id_produto
          state.produtos[oldObjectIndex].id_user = produtosCarrinho[j].id_user
          state.produtos[oldObjectIndex].nome = produtosCarrinho[j].nome
          state.produtos[oldObjectIndex].pedido_min = produtosCarrinho[j].pedido_min
          state.produtos[oldObjectIndex].quantidade = produtosCarrinho[j].quantidade
          state.produtos[oldObjectIndex].unidade = produtosCarrinho[j].unidade
          state.produtos[oldObjectIndex].preco = produtosCarrinho[j].preco
          state.produtos[oldObjectIndex].subtotal = produtosCarrinho[j].subtotal
          state.produtos[oldObjectIndex].vlrdesconto = valorDesconto
          state.produtos[oldObjectIndex].vlrtotal = (parseFloat(produtosCarrinho[j].subtotal) - parseFloat(valorDesconto)).toFixed(2)
          state.produtos[oldObjectIndex].desconto_negociado = produtosCarrinho[j].desconto_negociado
          state.produtos[oldObjectIndex].id_regra = regrasQuantidade[i].id
          state.produtos[oldObjectIndex].id_campanha = produtosCarrinho[j].id_campanha
          state.produtos[oldObjectIndex].unidade_negocio = produtosCarrinho[j].unidade_negocio
          state.produtos[oldObjectIndex].data_atual = produtosCarrinho[j].data_atual
          state.produtos[oldObjectIndex].volume = produtosCarrinho[j].volume
          state.produtos[oldObjectIndex].nome_campanha = produtosCarrinho[j].nome_campanha
          state.produtos[oldObjectIndex].imagens = produtosCarrinho[j].imagens
          state.produtos[oldObjectIndex].marca = produtosCarrinho[j].marca
          state.produtos[oldObjectIndex].parcelamento = regrasQuantidade[i].parcelamento
          state.produtos[oldObjectIndex].fazenda_id = produtosCarrinho[j].fazenda_id
          state.produtos[oldObjectIndex].produtor_id = produtosCarrinho[j].produtor_id
          state.produtos[oldObjectIndex].fazenda_name = produtosCarrinho[j].fazenda_name
          state.produtos[oldObjectIndex].produtor_name = produtosCarrinho[j].produtor_name
          state.produtos[oldObjectIndex].entrega = produtosCarrinho[j].entrega
          state.produtos[oldObjectIndex].desconto_tecnico = produtosCarrinho[j].desconto_tecnico
          state.produtos[oldObjectIndex].vlr_parcelas = produtosCarrinho[j].vlr_parcelas
        }
      }
    }
  }

  // checagem das regras volume
  for (let i = 0; i < regrasVolume.length; i++) {
    // filtra os produtos do carrinho que estão na regra de volume
    const produtosCarrinho = produtosCampanha.filter(el => regrasVolume[i].produtos.filter(produto => produto.id === el.id_produto).length)
    let volume = 0

    // soma os volumes totais dos itens do carrinho que estão na mesma regra
    for (let j = 0; j < produtosCarrinho.length; j++) {
      volume += parseFloat(produtosCarrinho[j].volume)

      const oldObjectIndex = state.produtos.findIndex(el => el.id === produtosCarrinho[j].id)
      state.produtos[oldObjectIndex].id_produto = produtosCarrinho[j].id_produto
      state.produtos[oldObjectIndex].id_user = produtosCarrinho[j].id_user
      state.produtos[oldObjectIndex].nome = produtosCarrinho[j].nome
      state.produtos[oldObjectIndex].pedido_min = produtosCarrinho[j].pedido_min
      state.produtos[oldObjectIndex].quantidade = produtosCarrinho[j].quantidade
      state.produtos[oldObjectIndex].unidade = produtosCarrinho[j].unidade
      state.produtos[oldObjectIndex].preco = produtosCarrinho[j].preco
      state.produtos[oldObjectIndex].desconto_negociado = produtosCarrinho[j].desconto_negociado
      state.produtos[oldObjectIndex].subtotal = produtosCarrinho[j].subtotal
      state.produtos[oldObjectIndex].vlrdesconto = 0
      state.produtos[oldObjectIndex].vlrtotal = produtosCarrinho[j].subtotal
      state.produtos[oldObjectIndex].id_regra = ''
      state.produtos[oldObjectIndex].id_campanha = produtosCarrinho[j].id_campanha
      state.produtos[oldObjectIndex].unidade_negocio = produtosCarrinho[j].unidade_negocio
      state.produtos[oldObjectIndex].data_atual = produtosCarrinho[j].data_atual
      state.produtos[oldObjectIndex].volume = produtosCarrinho[j].volume
      state.produtos[oldObjectIndex].nome_campanha = produtosCarrinho[j].nome_campanha
      state.produtos[oldObjectIndex].imagens = produtosCarrinho[j].imagens
      state.produtos[oldObjectIndex].marca = produtosCarrinho[j].marca
      state.produtos[oldObjectIndex].parcelamento = produtosCarrinho[j].parcelamento
      state.produtos[oldObjectIndex].fazenda_id = produtosCarrinho[j].fazenda_id
      state.produtos[oldObjectIndex].produtor_id = produtosCarrinho[j].produtor_id
      state.produtos[oldObjectIndex].fazenda_name = produtosCarrinho[j].fazenda_name
      state.produtos[oldObjectIndex].produtor_name = produtosCarrinho[j].produtor_name
      state.produtos[oldObjectIndex].entrega = produtosCarrinho[j].entrega
      state.produtos[oldObjectIndex].desconto_tecnico = produtosCarrinho[j].desconto_tecnico
      state.produtos[oldObjectIndex].vlr_parcelas = produtosCarrinho[j].vlr_parcelas
    }
    // filtra as condições dentro da regra volume
    const condicoesRegrasVolume = regrasVolume[i].condicoes.filter(el => el.volume)

    // percorre as condições da regra e volume
    for (let k = 0; k < condicoesRegrasVolume.length; k++) {
      if (volume >= parseFloat(condicoesRegrasVolume[k].volume)) {
        for (let j = 0; j < produtosCarrinho.length; j++) {
          const valorDesconto = (parseFloat((produtosCarrinho[j].subtotal) * parseFloat(condicoesRegrasVolume[k].desconto) * 0.01) + (produtosCarrinho[j].desconto_negociado > 0 ? (produtosCarrinho[j].subtotal * produtosCarrinho[j].desconto_negociado * 0.01) : 0)).toFixed(2)

          const oldObjectIndex = state.produtos.findIndex(el => el.id === produtosCarrinho[j].id)
          state.produtos[oldObjectIndex].id_produto = produtosCarrinho[j].id_produto
          state.produtos[oldObjectIndex].id_user = produtosCarrinho[j].id_user
          state.produtos[oldObjectIndex].nome = produtosCarrinho[j].nome
          state.produtos[oldObjectIndex].pedido_min = produtosCarrinho[j].pedido_min
          state.produtos[oldObjectIndex].quantidade = produtosCarrinho[j].quantidade
          state.produtos[oldObjectIndex].unidade = produtosCarrinho[j].unidade
          state.produtos[oldObjectIndex].preco = produtosCarrinho[j].preco
          state.produtos[oldObjectIndex].desconto_negociado = produtosCarrinho[j].desconto_negociado
          state.produtos[oldObjectIndex].subtotal = produtosCarrinho[j].subtotal
          state.produtos[oldObjectIndex].vlrdesconto = valorDesconto
          state.produtos[oldObjectIndex].vlrtotal = (parseFloat(produtosCarrinho[j].subtotal) - parseFloat(valorDesconto)).toFixed(2)
          state.produtos[oldObjectIndex].id_regra = regrasVolume[i].id
          state.produtos[oldObjectIndex].id_campanha = produtosCarrinho[j].id_campanha
          state.produtos[oldObjectIndex].unidade_negocio = produtosCarrinho[j].unidade_negocio
          state.produtos[oldObjectIndex].data_atual = produtosCarrinho[j].data_atual
          state.produtos[oldObjectIndex].volume = produtosCarrinho[j].volume
          state.produtos[oldObjectIndex].nome_campanha = produtosCarrinho[j].nome_campanha
          state.produtos[oldObjectIndex].imagens = produtosCarrinho[j].imagens
          state.produtos[oldObjectIndex].marca = produtosCarrinho[j].marca
          state.produtos[oldObjectIndex].parcelamento = condicoesRegrasVolume[k].parcelamento
          state.produtos[oldObjectIndex].fazenda_id = produtosCarrinho[j].fazenda_id
          state.produtos[oldObjectIndex].produtor_id = produtosCarrinho[j].produtor_id
          state.produtos[oldObjectIndex].fazenda_name = produtosCarrinho[j].fazenda_name
          state.produtos[oldObjectIndex].produtor_name = produtosCarrinho[j].produtor_name
          state.produtos[oldObjectIndex].entrega = produtosCarrinho[j].entrega
          state.produtos[oldObjectIndex].desconto_tecnico = produtosCarrinho[j].desconto_tecnico
          state.produtos[oldObjectIndex].vlr_parcelas = produtosCarrinho[j].vlr_parcelas
        }
        break
      }
    }
  }
}

const validateCart = (cartState, item, unidade_negocio, fazenda) => {
  /*
  100 -> pode inserir
  200 -> produtos com unidades diferentes
  300 -> produtos que não estão em campanha com itens que estão em campanha
  400 -> produtos de campanhas diferentes
  401 -> produtos de fazendas diferentes
  */
  if (!cartState.length > 0) {
    return 100
  } else {
    if (!(cartState.filter(el => el.fazenda_id === fazenda).length > 0)) {
      return 401
    } else if ((item.id_campanha !== '') && (item.id_campanha !== '-1')) {
      const itemCampanha = cartState.filter(el => el.id_campanha === item.id_campanha)
      if (itemCampanha.length > 0) {
        return 100
      } else {
        return 300
      }
    } else if ((item.id_campanha === '') || (item.id_campanha === '-1')) {
      const itemCampanha = cartState.filter(el => (el.id_campanha !== '') && (el.id_campanha !== '-1'))
      if (itemCampanha.length > 0) {
        return 400
      } else {
        return 100
      }
    } else {
      for (let i = 0; i < cartState.length; i++) {
        if (cartState[i].unidade_negocio === unidade_negocio) {
          return 100
        } else {
          return 200
        }
      }
    }
  }
}

const getData = function (date) {
  const newDate = new Date()
  if (date) {
    const dateArray = date.substring(0, 10).split('-')
    newDate.setFullYear(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]))
  }
  return newDate
}

const findOffersDiscountCampaign = function (rootState, id_produto) {
  const produto = {
    desconto: '',
    id_campanha: '',
    id_regra: [],
    nomeCampanha: '',
    volume: '',
    parcelamento: '',
    pedido_numero: {},
  }
  const regrasCampanhas = []

  const produtoOferta = rootState.loja.itensOfertas.filter(el => el.produto_id === id_produto)

  if (produtoOferta.length > 0) {
    if (produtoOferta[0].id_campanha === '-1') {
      produto.id_regra = produtoOferta[0].id_regra
      produto.desconto = produtoOferta[0].condicoes[0].desconto
      produto.volume = produtoOferta[0].volume
      produto.parcelamento = produtoOferta[0].condicoes[0].parcelamento
      produto.entrega = produtoOferta[0].condicoes[0].entrega
    } else if (produtoOferta[0].id_campanha !== '-1') {
      const nomeCampanha = rootState.loja.ofertasCampanhas.filter(el => el.id === produtoOferta[0].id_campanha)
      produto.id_campanha = nomeCampanha[0].id
      produto.nomeCampanha = nomeCampanha[0].nome
      produto.volume = nomeCampanha[0].volume
      produto.parcelamento = produtoOferta[0].condicoes[0].parcelamento
      produto.entrega = produtoOferta[0].condicoes[0].entrega

      for (let i = 0; i < produtoOferta.length; i++) {
        regrasCampanhas.push(produtoOferta[i].id_regra)
      }

      produto.id_regra = regrasCampanhas
    }
  }
  return produto
}

const alertCarrinho = (alerta, mensagem) => {
  Swal.hideLoading()
  return Swal.fire({
    icon: 'error',
    title: alerta,
    text: mensagem,
  })
}

const getDefaultState = () => {
  return {
    loading: true,
    produtos: [],
    pedido_numero: '',
    data_atual: '',
    observacao: '',
    observacao_atendimento: '',
    data_entrega: '',
    parcelas: 1,
  }
}

const state = getDefaultState()

const getters = {
  getField,
  precoSubTotal: state => {
    let total = 0
    state.produtos.forEach(produto => { total = (total + parseFloat(produto.subtotal)) })
    return total.toFixed(2)
  },
  descontoTotal: state => {
    let total = 0
    state.produtos.forEach(produto => { total = (total + parseFloat(produto.vlrdesconto)) })
    return total.toFixed(2)
  },
  precoTotal: state => {
    let total = 0
    state.produtos.forEach(produto => { total = (total + parseFloat(produto.vlrtotal)) })
    return total.toFixed(2)
  },
  prazoEntrega: state => {
    let total = 0
    state.produtos.forEach(produto => { if (produto.entrega && parseInt(produto.entrega) > total) total = parseInt(produto.entrega) })
    return total
  },
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [PUSH_PRODUTO] (state, payload) {
    state.produtos.push(payload)
  },
  [REMOVE_PRODUTO] (state, { rootState, id }) {
    const index = state.produtos.findIndex(item => item.id === id)
    state.produtos.splice(index, 1)
    localStorage.setItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id), JSON.stringify(state.produtos))
  },
  [ADD_DESCONTO] (state, { rootState, produto }) {
    const index = state.produtos.findIndex(item => item.id === produto.id)
    state.produtos[index].desconto_negociado = produto.desconto_negociado > 0 ? produto.desconto_negociado : 0
    state.produtos[index].vlrdesconto = ((state.produtos[index].subtotal * (produto.desconto / 100)) + (produto.desconto_negociado > 0 ? state.produtos[index].subtotal * (produto.desconto_negociado / 100) : 0)).toFixed(2)
    state.produtos[index].vlrtotal = (state.produtos[index].subtotal - state.produtos[index].vlrdesconto).toFixed(2)
  },
  [ADD_PRODUTO] (state, { rootState, produto }) {
    const index = state.produtos.findIndex(item => item.id === produto.id)
    state.produtos[index].quantidade++
    state.produtos[index].volume = (parseFloat(produto.vol_unidade) * parseFloat(state.produtos[index].quantidade)).toFixed(3)
    state.produtos[index].subtotal = (state.produtos[index].preco * state.produtos[index].quantidade).toFixed(2)
    state.produtos[index].vlrdesconto = ((state.produtos[index].subtotal * (produto.desconto / 100)) + (produto.desconto_negociado > 0 ? state.produtos[index].subtotal * (produto.desconto_negociado / 100) : 0)).toFixed(2)
    state.produtos[index].vlrtotal = (state.produtos[index].subtotal - state.produtos[index].vlrdesconto).toFixed(2)
  },
  [SUB_PRODUTO] (state, { rootState, produto }) {
    const index = state.produtos.findIndex(item => item.id === produto.id)
    state.produtos[index].quantidade--
    state.produtos[index].volume = (parseFloat(produto.vol_unidade) * parseFloat(state.produtos[index].quantidade)).toFixed(3)
    state.produtos[index].subtotal = (state.produtos[index].preco * state.produtos[index].quantidade).toFixed(2)
    state.produtos[index].vlrdesconto = ((state.produtos[index].subtotal * (produto.desconto / 100)) + (produto.desconto_negociado > 0 ? state.produtos[index].subtotal * (produto.desconto_negociado / 100) : 0)).toFixed(2)
    state.produtos[index].vlrtotal = (state.produtos[index].subtotal - state.produtos[index].vlrdesconto).toFixed(2)
  },
  [CALCULAR_REGRAS] (state, { rootState, produto }) {
    rulesCampaignAdd(rootState, produto)
    localStorage.setItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id), JSON.stringify(state.produtos))
  },
  [ADD_QUANTIDADE] (state, { rootState, produto }) {
    const index = state.produtos.findIndex(item => item.id === produto.id)
    state.produtos[index].quantidade = produto.quantidade
    state.produtos[index].volume = (parseFloat(produto.vol_unidade) * parseFloat(state.produtos[index].quantidade)).toFixed(3)
    state.produtos[index].subtotal = (state.produtos[index].preco * state.produtos[index].quantidade).toFixed(2)
    state.produtos[index].vlrdesconto = ((state.produtos[index].subtotal * (produto.desconto / 100)) + (produto.desconto_negociado > 0 ? state.produtos[index].subtotal * (produto.desconto_negociado / 100) : 0)).toFixed(2)
    state.produtos[index].vlrtotal = (state.produtos[index].subtotal - state.produtos[index].vlrdesconto).toFixed(2)
  },
  [ADD_PREVISAO_COMPRA] (state, { rootState, produto }) {
    const index = state.produtos.findIndex(item => item.id === produto.id)
    state.produtos[index].previsao_compra = produto.previsao_compra
  },
}

const actions = {
  async [BOOTSTRAP_PEDIDO] ({ commit, state }) {
    const data_atual = getData()
    var data = new Date()
    const pedido_numero = data_atual.getFullYear().toString().substr(-2) + ((data_atual.getMonth() + 1).toString()).padStart(2, '0') + data_atual.getDate().toString().padStart(2, '0') + data.getHours().toString().padStart(2, '0') + data.getMinutes().toString().padStart(2, '0') + data.getSeconds().toString().padStart(2, '0')
    const data_entrega = moment(this.data_atual).add(6, 'days').toISOString().slice(0, 10)
    commit(SET_STATE, { pedido_numero: pedido_numero, data_atual: data_atual, data_entrega: data_entrega })
  },
  async [ADD_TO_CART] ({ commit, state, rootState }, produto) {
    if (rootState.fazendas.selecaoFazenda === null) {
      Swal.fire(
        'Atenção!',
        'Por favor selecione uma fazenda antes de adicionar um produto ao carrinho.',
        'warning',
      )
      return
    }
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
    }
    if (!state.produtos.find(el => el.id === produto.id)) {
      const unidade_negocio = rootState.fazendas.selecaoFazenda.unidade_negocio_id
      const fazenda_id = rootState.fazendas.selecaoFazenda.id
      const produtor_id = rootState.user.roles.slug === 'tecnico' ? rootState.produtores.selecaoProdutor.id : rootState.user.pessoa_id
      const fazenda_name = (rootState.fazendas.selecaoFazenda.nome_fantasia ? rootState.fazendas.selecaoFazenda.nome_fantasia : rootState.fazendas.selecaoFazenda.razao_social)
      const produtor_name = rootState.user.roles.slug === 'tecnico' ? rootState.produtores.selecaoProdutor.nome_razao_social : rootState.user.nome
      const retorno = validateCart(state.produtos, produto, unidade_negocio, fazenda_id)

      if (retorno === 200 && state.produtos.length) {
        alertCarrinho('Atenção!', 'Não é possível inserir produtos com unidades de negócio diferentes no carrinho.')
        return false
      } else if (retorno === 300 && state.produtos.length) {
        alertCarrinho('Atenção!', 'Não é possível inserir produtos de campanhas diferentes ou produtos de campanha juntamente com itens que não estão em campanha. Finalize o pedido antes de continuar. ')
        return false
      } else if (retorno === 400 && state.produtos.length) {
        alertCarrinho('Atenção!', 'Não é possível inserir produtos de campanha juntamente com itens que não estão em campanha. Finalize o pedido antes de continuar.')
        return false
      } else if (retorno === 401 && state.produtos.length) {
        alertCarrinho('Atenção!', 'Não é possível inserir produtos para fazendas diferentes. Finalize o pedido antes de continuar.')
        return false
      } else {
        const ofertaOuCampanha = findOffersDiscountCampaign(rootState, produto.produto_id)
        const percDesconto = ofertaOuCampanha.desconto !== '' ? parseInt(ofertaOuCampanha.desconto).toFixed(2) : 0
        const unidadeStr = produto.unidade.sigla
        const cart = {}

        produto.quantidade = produto.pedido_min

        cart.id = produto.id
        cart.id_produto = produto.produto_id
        cart.id_user = rootState.user.id
        cart.nome = produto.nome
        cart.pedido_min = produto.pedido_min
        cart.quantidade = produto.quantidade
        cart.desconto = percDesconto
        cart.preco = produto.disponibilidade !== 2 ? produto.preco : 0
        cart.desconto_negociado = produto.disponibilidade !== 2 ? (produto.desconto_negociado ? produto.desconto_negociado : 0) : 0
        cart.subtotal = (cart.preco * produto.quantidade).toFixed(2)
        cart.vlrdesconto = ((cart.subtotal * (percDesconto / 100)) + (produto.desconto_negociado > 0 ? cart.subtotal * (produto.desconto_negociado / 100) : 0)).toFixed(2)
        cart.vlrtotal = (cart.subtotal - cart.vlrdesconto).toFixed(2)
        cart.unidade = unidadeStr
        cart.id_regra = ''
        cart.id_campanha = ofertaOuCampanha.id_campanha
        cart.unidade_negocio = unidade_negocio
        cart.data_atual = (new Date()).toISOString()
        cart.volume = (parseFloat(produto.volume) * parseFloat(produto.quantidade)).toFixed(3)
        cart.vol_unidade = produto.volume
        cart.nome_campanha = ofertaOuCampanha.nomeCampanha
        cart.imagens = produto.imagens
        cart.marca = produto.marca
        cart.fazenda_id = fazenda_id
        cart.produtor_id = produtor_id
        cart.fazenda_name = fazenda_name
        cart.produtor_name = produtor_name
        cart.parcelamento = (ofertaOuCampanha.parcelamento ? ofertaOuCampanha.parcelamento : produto.parcelamento)
        cart.entrega = produto.entrega
        cart.desconto_tecnico = produto.desconto_tecnico
        cart.vlr_parcelas = produto.vlr_parcelas
        cart.disponibilidade = produto.disponibilidade
        cart.categoria_id = produto.categoria.id
        cart.nome_categoria = produto.categoria.nome

        const itemCart = cart

        state.produtos.push(itemCart)

        rulesCampaignAdd(rootState, itemCart)

        localStorage.setItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id), JSON.stringify(state.produtos))
        return true
      }
    } else {
      alertCarrinho('Atenção!', 'Produto já inserido no carrinho.')
    }
  },
  async [REMOVE_FROM_CART] ({ commit, state, rootState }, id) {
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
    }
    commit(REMOVE_PRODUTO, { rootState, id })
    if (state.produtos.length > 0) {
      commit(CALCULAR_REGRAS, { rootState, produto: state.produtos[0] })
    }
  },
  async [ADD_PRODUTO_FROM_CART] ({ commit, rootState }, produto) {
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
    }
    commit(ADD_PRODUTO, { rootState, produto })
    commit(CALCULAR_REGRAS, { rootState, produto })
  },
  async [ADD_DESCONTO_FROM_CART] ({ commit, rootState }, produto) {
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
    }
    commit(ADD_DESCONTO, { rootState, produto })
    commit(CALCULAR_REGRAS, { rootState, produto })
  },
  async [ADD_PREVISAO_FROM_CART] ({ commit, rootState }, produto) {
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
      commit(ADD_PREVISAO_COMPRA, { rootState, produto })
      commit(CALCULAR_REGRAS, { rootState, produto })
    }
  },
  async [SUB_PRODUTO_FROM_CART] ({ commit, rootState }, produto) {
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
    }
    const index = produtos.findIndex(item => item.id === produto.id)
    if (index >= 0 && produtos[index].quantidade > produtos[index].pedido_min) {
      commit(SUB_PRODUTO, { rootState, produto: produtos[index] })
      commit(CALCULAR_REGRAS, { rootState, produto: produtos[index] })
    }
  },
  async [APLICA_QUANTIDADE] ({ commit, rootState }, produto) {
    const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
    if (produtos !== null) {
      commit(SET_STATE, { produtos })
    }
    commit(ADD_QUANTIDADE, { rootState, produto })
    commit(CALCULAR_REGRAS, { rootState, produto })
  },
  async [FINALIZAR_PEDIDO] ({ commit, state, rootState }) {
    try {
      const farm = rootState.fazendas.selecaoFazenda
      const data_now = getData()
      const produtos = JSON.parse(localStorage.getItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id)))
      const temCotacao = produtos.filter(p => p.disponibilidade === 2)
      const location = await getLocation()
      let cadastro
      var array_itens = []

      if (produtos !== null) {
        commit(SET_STATE, { produtos })
      }

      // pedido
      if (temCotacao.length < produtos.length) {
        const purchase_order = {}

        purchase_order.id = v4()
        purchase_order.pedido_id = state.pedido_numero
        purchase_order.user_id = rootState.user.id
        purchase_order.fazenda_id = farm.id
        purchase_order.nome_fazenda = farm.razao_social
        purchase_order.inc_estadual_fazenda = farm.inscricao_estadual
        purchase_order.bairro_fazenda = farm.bairro ? farm.bairro : ''
        purchase_order.cidade_fazenda = farm.endereco.cidade.nome
        purchase_order.estado_fazenda = farm.endereco.estado.nome
        purchase_order.status = 'Não Sincronizado'
        purchase_order.data_pedido = moment(data_now).format('YYYY-MM-DD HH:mm:ss')
        purchase_order.parcelas = state.parcelas
        purchase_order.unidade_negocio_id = farm.unidade_negocio_id
        purchase_order.dateCreate = new Date()
        purchase_order.latitude = location.latitude
        purchase_order.longitude = location.longitude
        purchase_order.observacao = state.observacao !== '' ? state.observacao : ''
        purchase_order.observacao_atendimento = state.observacao_atendimento !== '' ? state.observacao_atendimento : ''
        purchase_order.resposta = ''

        if (rootState.user.roles.filter(el => el.level === 4).length) { // tecnico
          const producer = rootState.produtores.selecaoProdutor

          purchase_order.produtor_id = producer.id
          purchase_order.nome_produtor = producer.nome
          purchase_order.cpf_cnpj_produtor = producer.cpf_cnpj
          cadastro = farm.produtores.find(el => el.id === producer.id).cadastro
        } else {
          purchase_order.produtor_id = rootState.user.pessoa_id
          purchase_order.nome_produtor = rootState.user.nome
          purchase_order.cpf_cnpj_produtor = rootState.user.cpf !== '' ? rootState.user.cpf : rootState.user.rg
          cadastro = farm.produtores.find(el => el.id === rootState.user.pessoa_id).cadastro
        }

        // item pedido
        array_itens = []

        for (let i = 0; i < produtos.length; i++) {
          if (produtos[i].disponibilidade !== 2) {
            var purchase_order_item = {}

            purchase_order_item.id = v4()
            purchase_order_item.produto_unidade_negocio_id = produtos[i].id
            purchase_order_item.pedido_id = purchase_order.id
            purchase_order_item.nome = produtos[i].nome
            purchase_order_item.quantidade = produtos[i].quantidade
            purchase_order_item.volume = produtos[i].volume
            purchase_order_item.valor_unitario = produtos[i].preco
            purchase_order_item.valor_subtotal = produtos[i].subtotal
            purchase_order_item.valor_desconto = produtos[i].vlrdesconto
            purchase_order_item.valor_total = produtos[i].vlrtotal
            purchase_order_item.unidade_medida = produtos[i].unidade
            purchase_order_item.id_campanha = produtos[i].id_campanha !== '' ? produtos[i].id_campanha : produtos[i].id_regra !== '' ? produtos[i].id_regra : null
            purchase_order_item.previsao_compra = 0
            purchase_order_item.desconto_negociado = produtos[i].desconto_negociado
            // purchase_order_item.previsao_compra = state.produtos[i].previsao_compra
            array_itens.push(purchase_order_item)
          }
        }

        const pedido_completo = {}

        pedido_completo.id = purchase_order.id
        pedido_completo.pedido_id = purchase_order.pedido_id
        pedido_completo.user_id = purchase_order.user_id
        pedido_completo.produtor_id = purchase_order.produtor_id
        pedido_completo.nome_produtor = purchase_order.nome_produtor
        pedido_completo.cpf_cnpj_produtor = purchase_order.cpf_cnpj_produtor
        pedido_completo.fazenda_id = purchase_order.fazenda_id
        pedido_completo.nome_fazenda = purchase_order.nome_fazenda
        pedido_completo.inc_estadual_fazenda = purchase_order.inc_estadual_fazenda
        pedido_completo.bairro_fazenda = purchase_order.bairro_fazenda
        pedido_completo.cidade_fazenda = purchase_order.cidade_fazenda
        pedido_completo.estado_fazenda = purchase_order.estado_fazenda
        pedido_completo.status = 'Aguardando Aprovação'
        pedido_completo.data_pedido = purchase_order.data_pedido
        pedido_completo.parcelas = purchase_order.parcelas
        pedido_completo.unidade_negocio_id = purchase_order.unidade_negocio_id
        pedido_completo.observacao = purchase_order.observacao
        pedido_completo.observacao_atendimento = purchase_order.observacao_atendimento
        pedido_completo.resposta = purchase_order.resposta
        pedido_completo.latitude = purchase_order.latitude
        pedido_completo.longitude = purchase_order.longitude
        pedido_completo.cod_produtor_fornecedor = cadastro ? cadastro.cod_produtor_fornecedor : ''
        pedido_completo.cod_produtor_cliente = cadastro ? cadastro.cod_produtor_cliente : ''
        pedido_completo.fazenda_produtor_id = cadastro ? cadastro.id : ''
        pedido_completo.tecnico_id = cadastro && cadastro.tecnico_id ? cadastro.tecnico_id : null
        pedido_completo.items = array_itens

        Swal.fire({
          title: 'Lançando o Pedido no sistema!',
        })

        Swal.showLoading()

        try {
          await api.cadastrarEntidade(pedido_completo, 'pedido')

          Swal.hideLoading()
          Swal.update({
            icon: 'success',
            title: 'O Pedido foi lançado com sucesso!',
          })
        } catch (err) {
          console.error(err)
          Swal.hideLoading()
          Swal.update({
            icon: 'error',
            title: 'Não foi possível lançar o Pedido!',
          })
        }
      }

      if (temCotacao.length && temCotacao.length > 0) {
        const cotation_order = {}

        cotation_order.id = v4()
        cotation_order.cotacao_id = state.pedido_numero
        cotation_order.user_id = rootState.user.id
        cotation_order.fazenda_id = farm.id
        cotation_order.nome_fazenda = farm.razao_social
        cotation_order.inc_estadual_fazenda = farm.inscricao_estadual
        cotation_order.bairro_fazenda = farm.bairro ? farm.bairro : ''
        cotation_order.cidade_fazenda = farm.endereco.cidade.nome
        cotation_order.estado_fazenda = farm.endereco.estado.nome
        cotation_order.status = 'Não Sincronizado'
        cotation_order.data_cotacao = moment(data_now).format('YYYY-MM-DD')
        cotation_order.unidade_negocio_id = farm.unidade_negocio_id
        cotation_order.dateCreate = new Date()
        cotation_order.latitude = location.latitude
        cotation_order.longitude = location.longitude
        cotation_order.observacao = state.observacao !== '' ? state.observacao : ''
        cotation_order.resposta = ''

        if (rootState.user.roles.filter(el => el.level === 4).length) { // tecnico
          const producer = rootState.produtores.selecaoProdutor

          cotation_order.produtor_id = producer.id
          cotation_order.nome_produtor = producer.nome
          cotation_order.cpf_cnpj_produtor = producer.cpf_cnpj
          cadastro = farm.produtores.find(el => el.id === producer.id).cadastro
        } else {
          cotation_order.produtor_id = rootState.user.pessoa_id
          cotation_order.nome_produtor = rootState.user.nome
          cotation_order.cpf_cnpj_produtor = rootState.user.cpf !== '' ? rootState.user.cpf : rootState.user.rg
          cadastro = farm.produtores.find(el => el.id === rootState.user.pessoa_id).cadastro
        }

        // item pedido
        array_itens = []

        for (let i = 0; i < produtos.length; i++) {
          if (produtos[i].disponibilidade === 2) {
            var cotation_order_item = {}

            cotation_order_item.id = v4()
            cotation_order_item.produto_unidade_negocio_id = produtos[i].id
            cotation_order_item.cotacao_id = cotation_order.id
            cotation_order_item.nome_produto = produtos[i].nome
            cotation_order_item.quantidade = produtos[i].quantidade
            cotation_order_item.categoria_id = produtos[i].categoria_id
            cotation_order_item.nome_categoria = produtos[i].nome_categoria
            cotation_order_item.unidade_medida = produtos[i].unidade
            cotation_order_item.observacao = 'Solicitação de cotação feita por Produto disponível na loja'

            array_itens.push(cotation_order_item)
          }
        }

        const cotation_completo = {}

        cotation_completo.id = cotation_order.id
        cotation_completo.cotacao_id = cotation_order.cotacao_id
        cotation_completo.user_id = cotation_order.user_id
        cotation_completo.produtor_id = cotation_order.produtor_id
        cotation_completo.nome_produtor = cotation_order.nome_produtor
        cotation_completo.cpf_cnpj_produtor = cotation_order.cpf_cnpj_produtor
        cotation_completo.fazenda_id = cotation_order.fazenda_id
        cotation_completo.nome_fazenda = cotation_order.nome_fazenda
        cotation_completo.inc_estadual_fazenda = cotation_order.inc_estadual_fazenda
        cotation_completo.bairro_fazenda = cotation_order.bairro_fazenda
        cotation_completo.cidade_fazenda = cotation_order.cidade_fazenda
        cotation_completo.estado_fazenda = cotation_order.estado_fazenda
        cotation_completo.status = 'Aguardando Aprovação'
        cotation_completo.data_cotacao = cotation_order.data_cotacao
        cotation_completo.unidade_negocio_id = cotation_order.unidade_negocio_id
        cotation_completo.observacao = cotation_order.observacao
        cotation_completo.resposta = cotation_order.resposta
        cotation_completo.latitude = cotation_order.latitude
        cotation_completo.longitude = cotation_order.longitude
        cotation_completo.cod_produtor_fornecedor = cadastro ? cadastro.cod_produtor_fornecedor : ''
        cotation_completo.cod_produtor_cliente = cadastro ? cadastro.cod_produtor_cliente : ''
        cotation_completo.fazenda_produtor_id = cadastro ? cadastro.id : ''
        cotation_completo.tecnico_id = cadastro && cadastro.tecnico_id ? cadastro.tecnico_id : null
        cotation_completo.items = array_itens

        try {
          await api.cadastrarEntidade(cotation_completo, 'cotacao')
        } catch (err) {
          console.error(err)
        }
      }

      router.push({ path: `/${rootState.user.roles[0].slug}/loja` })
      commit(RESET_STATE)
      localStorage.setItem(('Carrinho-' + rootState.fazendas.selecaoFazenda.id), JSON.stringify(state.produtos))
    } catch (_err) {
      console.error(_err)
      throw _err
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
