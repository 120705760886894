<template>
  <div>
    <audio
      ref="whistleSound"
      src="../../assets/Sons/beep.mp3"
    />
    <v-menu
      bottom
      left
      offset-y
      style="border-radius: 20px;"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          @click="paraBell()"
        >
          <v-badge
            :content="notificationQuantity"
            :value="notificationQuantity === 0 ? false : notOpened"
            color="success"
            overlap
            class="pa-1 ma-2"
            small
          >
            <v-icon
              small
              color="white"
              title="Acessar as mensagens do usuário!"
            >
              {{ notOpened ? 'mdi-bell-ring' : 'mdi-bell' }}
            </v-icon>
          </v-badge>
        </div>
      </template>
      <v-list class="ma-0 pa-0">
        <v-toolbar
          color="blue darken-2"
          dark
          height="40px"
          class="ma-0 pa-0"
        >
          <v-icon
            small
            color="white"
            class="mx-2 px-0"
          >
            mdi-bell
          </v-icon>
          <v-toolbar-title
            class="px-0 pt-1"
          >
            Mensagens
          </v-toolbar-title>
        </v-toolbar>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :class="{active: item.active, normal: !item.active}"
          @click="item.action"
        >
          <v-list-item-title
            class="mx-2 px-0"
          >
            <v-icon
              color="#1976d2"
              class="mx-2 px-0"
            >
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  import UserProfileStore, { LOAD_NOTIFICATIONS } from '@/store/modules/userProfile'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'NotificationAdm',
    data () {
      return {
        show: false,
        notOpened: true,
      }
    },
    computed: {
      ...mapState('userProfile', ['cadastros',
                                  'pedidos_aguardando',
                                  'pedidos_em_analise',
                                  'pedidos_aprovados',
                                  'pedidos_faturados',
                                  'pedidos_cancelados_slcnc',
                                  'cotacoes',
                                  'cotacoes_slcnc']),
      notificationQuantity () {
        return this.cadastros + this.pedidos_aguardando + this.pedidos_em_analise + this.pedidos_aprovados + this.pedidos_faturados + this.pedidos_cancelados_slcnc + this.cotacoes + this.cotacoes_slcnc
      },
      items () {
        const itens = []

        if (this.cadastros > 0) {
          itens.push({
            title: 'Você possui ' + this.cadastros + ' solicitações de cadastro para serem analisadas.',
            active: (this.cadastros > 0),
            action: this.handleCadastro,
            icon: 'mdi-account-check',
          })
        }

        if (this.pedidos_aguardando > 0) {
          itens.push({
            title: 'Você possui ' + this.pedidos_aguardando + ' pedidos aguardando aprovação.',
            active: (this.pedidos_aguardando > 0),
            action: this.handlePedidoAguardando,
            icon: 'mdi-file-clock-outline',
          })
        }

        if (this.pedidos_em_analise > 0) {
          itens.push({
            title: 'Você possui ' + this.pedidos_em_analise + ' pedidos em análise.',
            active: (this.pedidos_em_analise > 0),
            action: this.handlePedidoEmAnalise,
            icon: 'mdi-book-clock-outline',
          })
        }

        if (this.pedidos_aprovados > 0) {
          itens.push({
            title: 'Você possui ' + this.pedidos_aprovados + ' pedidos aprovados.',
            active: (this.pedidos_aprovados > 0),
            action: this.handlePedidoAprovados,
            icon: 'mdi-file-check-outline',
          })
        }

        if (this.pedidos_cancelados_slcnc > 0) {
          itens.push({
            title: 'Você possui ' + this.pedidos_slcnc + ' pedidos que foram solicitado o cancelamento.',
            active: (this.pedidos_cancelados_slcnc > 0),
            action: this.handlePedido_slcnc,
            icon: 'mdi-file-remove-outline',
          })
        }

        if (this.pedidos_faturados > 0) {
          itens.push({
            title: 'Você possui ' + this.pedidos_faturados + ' pedidos faturados.',
            active: (this.pedidos_faturados > 0),
            action: this.handlePedidoFaturados,
            icon: 'mdi-clipboard-check-outline',
          })
        }

        if (this.cotacoes > 0) {
          itens.push({
            title: 'Você possui ' + this.cotacoes + ' cotações aguardando análise.',
            active: (this.cotacoes > 0),
            action: this.handleCotacoes,
            icon: 'mdi-file-clock-outline',
          })
        }

        if (this.cotacoes_slcnc > 0) {
          itens.push({
            title: 'Você possui ' + this.cotacoes_slcnc + ' cotações que foram solicitado o cancelamento.',
            active: (this.cotacoes_slcnc > 0),
            action: this.handleCotacoes_slcnc,
            icon: 'mdi-file-remove-outline',
          })
        }

        return itens.sort((a, b) => (a.active === b.active) ? 0 : a.active ? -1 : 1)
      },
    },
    created () {
      if (!this.$store.state.userProfile) { this.$store.registerModule('userProfile', UserProfileStore) }
      this.LOAD_NOTIFICATIONS()
    },
    mounted () {
      this.startHourlyBeep()
    },
    methods: {
      ...mapActions('userProfile', [LOAD_NOTIFICATIONS]),
      handleCadastro () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cadastros/?status=Em análise' }).catch(() => {})
      },
      handlePedidoAguardando () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/pedidos/?status=Aguardando Aprovação' }).catch(() => {})
      },
      handlePedidoAprovados () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/pedidos/?status=Aprovado' }).catch(() => {})
      },
      handlePedidoEmAnalise () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/pedidos/?status=Em Análise' }).catch(() => {})
      },
      handlePedidoFaturados () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/pedidos/?status=Faturado' }).catch(() => {})
      },
      handlePedido_slcnc () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/pedidos/?status=Cancelamento Solicitado' }).catch(() => {})
      },
      handleCotacoes () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/cotacoes/?status=Aguardando Análise' }).catch(() => {})
      },
      handleCotacoes_slcnc () {
        this.$router.push({ path: `/${this.$store.state.user.roles[0].slug}/dashboard` }).catch(() => {})
        this.$router.push({ path: 'cdv/cotacoes/?status=Cancelamento Solicitado' }).catch(() => {})
      },
      playWhistle () {
        this.$refs.whistleSound.play()
      },
      paraBell () {
        this.notOpened = false
      },
      startHourlyBeep () {
        setInterval(() => {
          this.LOAD_NOTIFICATIONS()
          this.notOpened = true
          this.playWhistle()
        }, 900000) // 3600000 = 1 hora
      },
    },
  }
</script>
<style scoped>
.normal {
  background: #ffffff;
  border-color: #9c9c9c;
}
.active {
  background: #f1f1ff;
}

.v-menu__content {
  border-radius: 20px;
  border: 7px solid;
  border-color:#1976d2;
}
</style>
