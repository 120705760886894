import api from '@/api/api'
import Swal from 'sweetalert2'
import { getField, updateField } from 'vuex-map-fields'

export const BOOTSTRAP = 'BOOTSTRAP'
export const SET_STATE = 'SET_STATE'
export const SET_EDIT = 'SET_EDIT'
export const LOAD_CATEGORIAS = 'LOAD_CATEGORIAS'
export const INATIVAR_CATEGORIA = 'INATIVAR_CATEGORIA'
export const REMOVER_INDEX = 'REMOVER_INDEX'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    getField,
    categorias: null,
    loading: true,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [REMOVER_INDEX] (state, { index }) {
    state.categorias.splice(index, 1)
  },
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async [LOAD_CATEGORIAS] ({ commit, state }) {
    commit(SET_STATE, { loading: true })
    await api.listEntidade('categoria').then(
      categorias => {
        commit(SET_STATE, { categorias, loading: false })
      },
    )
  },
  async [BOOTSTRAP] ({ dispatch, commit, state }) {
    await dispatch(LOAD_CATEGORIAS)
  },
  async [INATIVAR_CATEGORIA] ({ dispatch, commit, state }, categoria) {
    const index = state.categorias.findIndex(c => c.id === categoria.id)

    Swal.fire({
      title: 'Excluir',
      text: 'Tem certeza que deseja excluir a Categoria?',
      icon: 'question',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonColor: '#109010',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      focusConfirm: false,
      preConfirm: () => {
        return api.inativarEntidade('categoria', categoria.id).then(
          result => result,
          ({
            error = null,
            message = 'Não foi possivel excluir a Categoria!',
          }) => {
            Swal.fire({
              icon: 'error',
              title: error === null ? 'Ocorreu algum erro!' : 'Erro!',
              text: message,
            })
          },
        )
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'A Categoria foi excluída com sucesso!',
          icon: 'success',
          showConfirmButton: false,
          timer: 2500,
        })
        commit(REMOVER_INDEX, { index })
        dispatch(BOOTSTRAP)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
