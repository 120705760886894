<template>
  <v-text-field
    v-model="cpf_cnpj"
    v-mask="[maskCpf,maskCnpj]"
    v-bind="$attrs"
    autocomplete="off"
    outlined
    rounded
    :label="label"
    :error-messages="errors"
    :loading="$v.cpf_cnpj.$pending"
    @blur="$v.cpf_cnpj.$touch()"
    @focus="onFocus"
    @input="touch"
  >
    <template v-slot:append>
      <v-icon
        v-if="cpfRequiredValid"
        color="success"
      >
        mdi-check-circle
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
  import { required } from 'vuelidate/lib/validators'
  import { validarCNPJ, validarCPF } from '../../utils/validacoes'
  import rules from '../../utils/formRules'
  const CPF = 0
  const CNPJ = 1
  export default {
    name: 'CPFCNPJ',
    model: { prop: 'value', event: 'input' },
    props: {
      required: {
        type: Boolean,
        default: () => false,
      },
      value: {
        type: Object,
        default: () => ({ value: null, tipo: '' }),
      },
      texto: {
        type: String,
        default: 'CPF/CNPJ',
      },
    },
    data () {
      return {
        rules,
        maskCpf: '###.###.###-##',
        maskCnpj: '##.###.###/####-##',
        cpf_cnpj: null,
      }
    },
    computed: {
      label () {
        return this.texto + (this.required ? ' *' : '')
      },
      tipo () {
        let retorno = ''

        if (this.texto === 'CPF/CNPJ') {
          retorno = (!this.cpf_cnpj || this.cpf_cnpj.length < 14) ? null : (this.cpf_cnpj.length === 14 ? CPF : CNPJ)
        } else if (this.texto === 'CPF') {
          retorno = (!this.cpf_cnpj || this.cpf_cnpj.length < 14) ? null : CPF
        } else if (this.texto === 'CNPJ') {
          retorno = (!this.cpf_cnpj || this.cpf_cnpj.length < 14) ? null : CNPJ
        }

        return (retorno)
      },
      errors () {
        const errors = []
        if (!this.$v.cpf_cnpj.$dirty) return errors;
        (this.required && !this.$v.cpf_cnpj.required) && errors.push('Obrigatório')
        !this.$v.cpf_cnpj.formato && errors.push('Inválido')
        return errors
      },
      cpfRequiredValid () {
        return this.cpf_cnpj && this.$v.cpf_cnpj.$dirty && !this.$v.cpf_cnpj.$error
      },
    },
    validations () {
      return {
        cpf_cnpj: {
          required: (this.required ? required : true),
          formato: (v) => (!v || (
            this.tipo === null
              ? false
              : (this.tipo === CPF ? validarCPF(v) : validarCNPJ(v)))
          ),
        },
      }
    },
    methods: {
      touch () {
        this.$emit('input', { value: this.cpf_cnpj, tipo: this.tipo ? 'cnpj' : this.tipo || 'cpf' })
        if (this.cpf_cnpj.length === 14 || this.cpf_cnpj.length === 18) {
          this.$v.cpf_cnpj.$touch()
        } else {
          this.$v.$reset()
        }
      },
      onFocus () {
        this.$v.$reset()
        this.$emit('focus')
      },
      validate () {
        this.$v.cpf_cnpj.$touch()
        return !this.$v.cpf_cnpj.$anyError
      },
    },
  }
</script>
