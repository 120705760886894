<template>
  <v-row>
    <v-col
      v-for="(tel, index) in telefones"
      :key="index"
      cols="12"
      :md="colSize"
    >
      <crud-telefone
        :index="index"
        :tel-data="{ ...tel }"
        :disabled="disabled"
        store-module="form"
        :input-outlined="inputOutlined"
      />
    </v-col>
  </v-row>
</template>
<script>
  // import { createFormFieldsArray } from '../../utils/formMapFields'
  export default {
    name: 'Contato',
    props: {
      telefones: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
      storeModule: {
        type: String,
        default: 'form',
      },
      cols: {
        type: Number,
        default: 3,
      },
      inputOutlined: {
        type: Boolean,
        default: false,
      },

    },
    computed: {
      colSize () {
        return this.cols
      },
    },
  }
</script>
