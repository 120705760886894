const router = function (permissions) {
  return ({
    path: 'fazendas',
    component: () => import('@/views/Fazenda/FazendaModule.vue'),
    meta: { permissions },
    children: [
      {
        path: '/',
        component: () => import('@/views/Fazenda/FazendaList.vue'),
        meta: { permissions, title: 'Manutenção de Fazendas' },
      },
      {
        path: 'cadastrar',
        component: () => import('@/views/Fazenda/FazendaProfile.vue'),
        meta: { permissions, title: 'Cadastrando Fazenda' },
      },
      {
        path: 'alterar',
        component: () => import('@/views/Fazenda/FazendaProfile.vue'),
        meta: { permissions, title: 'Editando Fazenda' },
      },
    ],
  })
}

export default {
  router,
}
