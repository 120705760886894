<template>
  <crud-card
    title="Dados Principais"
    :exclude="exclude"
    :optional="optional"
  >
    <crud-pessoa-juridica-base
      :optional="optional"
      :exclude="exclude"
      :not-editable="notEditable"
    />
    <slot />
  </crud-card>
</template>
<script>
  export default {
    props: {
      optional: {
        type: Array,
        default: () => [],
      },
      exclude: {
        type: Array,
        default: () => [],
      },
      notEditable: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>
