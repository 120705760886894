<template>
  <v-list-item class="px-0">
    <a @click="handleProdLink(produto.id)">
      <v-list-item-avatar
        tile
        size="60"
        color="white"
        class="ma-0"
      >
        <v-img
          height="60"
          width="60"
          contain
          :src="showImage"
        />
      </v-list-item-avatar>
    </a>
    <v-list-item-content
      dense
      class="py-0"
    >
      <v-row dense>
        <v-col
          cols="8"
        >
          <div style="width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <a @click="handleProdLink(produto.id)">
              <span
                style="font-size: 12px; color: #143693;font-weight: bold;"
              >
                {{ produto.nome }}
              </span>
            </a>
          </div>
        </v-col>
        <v-col
          cols="3"
          style="text-align: right;"
        >
          <span
            v-if="produto.disponibilidade !== 2"
            style="font-size: 12px; color: #143693; font-weight: bold"
          >
            R$ {{ currencyFormatter( ( produto.preco * produto.quantidade ) ) }}
          </span>
          <span
            v-else-if="produto.disponibilidade === 2"
            style="font-size: 12px; color: #ff8f00; font-weight: bold"
          >
            Cotação
          </span>
        </v-col>
        <v-col
          align-self="center"
          cols="1"
          class="mx-0"
        >
          <v-row
            align-content="center"
            justify="center"
          >
            <v-col>
              <v-btn
                icon
                x-small
                color="red accent-4"
                class="mt-n1"
                @click="REMOVE_FROM_CART(produto.id)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <span
            style="font-size: 12px; color: #808080;"
          >
            Quantidade: {{ produto.quantidade }}
          </span>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import { formatDescText, handleProdLink, formatImageLink } from './helpers'
  import { REMOVE_FROM_CART } from '@/store/modules/carrinho'
  import { mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    props: {
      produto: {
        type: Object,
        default: () => (
          {
            id: 'fa6d6298-60bf-4509-9dbb-79d1c51a3d90',
            nome: 'Excede (CCFA)',
            marca: 'Zoetis',
            descricao: 'EXCEDE (CCFA) Suspensão Estéril – 200 mg/mL é uma formulação pronta para uso que contém ácido livre cristalino de ceftiofur, um antibiótico com largo espectro de ação, pertencente ao grupo das cefalosporinas, ativo contra bactérias Gram positivas e Gram negativas, incluindo cepas produtoras de β-lactamase. Como outras cefalosporinas, ceftiofur é bactericida in vitro, devido a sua ação de inibição da síntese da parede bacteriana.',
            imagens: ['24b6b33a-3d1f-409e-939a-ab81bed0929f.0.png'],
          }
        ),
      },
    },
    data () {
      return {
        descLength: 150,
        currencyFormatter,
      }
    },
    computed: {
      showImage () {
        return this.formatImageLink(this.produto.imagens[0])
      },
    },
    methods: {
      ...mapActions('carrinho', [REMOVE_FROM_CART]),
      formatDescText,
      handleProdLink,
      formatImageLink,
    },
  }
</script>
<style scoped>
</style>
